import { wenissCloseWeview, wenissSelectPlayer } from "actions";
import Ban from "components/commands/Ban";
import Burn from "components/commands/Burn";
import Explode from "components/commands/Explode";
import Kick from "components/commands/Kick";
import Mute from "components/commands/Mute";
import Rename from "components/commands/Rename";
import TempBan from "components/commands/TempBan";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "scss/AdminCommands.scss";
import "scss/SelectedPlayer.scss";

function formDummyPlayer(id) {
  return {
    name: "NO PLAYER SELECTED",
    guid: "",
    slot: "",
    id: id || "",
  };
}

function getPlayerFromId(players, admins, id) {
  for (var i = 0; i < players.length; i++) {
    if (players[i].id === id) {
      return Object.assign({}, players[i]);
    }
  }
  for (i = 0; i < admins.length; i++) {
    if (admins[i].id === id) {
      return Object.assign({}, admins[i]);
    }
  }
  throw new Error("PLAYER_NOT_FOUND");
}

function SelectedPlayer({
  weniss,
  players,
  admin,
  admins,
  closeWeview,
  wrapper,
}) {
  const [show, setShow] = useState(false);
  const [player, setPlayer] = useState(formDummyPlayer());

  useEffect(() => {
    try {
      setPlayer(getPlayerFromId(players, admins, weniss.selectedPlayer));
      if (!show)
        setShow(true);
    } catch {
      setPlayer(formDummyPlayer("null"));
      setShow(false);
    }
    return () => {};
  }, [weniss.selectedPlayer, weniss.selectedPlayerGUID]);

  useEffect(() => {
    setShow(player.id !== "null" && weniss.weviewVisible);

    return () => {
      setShow(false);
    };
  }, [weniss.weviewVisible]);

  const handleClose = () => {
    closeWeview();
  };

  return (
    <>
      <Offcanvas
        className="SelectedPlayer"
        show={weniss.weviewVisible}
        onHide={handleClose}
        container={wrapper}
        scroll={true}
        backdrop={false}
        placement="top"
      >
        <Offcanvas.Header closeButton closeLabel="Close" closeVariant="white">
          {player.id !== "null" && (
            <Link to={`/echelon/clientDetails/${player.guid}`}>
              <Button variant="info">
                <span style={{ whiteSpace: "nowrap" }}>
                  Echelon <i className="fa fa-id-card-o" />
                </span>
              </Button>
            </Link>
          )}
          <Offcanvas.Title>
            [{player.connected ? player.slot : "OFFLINE"}] {player.name}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {player.connected && (

            <div className="AdminCommands">
            <ul className="list-inline flex-container AdminCommands-greater">
              {admin.perms.includes("cmd_ban") && (
                <li>
                  <Ban
                    clientid={player.id}
                    guid={player.guid}
                    ip={player.ip}
                    name={player.name}
                    slot={player.connected ? player.slot : undefined}
                    />
                </li>
              )}
              {admin.perms.includes("cmd_kick") && (
                <li>
                  <Kick
                    clientid={player.id}
                    guid={player.guid}
                    ip={player.ip}
                    name={player.name}
                    slot={player.connected ? player.slot : undefined}
                    />
                </li>
              )}
              {admin.perms.includes("cmd_tempban") && (
                <li>
                  <TempBan
                    clientid={player.id}
                    name={player.name}
                    guid={player.guid}
                    ip={player.ip}
                    slot={player.connected ? player.slot : undefined}
                    />
                </li>
              )}
              {admin.perms.includes("cmd_mute") && (
                <li>
                  <Mute
                    clientid={player.id}
                    name={player.name}
                    slot={player.connected ? player.slot : undefined}
                    muted={player.muted}
                    />
                </li>
              )}
            </ul>
            <ul className="list-inline flex-container AdminCommands-lesser">
              {admin.perms.includes("cmd_rename") && (
                <li>
                  <Rename
                    clientid={player.id}
                    name={player.name}
                    slot={player.connected ? player.slot : undefined}
                    />
                </li>
              )}
              {admin.perms.includes("cmd_burn") && (
                <li>
                  <Burn
                    clientid={player.id}
                    name={player.name}
                    slot={player.connected ? player.slot : undefined}
                    />
                </li>
              )}
              {admin.perms.includes("cmd_explode") && (
                <li>
                  <Explode
                    clientid={player.id}
                    name={player.name}
                    slot={player.connected ? player.slot : undefined}
                    />
                </li>
              )}
            </ul>
          </div>
        )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

SelectedPlayer.propTypes = {
  wrapper: PropTypes.any,
  closeWeview: PropTypes.func,
  weniss: PropTypes.shape({
    selectedPlayer: PropTypes.string,
    selectedPlayerGUID: PropTypes.string,
    weviewVisible: PropTypes.bool,
    b3infoByGUID: PropTypes.shape({
      b3info: PropTypes.object,
      aliases: PropTypes.array,
      reports: PropTypes.array,
    }),
  }),
  players: PropTypes.array,
  admins: PropTypes.array,
};

export default connect(
  (state) => ({
    weniss: state.weniss,
    players: state.players,
    admins: state.admins,
    admin: state.admin.info,
  }),
  (dispatch) => ({
    closeWeview: () => dispatch(wenissCloseWeview()),
    wenissSelectPlayer: (id) => dispatch(wenissSelectPlayer(id)),
  })
)(SelectedPlayer);
