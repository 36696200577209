import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import CharPad from 'components/util/CharPad'
import 'scss/ServerInfo.scss'

function mins(now, to) {
    return moment(to).diff(now, 'minutes') % 60;
}

function secs(now, to) {
    return moment(to).diff(now, 'seconds') % 60;
}

class ServerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            now: moment(),
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({now: moment() }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render()  {
        return (
            <div className="ServerInfo col-xs-12 nopadding">
                <div className={"current_map " + this.props.serverinfo.mapname} >
                        <span className={moment.tz(this.props.serverinfo.g_mapStartTime, 'ddd MMM DD HH:mm:ss YYYY', "America/Los_Angeles").add('15', 'minutes')}></span>
                    <div className="current_map_timer">
                        <CharPad
                            str={"" + mins(this.state.now, moment.tz(this.props.serverinfo.g_mapStartTime, 'ddd MMM DD HH:mm:ss YYYY', "America/Los_Angeles").add('15', 'minutes').format())}
                            digits={2}
                            char="0" />:
                        <CharPad
                            str={"" + secs(this.state.now, moment.tz(this.props.serverinfo.g_mapStartTime, 'ddd MMM DD HH:mm:ss YYYY', "America/Los_Angeles").add('15', 'minutes').format())}
                            digits={2}
                            char="0" />
                        </div>
                    <div className="current_map_name">
                        <span className="serverinfo-map-name">{this.props.serverinfo.mapname}</span><br />
                        <span className="serverinfo-player-count">{this.props.players.filter(p => p.connected).length}/24</span>
                    </div>
                </div>
            </div>
        );
    }
}

ServerInfo.propTypes = {
    serverinfo: PropTypes.shape({
        mapname: PropTypes.string,
        g_mapStartTime: PropTypes.string,
    }),
    players: PropTypes.array,
    adminInitChat: PropTypes.func,
    adminChat: PropTypes.func,
    adminAlerts: PropTypes.func,
    adminConnect: PropTypes.func,
    adminDisconnect: PropTypes.func,
    adminList: PropTypes.func,
}

export default ServerInfo
