import Ban from "components/commands/Ban";
import EditGreeting from "components/commands/EditGreeting";
import IPBan from "components/commands/IPBan";
import Mute from "components/commands/Mute";
import Activity from "components/echelon/Activity";
import AdminNotes from "components/echelon/AdminNotes";
import Aliases from "components/echelon/Aliases";
import Chatlog from "components/echelon/Chatlog/Chatlog";
import IPLogs from "components/echelon/IPLogs";
import Loading from "components/echelon/Loading";
import Penalties from "components/echelon/Penalties";
import Reports from "components/echelon/Reports";
import FontAwesome from "components/util/FontAwesome";
import axios from "modules/axios";
import { useDeepLinking } from "modules/deepLinking";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Moment from "react-moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "scss/ClientDetails.scss";

const rxp_guid = /[A-z0-9]{32}/;

const defaultClient = {
  name: "",
  id: "",
  guid: "",
  time_add: "",
  clientid: "",
  time_edit: "",
  greeting: "",
  pbid: "",
  connections: 0,
};

const Section = (props) => {
  return (
    <Col className="section" {...props}>
      {props.children}
    </Col>
  );
};
Section.propTypes = {
  children: PropTypes.any,
};

function ClientDetails() {
  const [client, setClient] = useState(defaultClient);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [clientid, setClientid] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [
    ,
    ,
    ,/* getSearchParam */
    /* setSearchParam */
    /* addSearchParamValue */
    /* deleteSearchParam */
  ] = useDeepLinking("clientDetails");

  useEffect(() => {
    if (("" + params.clientid).match(rxp_guid)) {
      axios
        .get(`/api/v2/clients/${params.clientid}`)
        .then((res) => {
          let loc = { ...location };
          loc.pathname = `/echelon/clientdetails/${res.data.data.data[0].id}`;
          navigate(loc, { replace: true });
        })
        .catch(() => {});
    } else {
      axios
        .get(`/api/v2/clients/${params.clientid}`)
        .then((res) => {
          setClient(res.data.data.data[0]);
          setLoading(false);
        })
        .catch(() => {});
    }
    return () => {
      setLoading(true);
      setClientid(null);
    };
  }, [params.clientid]);

  useEffect(() => {
    if (clientid !== client.id) setClientid(client.id);
  }, [client, clientid]);

  // if (client == null) {
  //   axios.get(`/api/clients/${params.clientid}`).then((res) => {
  //     console.log('Returns', res.data);
  //     setClient(res.data);
  //   })
  //   .catch( (e) => {
  //       console.log(e);
  //   })
  // }
  // if (client == null)
  //   return ( <Container fluid className="ClientDetails">Loading...</Container>)

  return (
    <div fluid className="ClientDetails" style={{ position: "relative" }}>
      {loading && <Loading />}
      <Row>
        <Section xs={12}>
          <div fluid className="content">
            <Row>
              <Col xs={6}>
                <b>Name: </b>
                {client.name}&nbsp;
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Open in Echelon</Tooltip>}
                >
                  <a
                    href={`https://echelon.net-jam.com/clientdetails.php?game=1&id=${client.id}`}
                    target="_new"
                  >
                    <FontAwesome icon="external-link" />
                  </a>
                </OverlayTrigger>
              </Col>
              <Col xs={6}>
                <b>IP: </b>
                {client.ip}&nbsp;
                <IPBan clientid={client.id} name={client.name} ip={client.ip} />
                &nbsp;
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Geo Location</Tooltip>}
                >
                  <a
                    href={`https://www.geolocation.com/en_us?ip=${client.ip}#ipresult`}
                    target="_new"
                  >
                    <FontAwesome icon="globe" />
                  </a>
                </OverlayTrigger>
              </Col>
              <Col xs={6}>
                <b>Client ID: </b>
                {client.id}
              </Col>
              <Col xs={6}>
                <b>First Seen: </b>
                <Moment unix format="MM/DD/YYYY hh:mma">
                  {client.time_add}
                </Moment>
              </Col>
              <Col xs={6}>
                <b>GUID: </b>
                <i
                  className={`fa fa-${
                    client.guid.match(/^00000000.*$/)
                      ? "unlock-alt cracked"
                      : "lock legit"
                  }`}
                />{" "}
                {client.guid}{" "}
                {client.banned && (
                  <Badge text="light" bg="danger">
                    BANNED
                  </Badge>
                )}{" "}
                {!client.guid.match(/^00000000.*$/) && (
                  <Ban
                    size="sm"
                    clientid={client.id}
                    name={client.name}
                    ip={client.ip}
                    guid={client.guid}
                  />
                )}
              </Col>
              <Col xs={6}>
                <b>Last Seen: </b>
                <Moment unix format="MM/DD/YYYY hh:mma">
                  {client.time_edit}
                </Moment>
              </Col>
              <Col xs={6}>
                <b>PB GUID: </b>
                {client.pbid || "None"}
              </Col>
              <Col xs={6}>
                <b>Greeting: </b>
                {client.greeting || "None"}{" "}
                <EditGreeting clientid={client.id} greeting={client.greeting} />
              </Col>
              <Col xs={6}>
                <b>Connections: </b>
                {client.connections || "None"}
              </Col>
              <Col xs={6}>
                <b>Mute Status: </b>
                {client.muted ? "Muted" : "Not muted"}&nbsp;
                <Mute
                  clientid={client.id}
                  name={client.name}
                  muted={client.muted ? true : false}
                />
              </Col>
            </Row>
          </div>
        </Section>
        <Section xs={12}>
          <Activity clientid={client.id} />
        </Section>
        <Section xs={6}>
          <Aliases clientid={client.id} />
        </Section>
        <Section xs={6}>
          <IPLogs clientid={client.id} />
        </Section>
        <Section xs={6}>
          <Reports clientid={client.id} />
        </Section>
        <Section xs={6}>
          <AdminNotes clientid={client.id} />
        </Section>
        <Section xs={12}>
          <Penalties clientid={client.id} />
        </Section>
        <Section xs={12}>
          <Chatlog clientid={client.id} />
          {/* <ClientChatlog clientid={client.id} /> */}
        </Section>
      </Row>
    </div>
  );
}

ClientDetails.propTypes = {};

export default ClientDetails;

/*
RCONTROL
*/
