import React, { useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";

type DatePaginationProps = {
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onControl?: Function;
};

const thirtydays = 2592000000;

export default function DatePagination({
  defaultStartDate = new Date(),
  defaultEndDate = new Date(defaultStartDate.getTime() - 1000 * 60 * 60 * 24),
  onControl = () => {}
}: DatePaginationProps) {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [updated, setUpdated] = useState(false);
  const NextDate = () => {
    setStartDate(endDate);
    setEndDate(
      new Date(endDate.getTime() + (endDate.getTime() - startDate.getTime()))
    );
    setUpdated(true);
  };

  const PrevDate = () => {
    setEndDate(startDate);
    setStartDate(
      new Date(startDate.getTime() - (endDate.getTime() - startDate.getTime()))
    );
    setUpdated(true);
  };

  useEffect(() => {
    if (updated) {
      onControl({startDate, endDate});
      setUpdated(false);
    }
  }, [updated])

  const clampEndDate = (dt = startDate) => {
    if (dt.getTime() - endDate.getTime() > thirtydays) {
      setEndDate(new Date(dt.getTime() - thirtydays));
      setUpdated(true);
    }
    if (dt.getTime() < endDate.getTime()) {
      setEndDate(new Date(dt.getTime() - (defaultStartDate.getTime() - defaultEndDate.getTime())));
      setUpdated(true);
    }
  }

  const updateStartDate = (dt : Date) => {
    setEndDate(new Date(dt.getTime() - (startDate.getTime() - endDate.getTime())));
    setStartDate(dt);

    setUpdated(true);
  } 

  clampEndDate();

  return (
    <div className="DatePagination">
      <InputGroup>
        <Button onClick={PrevDate}>Newer</Button>
        <DateTimeSelector
          date={startDate}
          onChange={updateStartDate}
          max={new Date()}
        />
        <InputGroup.Text>to</InputGroup.Text>
        <DateTimeSelector
          date={endDate}
          onChange={(dt) => {setEndDate(dt); setUpdated(true)}}
          max={startDate}
          min={new Date(startDate.getTime() - thirtydays)}
        />
        <Button onClick={NextDate}>Older</Button>
      </InputGroup>
    </div>
  );
}

type DateTimeSelectorProps = {
  defaultDate?: Date;
  date?: Date;
  min?: Date;
  max?: Date;
  onChange?: (dt: Date) => void;
};

export function DateTimeSelector({
  defaultDate = new Date(),
  onChange = () => {},
  date,
  min,
  max,
}: DateTimeSelectorProps) {
  const [val, setVal] = useState(date ?? defaultDate);
  const fVal = date
    ? date.toISOString().slice(0, 10)
    : val.toISOString().slice(0, 10);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dt;
    if (e.target.value === "") dt = defaultDate;
    else dt = new Date(e.target.value);
    setVal(dt);
    onChange(dt);
  };
  return (
    <Form.Control
      type="date"
      value={fVal}
      onChange={handleChange}
      min={min ? min.toISOString().slice(0, 10) : undefined}
      max={max ? max.toISOString().slice(0, 10) : undefined}
    />
  );
}
