import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";

import FormControl from "react-bootstrap/FormControl";
import { InputGroup } from "react-bootstrap";

import 'scss/FilterHeader.scss'


const CustomToggle = React.forwardRef(({ children, onClick, active }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className={active ? "active" : undefined}
  >
    {children}
  </div>
));

CustomToggle.displayName = "CustomToggle";

CustomToggle.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

const CustomMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labeledby": labeledBy,
      field,
      searchable,
      getSearch,
      sortable,
      onSearch,
      getSort,
      onSort,
    },
    ref
  ) => {
    const activeSort = getSort().field === field ? getSort().order : "";
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labeledby={labeledBy}
      >
        {searchable && (
          <InputGroup>
            <FormControl
              type="search"
              autoFocus
              placeholder="Search..."
              onChange={(e) => {
                onSearch(field)(e.target.value);
              }}
              value={getSearch(field)}
            />
            <div className="clearButton" onClick={() => onSearch(field)("")}>
              <i className="fa fa-times" />
            </div>
          </InputGroup>
        )}
        {sortable && (
          <span className="sort-buttons">
            <a
              onClick={() => onSort(field)("ASC")}
              className={activeSort === "ASC" && "active"}
            >
              <i className="fa fa-sort-alpha-asc" />
            </a>
            <a
              onClick={() => onSort(field)("DESC")}
              className={activeSort === "DESC" && "active"}
            >
              <i className="fa fa-sort-alpha-desc" />
            </a>
          </span>
        )}
        {children && <ul className="list-unstyled">{children}</ul>}
      </div>
    );
  }
);

CustomMenu.displayName = "CustomMenu";

CustomMenu.propTypes = {
  field: PropTypes.string,
  searchable: PropTypes.bool,
  sortable: PropTypes.bool,
  onSearch: PropTypes.func,
  getSearch: PropTypes.func,
  onSort: PropTypes.func,
  getSort: PropTypes.func,
  "aria-labeledby": PropTypes.string,
  className: PropTypes.any,
  style: PropTypes.any,
  children: PropTypes.any,
};

/* What do I need to pass to this?
 * field
 * label
 * onFilter
 * onSort
 * onSearch
 * filters -- determine what filters are available
 * filtered -- determine what is filtered.
 * searched -- value for the search box...
 */

function TH(props) {
  return <th {...props}>
    {props.children}
  </th>
}

TH.propTypes = {
  children: PropTypes.any,
}

function SortIcon({ field, sortKey, sortOrder}) {
  let classes = ["fa"];
  if (sortKey===field && (sortOrder === "ASC" || sortOrder === "DESC")) {
    classes.push("fa-sort-alpha-" + sortOrder.toLowerCase())
    classes.push("active");
  }
  else
    classes.push("fa-bars");
  return (<i className={classes.join(' ')} />)
}

SortIcon.propTypes = {
  field: PropTypes.string,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
}

function FilterHeader({ field, controls, ...props }) {
  const active = controls.filtered || controls.searched || controls.sorted;
  return (
    <Dropdown
      as={TH}
      className="FilterHeader"
      autoClose="outside"
      onSelect={(e) => {
        controls.onFilter(field.field)(e);
      }}
    >
      <Dropdown.Toggle as={CustomToggle} active={active} className="toggle">
        {field.label}
        {field.sortable && (
          <SortIcon field={field.field} sortKey={controls.sortKey} sortOrder={controls.sortOrder} />
        )}

        {field.filterable && (
          <i className={"fa fa-filter " + ((controls.getFilter(field.field) && controls.getFilter(field.field).length >0) ? "active" : "")} />
          )}

        {field.searchable && (
          <i className={"fa fa-search " + ((controls.getSearch(field.field) && controls.getSearch(field.field).length >0) ? "active" : "")} />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={
          CustomMenu
        }
        {...props}
        field={field.field}
        searchable={field.searchable}
        sortable={field.sortable}
        onSearch={controls.onSearch}
        onSort={controls.onSort}
        getSort={controls.getSort}
        getSearch={controls.getSearch}
      >
        {field.filterable &&
          field.filters &&
          field.filters.map((filter) => (
            <Dropdown.Item key={filter.value} eventKey={filter.value}>
              {/* {console.log('DropdownItem GetFilter', field.field, controls.getFilter(field.field))} */}
              <i className={"fa fa-" + (controls.getFilter(field.field).includes(filter.value) ? "check-square-o" : "square-o")} />
              {filter.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

FilterHeader.propTypes = {
  field: PropTypes.objectOf({
    field: PropTypes.string,
    label: PropTypes.string,
    filterable: PropTypes.bool,
    sortable: PropTypes.bool,
    searchable: PropTypes.bool,
    filters: PropTypes.array,
  }),
  controls: PropTypes.objectOf({
    filterable: PropTypes.bool,
    filtered: PropTypes.array,
    searchable: PropTypes.bool,
    searched: PropTypes.array,
    sortable: PropTypes.bool,
    sorted: PropTypes.string,
    onSort: PropTypes.func,
    onFilter: PropTypes.func,
    onSearch: PropTypes.func,
  }),
};

FilterHeader.defaultProps = {
  onSearch: () => {},
  onSort: () => {},
  sorted: "",
};

export default FilterHeader;
