import PropTypes from "prop-types";
import React, { FC, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DataController from "components/echelon/DataController";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import axios from "axios";
import "scss/AdminNotes.scss";
import DataTable from "components/echelon/DataTable";
import DeepLinks from "components/echelon/DeepLinks";
import APIDataTable from "components/echelon/APIDataTable";
import FontAwesome from "components/util/FontAwesome";
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";

const headers = [
  {
    key: "alert",
    label: "Alert",
    sortable: true,
    filterable: true,
    searchable: false,
    filters: [
      {
        label: "Alert",
        value: "1",
      },
      {
        label: "Note",
        value: "0",
      },
    ],
  },
  {
    key: "timestamp",
    label: "Date",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: "nickname",
    label: "Admin",
    sortable: false,
    filterable: false,
    searchable: true,
  },
  {
    key: "note",
    label: "Notes",
    sortable: false,
    filterable: false,
    searchable: true,
  },
];

interface AdminNoteProps {
  id: number;
  client_id: number;
  timestamp: number;
  admin_id: number;
  note: string;
  alert: boolean;
  admin_nickname: string;
}

const AdminNote: FC<AdminNoteProps> = ({
  id,
  client_id,
  timestamp,
  admin_id,
  note,
  alert,
  admin_nickname,
  ...props
}) => {
  const [alertStatus, setAlertStatus] = useState<boolean>(alert);
  useEffect(() => {
    setAlertStatus(alert);
  }, [alert])
  const changeAlert = (status: boolean) => {
    axios.get(`/api/v2/clients/${client_id}/notes`).then(r => {
      console.log(r);
    });
    axios.put(`/api/v2/clients/${client_id}/notes`, {
      note: {
        id,
        alert: status
      }
    }).then(res => {
      console.log(res);
      setAlertStatus(status);
    }).catch( e => {
      console.log(e);
    });
  };
  return (
    <tr className="AdminNote" {...props} key={id}>
      <td>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Toggle Alert Satus</Tooltip>}
        >
          <Button variant={alertStatus ? "danger" : undefined} size="sm" onClick={() => changeAlert(!alertStatus)}>
            <FontAwesome icon={alertStatus ? "bell" : "bell-slash"} />
          </Button>
        </OverlayTrigger>
      </td>
      <td>
        <Moment unix format="MM/DD/YYYY hh:mma" tz="America/Los_angeles">
          {timestamp}
        </Moment>
      </td>
      <td>
        {admin_nickname ? (
          <Link
            to={`/echelon/clientDetails/${admin_id}`}
            style={{ textDecoration: "underline" }}
          >
            {admin_nickname}
          </Link>
        ) : (
          "B3"
        )}
      </td>
      <td>{note}</td>
    </tr>
  );
};

interface AdminNotesProps {
  clientid: number;
}
const AdminNotes: FC<AdminNotesProps> = ({ clientid, ...props }) => {
  const [alert, setAlert] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [refresh, setRefresh] = useState<number>(0);

  const saveAlert = () => {
    axios
      .post(`/api/v2/clients/${clientid}/notes`, {
        note: {
          client_id: clientid,
          note,
          alert: alert ? 1 : 0,
        },
      })
      .then((res) => {
        setAlert(false);
        setNote("");
        setRefresh(refresh + 1);
      })
      .catch((err) => {
        console.log("admin note err: ", err);
      });
  };
  return (
    <div className="AdminNotes content">
      <h1>Admin Notes</h1>
      {clientid && (
        <APIDataTable
          cached
          endpoint={`/api/v2/clients/${clientid}/notes?limit=none&nocount`}
          refresh={refresh}
          defaultNumPerPage={10}
          defaultSortKey="timestamp"
          defaultSortOrder="DESC"
          row={AdminNote}
          headers={headers}
          datakey="notes"
        />
      )}
      <Form.Group>
        <InputGroup>
          <DropdownButton
            variant={alert ? "warning" : "secondary"}
            title={
              alert ? (
                <>
                  <FontAwesome icon="eye" /> Alert
                </>
              ) : (
                "No Alert"
              )
            }
          >
            <Dropdown.Item href="#" onClick={() => setAlert(true)}>
              <FontAwesome icon="eye" /> Alert
            </Dropdown.Item>
            <Dropdown.Item href="#" onClick={() => setAlert(false)}>
              No Alert
            </Dropdown.Item>
          </DropdownButton>

          <Form.Control
            type="text"
            placeholder="Type a note..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></Form.Control>
          <Button variant="success" onClick={saveAlert}>
            Save
          </Button>
        </InputGroup>
      </Form.Group>
    </div>
  );
};

export default AdminNotes;
