import PropTypes from 'prop-types'
import React from 'react'
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'
import AutoSizer from 'react-virtualized-auto-sizer'
import Message from './Message'
import 'scss/Serverlog.scss'


// function getPlayerFromId(players, id, msgs) {
//     for(var i=0; i<players.length; i++) {
//         if(players[i].id === id) {
//             return Object.assign({}, players[i]);
//         }
//     }
// }


function formPlayerFromId(players, id, message) {
 // Player requires:
 /* {
        name:
        guid:
        slot:
        id:
     }   */


     var plName;
     try {
         if (message.newName){
             plName = message.newName;
         }
         else if(message.name) {
             plName = message.name;
         }
         else {
             plName = 'Not Found';
         }
     } catch(e) {
         plName = 'Test';
     }

     return {
         name: plName,
         guid: message.guid || 'NA',
         slot: message.slot || 'NA',
         id: message.id
     }
}

class ServerLog extends React.PureComponent {
    constructor(props) {
        super(props)
        const cache = new CellMeasurerCache({
            defaultHeight: 21,
            fixedWidth: true,
            // keyMapper: () => 1,
        })
        this.state = {
            visibleStopIndex: 500,
            numberOfMessages: 0,
        };
        this.onRowsRendered = this.onRowsRendered.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.row = this.row.bind(this);
        this.cache = cache;
    }

    row({index, parent, key, style}) {
        var message = this.props.messages[index];
        if (!message)
            return null;
        var pls = this.props.players;
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                <Message
                    style={style}
                    msg={message}
                    player={formPlayerFromId(pls, message.id, message)}
                />
            </CellMeasurer>
        )
    }

    onRowsRendered({stopIndex}) {
        if (stopIndex >= this.props.messages.length-2) {
            this.setState({
                visibleStopIndex: this.props.messages.length,
            })
        } else if(this.state.visibleStopIndex !== undefined) {
            this.setState({
                visibleStopIndex: undefined,
            })
        }
    }

    handleClick(e) {
        e.preventDefault();
        console.log(this.props.messages, this.props.players);
    }

    render() {
        return (
            <div className={"chatlog " + (this.props.weviewVisible ? 'weview-visiblez' : '')} ref={this.props.weviewContainerRef}>
            <AutoSizer>
                {({width, height}) => (
                    <List
                        height={height}
                        width={width}
                        ref={this.listRef}
                        deferredMeasurementCache={this.cache}
                        rowHeight={this.cache.rowHeight}
                        rowRenderer={this.row}
                        rowCount={this.props.messages.length}
                        scrollToIndex={this.state.visibleStopIndex}
                        onRowsRendered={this.onRowsRendered}
                    />
                )}
            </AutoSizer>
            </div>
        );
    }
}

ServerLog.propTypes = {
    messages: PropTypes.array.isRequired,
    players: PropTypes.array.isRequired,
    weviewVisible: PropTypes.bool,
    weviewContainerRef: PropTypes.any,
}

ServerLog.defaultProps = {
    messages : [],
    players: [],
};

export default ServerLog
