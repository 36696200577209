import PropTypes from "prop-types";
import React, { FC, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "scss/CommandButton.scss";
import { useSelector } from "react-redux";

interface CommandButtonField {
  name: string;
  label: string;
  description?: string;
  type: "text";
  required: boolean;
  validation?: any;
}

interface CommandButtonProps {
  label?: string;
  description?: string;
  permission: string;
  confirm?: string;
  fields?: CommandButtonField[];
  onConfirm?: Function;
  onCancel?: Function;
  button: any;
  defaultValues?: any;
}
const CommandButton: FC<CommandButtonProps> = ({
  label,
  permission,
  confirm,
  fields,
  onConfirm = (e: any) => {},
  onCancel = (e: any) => {},
  button,
  defaultValues,
  description,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const admin = useSelector((state : any) => state.admin.info);

  const handleClick = (e: any) => {
    e.preventDefault();
    if (confirm) {
      if (!show) setShow(true);
      else onConfirm(e);
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault && e.preventDefault();
    let vals = { ...values };
    vals[e.target.name] = e.target.value;
    setValues(vals);
  };

  useEffect(() => {
    setValues(defaultValues);
  }, [show]);

  const handleConfirm = (e: any) => {
    e.preventDefault();
    let newErrors: any = {};
    if (fields) {
      for (let i in fields) {
        let key = fields[i].name;
        if (fields[i].required) {
          if (
            values[key] === undefined ||
            (typeof values[key] === "string" && values[key].trim() === "")
          ) {
            newErrors[key] = "Required";
            continue;
          }
        }
        if (values[key] && fields[i].validation) {
          if (typeof fields[i].validation === "function") {
            let res = fields[i].validation(values[key]);
            if (res !== true) {
              newErrors[key] = res;
            }
          } else {
            if (!values[key].match(fields[i].validation)) {
              newErrors[key] = "Invalid";
            }
          }
        }
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length <= 0) {
      onConfirm(values);
      setShow(false);
    } else {
      setErrors(newErrors);
    }
  };

  if (!admin.perms.includes(permission) && !admin.roles.includes('Super Admin')) {
    return <></>
  }

  return (
    <div className="CommandButton">
      {typeof button === "function" ? (
        button({ onClick: handleClick })
      ) : React.Children.count(button) !== 0 ? (
        React.cloneElement(React.Children.only(button), {
          onClick: handleClick,
        })
      ) : (
        <Button {...props}>{label}</Button>
      )}
      {confirm && (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm {label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>{description}</Col>
            </Row>
            <hr />
            {typeof confirm === "string" && confirm}
            {fields &&
              fields.map((f) => (
                <Row key={f.name}>
                  <Col>
                    {f.type && typeof f.type !== "string" ? (
                      React.cloneElement(f.type, {
                        value: values[f.name],
                        error: errors[f.name],
                        onChange: handleChange,
                      })
                    ) : (
                      <Form.Group>
                        <Form.Label>{f.label}</Form.Label>
                        <Form.Control
                          {...f}
                          type="text"
                          value={values[f.name]}
                          onChange={handleChange}
                          isInvalid={errors[f.name]}
                        />
                        {f.description && (
                          <Form.Text className="text-muted">
                            {f.description}
                          </Form.Text>
                        )}
                        {errors[f.name] && (
                          <Form.Control.Feedback type="invalid">
                            {errors[f.name]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setShow(false);
                onCancel();
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirm} variant="success">
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default CommandButton;
