export const TEAMS = {
    ALLIES: "allies",
    AXIS: "axis",
    SPECTATOR: "spectator",
};

export const MAPS = {
    MP_BACKLOT: "mp_backlot",
    MP_BLOC: "mp_bloc",
    MP_BOG: "mp_bog",
    MP_BROADCAST: "mp_broadcase",
    MP_CARENTAN: "mp_carentan",
    MP_CARGOSHIP: "mp_cargoship",
    MP_CITYSTREETS: "mp_citystreets",
    MP_CONVOY: "mp_convoy",
    MP_COUNTDOWN: "mp_countdown",
    MP_CRASH: "mp_crash",
    MP_CRASH_SNOW: "mp_crash_snow",
    MP_CROSSFIRE: "mp_crossfire",
    MP_DUSK: "mp_dusk",
    MP_FARM: "mp_farm",
    MP_HILL: "mp_hill",
    MP_KILLHOUSE: "mp_killhouse",
    MP_OVERGROWN: "mp_overgrown",
    MP_PIPELINE: "mp_pipeline",
    MP_SHIPMENT: "mp_shipment",
    MP_SHOWDOWN: "mp_showdown",
    MP_STRIKE: "mp_strike",
    MP_VACANT: "mp_vacant",
};

