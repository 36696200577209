/* eslint-disable no-control-regex */
import React from 'react'
import { connect } from 'react-redux'
import Player from 'components/weniss/Player'
import CopyOnly from 'components/util/CopyOnly'
import Moment from 'react-moment'
import moment from 'moment'
import MessageIcon from 'components/util/MessageIcon'
import FontAwesome from 'components/util/FontAwesome'
import ColoredText from 'components/util/ColoredText'
import 'moment-timezone'
import PropTypes from 'prop-types'
import 'scss/Message.scss'
// import UIfx from 'uifx'
import { serverLogAlert } from 'actions'

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            alertClass: "",
        }

    }

    shouldCompopnentUpdate() {
        if (this.state.initialized)
            return false;
        // this.setState({initialized: true});
        return true;
    }

    render() {
        var msgClass = "";
        if (this.props.msg.muted) {
            msgClass = "muted ";
        }
        if (this.props.msg.type === "webcmd") {
            msgClass += "cmd ";
        }
        let alertClass = "";
        // handle the alert...
        if (this.props.msg.type === "chat") {
            let alerts = this.props.adminAlerts;
            let msg = this.props.msg.message;
            if (!msg.alertPlayed) {
                for (let i in alerts) {
                    if (alerts[i].rxp.test(msg)) {
                        switch(alerts[i].level) {
                            case 'alert':
                            alertClass = " msg-alert";
                            break;
                            case 'notice':
                            default:
                            alertClass = " msg-mention";
                            break;
                        }
                        break;
                    }
                }
            }
        }

        let player = {...this.props.player, name: this.props.msg.name || this.props.msg.newname}

        return (
            <p style={this.props.style} className={"msg " + this.props.msg.id + alertClass}>
                <Moment
                    date={moment.unix(Math.floor(this.props.msg.timestamp))}
                    format="HH:mm:ss"
                    withTitle
                    titleFormat="ddd, MMM DD, YYYY HH:mm:ss"
                    tz="America/Los_Angeles"/>&nbsp;
                <CopyOnly text=" " />
                <MessageIcon type={this.props.msg.type} />
                {this.props.player &&
                    <Player
                        showSlot={true}
                        static
                        name={player.name}
                        guid={player.guid}
                        slot={player.slot}
                        id={player.id || ''}
                        />
                }
                {this.props.player &&
                    <CopyOnly text=": " />
                }
                {this.props.msg.muted &&
                    <FontAwesome icon="microphone-slash" />
                }
                {this.props.msg.team &&
                    <span className="team">(team)</span>
                }
                {this.props.msg.message &&
                    // <span className={msgClass}><FormatMessage msg={this.props.msg.message} /></span>
                    <React.Fragment>
                        {/* <span className={msgClass}>{this.props.msg.message.replace(/[^\x00-\x7F]/g, "\ue900")}</span> */}
                        <ColoredText className={msgClass}>{this.props.msg.message.replace(/[^\x00-\x7F]/g, "\ue900")}</ColoredText>
                    </React.Fragment>
                }
                {this.props.msg.type === "rename" &&
                    <span>from {this.props.msg.oldname} to {this.props.msg.newname}</span>
                }

            </p>
        )
    }
}

Message.propTypes = {
    type: PropTypes.string.isRequired,
    guid: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slot: PropTypes.string.isRequired,
    player: PropTypes.shape(),
    style: PropTypes.shape(),
    msg: PropTypes.shape({
        id: PropTypes.string,
        timestamp: PropTypes.number,
        message: PropTypes.string,
        name: PropTypes.string,
        oldname: PropTypes.string,
        newname: PropTypes.string,
        type: PropTypes.string,
        muted: PropTypes.bool,
        team: PropTypes.string,
    }),
    message: PropTypes.string,
    timestamp: PropTypes.number.isRequired,
    adminAlerts: PropTypes.array,
    serverLogAlert: PropTypes.func,
}

Message.defaultProps = {
    type: '',
    guid: '',
    id: '',
    name: '',
    slot: '',
    timestamp: 0,
};

export default connect(
    (state) => ({
        adminAlerts: state.admin.alerts,
    }),
    (dispatch) => ({
        serverLogAlert: id => dispatch(serverLogAlert(id)),
    })
)(Message)
