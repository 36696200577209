import DataTable, { Header } from "components/echelon/DataTable";
import Loading from "components/echelon/Loading";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "modules/axios";
import useDataControls from "modules/useDataControls";
import useDataFilter, { DataRow } from "modules/useDataFilter";

interface UserRole {
  user_id: number;
  user_name: string;
  user_login: string;
  user_nickname?: string;
  role_id: number;
  role_name: string;
}

interface Role {
  id: number;
  name: string;
}

interface Perm {
  id: number;
  name: string;
  description: string;
}

interface RolePerm {
  perm: Perm;
  roles: Role[];
}

type RolePermProps = {
  perm: Perm;
  allRoles: Role[];
  roles: Role[];
  onChange?: Function;
};

const RolePerm = ({
  perm,
  roles,
  allRoles,
  onChange = (role_id: number, perm_id: number, has: boolean) => {
    console.log("Default onChange");
  },
}: RolePermProps) => {
  const hasRole = useCallback(
    (id: number) => {
      for (let r in roles) {
        if (roles[r].id === id) return true;
      }
      return false;
    },
    [roles]
  );

  return (
    <tr>
      <td>
        <span className="strong">{perm.name}</span>
        <br />
        <span className="small">{perm.description}</span>
      </td>
      {allRoles.map((role) => (
        <td>
          <Form.Check
            checked={hasRole(role.id)}
            onChange={() => onChange(perm.id, role.id, !hasRole(role.id))}
          />
        </td>
      ))}
    </tr>
  );
};

type Props = {};

const RolePermissions = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingPerms, setLoadingPerms] = useState(true);
  const [roles, setRoles] = useState<Role[]>([]);
  const [perms, setPerms] = useState<Perm[]>([]);
  const [headers, setHeaders] = useState<Header[]>([]);

  const { controls, onControl } = useDataControls({
    dataKey: "roleperms",
    useDeepLinks: false,
    defaults: { numPerPage: 10000 },
  });

  const { setFullData, filteredData } = useDataFilter({
    controls,
    headers,
  });

  const loadRolePerms = useCallback(() => {
    setLoading(true);
    axios.get(`/api/roles/rolepermissions`).then((res) => {
      if (res.data.rolepermissions) {
        let rp = res.data.rolepermissions;
        let rolePerms: RolePerm[] = [];
        console.log("Permissions: ", perms);
        for (let p in perms) {
          rolePerms.push({
            perm: perms[p],
            roles: [],
          });
        }
        for (let i in rp) {
          let exists = false;
          for (let j in rolePerms) {
            if (rp[i].perm_id === rolePerms[j].perm.id) {
              rolePerms[j].roles.push({
                id: rp[i].role_id,
                name: rp[i].role_name,
              });
              exists = true;
            }
          }
          if (!exists) {
            rolePerms.push({
              perm: {
                id: rp[i].perm_id,
                name: rp[i].perm_name,
                description: rp[i].perm_desc,
              },
              roles: [
                {
                  id: rp[i].role_id,
                  name: rp[i].role_name,
                },
              ],
            });
          }
        }
        console.log("Setting Data", rolePerms);
        setFullData(rolePerms);
        setLoading(false);
      }
    });
  }, [roles, perms]);

  const loadAllRoles = useCallback(() => {
    setLoadingRoles(true);
    axios.get(`/api/roles`).then((res) => {
      let _roles = res.data.roles;
      let _headers: Header[] = [
        {
          key: "perm",
          label: "Permission",
          searchable: true,
          searchFunc: (data: DataRow, val: string | string[]) => {
            if (
              data.perm.name.includes(val) ||
              data.perm.description.includes(val)
            )
              return true;
            return false;
          },
        },
      ];
      for (let r in _roles) {
        _roles[r].id = _roles[r].role_id;
        _roles[r].name = _roles[r].role_name;
        _roles[r].role_id = undefined;
        _roles[r].role_name = undefined;
        _headers.push({
          key: _roles[r].name,
          label: _roles[r].name,
        });
      }
      setRoles(_roles);
      setHeaders(_headers);

      setLoadingRoles(false);
    });
  }, []);

  const loadAllPermissions = useCallback(() => {
    setLoadingPerms(true);
    axios.get(`/api/roles/permissions`).then((res) => {
      let _perms = res.data.permissions;
      console.log(res);
      let newPerms: Perm[] = [];
      for (let p in _perms) {
        newPerms.push({
          id: _perms[p].perm_id,
          name: _perms[p].perm_name,
          description: _perms[p].perm_desc,
        });
      }
      setPerms(newPerms);
      setLoadingPerms(false);
    });
  }, []);

  const roleName = useCallback(
    (id: number) => {
      for (let i in roles) {
        if (roles[i].id === id) return roles[i].name;
      }
      return null;
    },
    [roles]
  );

  const permName = useCallback(
    (id: number) => {
      for (let i in perms) {
        if (perms[i].id === id) return perms[i].name;
      }
      return null;
    },
    [perms]
  );

  useEffect(() => {
    loadAllRoles();
    loadAllPermissions();
    // loadRolePerms();
  }, []);

  useEffect(() => {
    if (loadingPerms === false && loadingRoles === false) {
      console.log('Laoad role prms', perms, roles);
      loadRolePerms();
    }
  }, [loadingPerms, loadingRoles])

  const onChangeRolePerm = useCallback(
    (perm_id: number, role_id: number, has: boolean) => {
      console.log("Change Role Perm", role_id, perm_id, has);
      let role = roleName(role_id);
      let perm = permName(perm_id);
      if (role !== undefined && perm !== undefined) {
        if (has) {
          axios.post(`/api/roles/${role_id}/${perm_id}`).then((res) => {
            console.log("Result", res);
            loadRolePerms();
          });
        } else {
          axios.delete(`/api/roles/${role_id}/${perm_id}`).then((res) => {
            console.log("Result", res);
            loadRolePerms();
          });
        }
      }
    },
    []
  );

  return (
    <div className="WenissSettingPage RolePermissions">
      {(loading || loadingRoles || loadingPerms) && <Loading />}
      <DataTable
        row={RolePerm}
        data={filteredData}
        headers={headers}
        onControl={onControl}
        controls={controls}
        nopagination
        rowProps={{
          allRoles: roles,
          onChange: onChangeRolePerm,
        }}
      />
    </div>
  );
};

export default RolePermissions;
