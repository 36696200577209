import PropTypes from 'prop-types';
import React from 'react';
import 'scss/Pagination.scss';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    onClick(p) {
        if (this.props.queryParam !== undefined) {
            const params = new URLSearchParams(this.props.location.search);
            params.set(this.props.queryParam, p);
            this.props.history.replace({location: this.props.location, search: params.toString()})
        }
        if (this.props.changePage !== undefined) {
            this.props.changePage(p);
        }
    }

    render() {
    let page = this.props.page || 0;
    let num = this.props.num || 15;
    let lastPage = Math.ceil(this.props.totalItems/num)-1;
    let nextPage = (page < lastPage
                    ? page + 1
                    : lastPage);
    let next2Page = (page < lastPage-2 ? page+2 : lastPage);
    let next10Page = (page < lastPage-10 ? page+10 : lastPage);
    let prev2Page = (page > 2 ? page-2 : 0);
    let prevPage = (page > 0 ? page-1 : 0);
    let prev10Page = (page > 10 ? page-10 : 0);
        return (
            <ul className="pagination">
                <li className="page-item">
                    <span onClick={() => this.onClick(0)} className="page-link">First</span>
                </li>
                <li className="page-item"><span onClick={() => this.onClick(prevPage)} className="page-link">{"<"}</span></li>
                <li className={"page-item" + (prev10Page > 0 || page === 10 ? "" : " disabled")}><span onClick={() => this.onClick(prev10Page)} className="page-link">...</span></li>
                <li className={"page-item" + (prev2Page > 0 || page === 2 ? "" : " disabled")}><span onClick={() => this.onClick(prev2Page)} className="page-link">{prev2Page}</span></li>
                <li className={"page-item" + (prevPage > 0 || page === 1 ? "" : " disabled")}><span onClick={() => this.onClick(prevPage)} className="page-link">{prevPage}</span></li>
                <li className="page-item active"><span onClick={() => this.onClick(page)} className="page-link">{page}</span></li>
                <li className={"page-item" + (nextPage < lastPage || page === lastPage-1 ? "" : " disabled")}><span onClick={() => this.onClick(nextPage)} className="page-link">{nextPage}</span></li>
                <li className={"page-item" + (next2Page < lastPage || page === lastPage-2 ? "" : " disabled")}><span onClick={() => this.onClick(next2Page)} className="page-link">{next2Page}</span></li>
                <li className={"page-item" + (next10Page < lastPage || page === lastPage-10 ? "" : " disabled")}><span onClick={() => this.onClick(next10Page)} className="page-link">...</span></li>
                <li className="page-item"><span onClick={() => this.onClick(nextPage)} className="page-link">{">"}</span></li>
                <li className="page-item"><span onClick={() => this.onClick(lastPage)} className="page-link">Last</span></li>
            </ul>
        )
    }
}
Pagination.propTypes = {
    queryParam: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
    changePage: PropTypes.func,
    page: PropTypes.number,
    num: PropTypes.number,
    totalItems: PropTypes.number

}

export default Pagination;
