import React from 'react'

type Props = {}

const Roles = (props: Props) => {
  return (
    <div>Roles</div>
  )
}

export default Roles