import React from 'react'
import PropTypes from 'prop-types'
import Player from 'components/weniss/Player'
import HoveredPlayerHighlight from 'components/util/HoveredPlayerHighlight'
import "scss/PlayerList.scss"

function titleCase(name) {
    return name.toUpperCase().substr(0, 1) + name.toLowerCase().substr(1);
}

const Team = (props) => (
    <div className="team">
        <span className={props.teamName.toLowerCase()}>{titleCase(props.teamName)}&nbsp;
            <span className="playerCount">{props.players.length}</span>
            <span className="score">{props.teamScore}</span>
        </span>
        <ul team={props.teamName.toLowerCase()}>
            {props.players.sort((a, b) => (parseInt(a.slot) > parseInt(b.slot))).map(player =>
                <li key={player.id} className={"__" + player.id + (player.id === props.selectedPlayer ? " selected" : "")}>
                    <Player
                        showFlag={true}
                        showKey={true}
                        showSlot={true}
                        showTitle={true}
                        showAlert={true}
                        id={player.id}
                        name={player.name}
                        slot={player.slot}
                        guid={player.guid}
                        alert={player.alert}
                        muted={player.muted ? true : false}
                        ipFlag={player.ipinfo ? player.ipinfo.flag : ''}
                        ipCountry={player.ipinfo ? player.ipinfo.country_name : ''}
                        ipAddress={player.ipinfo ? player.ipinfo.ip_address : ''}

                    />
                </li>
            )}
        </ul>
    </div>
);

Team.propTypes = {
    teamName: PropTypes.string.isRequired,
    players: PropTypes.array.isRequired,
    teamScore: PropTypes.number,
    selectedPlayer: PropTypes.string
    // selectedPlayer: PropTypes.string,
};


class PlayerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: props.players,
            allies: props.players.filter(p => (p.connected && p.team === "allies")),
            axis: props.players.filter(p => (p.connected && p.team === "axis")),
            spectators: props.players.filter(p => (p.connected && p.team !== "allies" && p.team !== "axis")),

        }
    }

    // getDerivedStateFromProps(nProps) {
    //     this.setState({
    //         players: nProps.players,
    //         allies: nProps.players.filter(p => (p.connected && p.team == "allies")),
    //         axis: nProps.players.filter(p => (p.connected && p.team == "axis")),
    //         spectators: nProps.players.filter(p => (p.connected && p.team != "allies" && p.team != "axis")),
    //     });
    // }

    render() {
        if (this.props.serverinfo['g_gametype'] !== 'dm') {
            let allies = this.props.players.filter(p => (p.connected && p.team === "allies"));
            let axis = this.props.players.filter(p => (p.connected && p.team === "axis"));
            let spectators = this.props.players.filter(p => (p.connected && p.team !== "allies" && p.team !== "axis"));
            return (
                <div className="PlayerList">
                <Team teamName="allies"
                players={allies}
                selectedPlayer={this.props.selectedPlayer}
                teamScore={this.props.teamScores["allies"]} />
                <Team teamName="axis"
                players={axis}
                selectedPlayer={this.props.selectedPlayer}
                teamScore={this.props.teamScores["axis"]} />
                <Team teamName="spectators"
                players={spectators}
                selectedPlayer={this.props.selectedPlayer} />
                <HoveredPlayerHighlight />
                </div>
            );
        } else {
            let playing = this.props.players.filter(p => (p.connected && (p.team === "allies" || p.team === "axis" || p.team === "free")));
            let spectators = this.props.players.filter(p => (p.connected && p.team !== "allies" && p.team !== "axis" && p.team !== "free"));
            return (
                <div className="PlayerList">
                <Team teamName="playing"
                players={playing}
                selectedPlayer={this.props.selectedPlayer} />
                <Team teamName="spectators"
                players={spectators}
                selectedPlayer={this.props.selectedPlayer} />
                <HoveredPlayerHighlight />
                </div>
            )
        }
    }
}

PlayerList.propTypes = {
    players: PropTypes.arrayOf(PropTypes.shape(Player.propTypes)).isRequired,
    selectedPlayer: PropTypes.string,
    serverinfo: PropTypes.object,
    teamScores: PropTypes.object,
}

export default PlayerList
