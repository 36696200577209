import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { adminLogin } from "actions";
import FontAwesome from "components/util/FontAwesome";
import Navigation from "components/Navigation";
import Login from "pages/Login";
import AdminBans from "routes/AdminBans";
import ClientDetails from "routes/ClientDetails";
/* Echelon components */
import Clients from "routes/Clients";
import Echelon from "pages/Echelon";
import Weniss from "pages/Weniss";
import "scss/App.scss";
import "scss/styles.scss";
import Auth from "services/Authentication";
import ServerDetails from "routes/ServerDetails";
import Penalties from "routes/Penalties";
import Chatlog from "routes/Chatlog";
import Test from "routes/Test";
import Servers from "routes/Servers";

function Private(props : any) {
  if (Auth.isLoggedIn()) return props.children;
  else return <Navigate to="/login" />;
}

Private.propTypes = {
  children: PropTypes.array,
};

function App(props : any) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.RefreshLogin()
      .then((data) => {
        props.adminLogin(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })

  }, [])
  
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            {loading ? (
              <Route
                path="/"
                element={
                  <div className="loading">
                    <FontAwesome icon="spinner" spin />
                  </div>
                }
              />
            ) : (
              <React.Fragment>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/echelon"
                  element={
                    <Private>
                      <Navigation />
                      <Echelon />
                    </Private>
                  }
                >
                  <Route path="/echelon/clients" element={<Clients />} />
                  <Route
                    path="/echelon/clientdetails/:clientid"
                    element={<ClientDetails />}
                  />
                  <Route path="/echelon/penalties" element={<Penalties />} />
                  <Route path="/echelon/reports" element={<AdminBans />} />
                  <Route path="/echelon/chatlog" element={<Chatlog />} />
                  <Route path="/echelon/servers" element={<Servers />} />
                  <Route
                    path="/echelon/servers/:serverid"
                    element={<ServerDetails />}
                  />
                  <Route path="/echelon/" element={<Clients />} />
                  <Route path="/echelon/test" element={<Test />} />
                </Route>
                <Route
                  path="/*"
                  element={
                    <Private>
                      <Navigation />
                      <Weniss />
                    </Private>
                  }
                />
              </React.Fragment>
            )}
          </Routes>
        </BrowserRouter>
      </div>
  )
}


export default connect(
  (state : any) => ({ admin: state.admin.info }),
  (dispatch) => ({ adminLogin: (userData : any) => dispatch(adminLogin(userData)) })
)(App);
