import Activity from "components/echelon/Activity";
import Loading from "components/echelon/Loading";
import { useDeepLinking } from "modules/deepLinking";
import PropTypes from "prop-types";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import "scss/ClientDetails.scss";


// const defaultClient = {
//   name: "",
//   id: "",
//   guid: "",
//   time_add: "",
//   clientid: "",
//   time_edit: "",
//   greeting: "",
//   pbid: "",
//   connections: 0,
// };

const Section = (props) => {
  return (
    <Col className="section" {...props}>
      {props.children}
    </Col>
  );
};
Section.propTypes = {
  children: PropTypes.any,
};

function ServerDetails() {
  // const [client, setClient] = useState(defaultClient);
  const [loading, /* setLoading */] = useState(false);
  const params = useParams();
  const [serverid, /* setServerid */] = useState(params.serverid);

  const [
    ,
    ,
    ,/* getSearchParam */
    /* setSearchParam */
    /* addSearchParamValue */
    /* deleteSearchParam */
  ] = useDeepLinking("clientDetails");
  
  // if (client == null) {
  //   axios.get(`/api/clients/${params.clientid}`).then((res) => {
  //     console.log('Returns', res.data);
  //     setClient(res.data);
  //   })
  //   .catch( (e) => {
  //       console.log(e);
  //   })
  // }
  // if (client == null)
  //   return ( <Container fluid className="ServerDetails">Loading...</Container>)

  return (
    <div fluid className="ServerDetails" style={{ position: "relative" }}>
      {loading && <Loading />}
      <Row>
        <Section xs={12}>
          <Activity serverid={serverid} />
        </Section>
        {/* <Section xs={6}>
          <Aliases clientid={clientid} />
        </Section>
        <Section xs={6}>
          <IPLogs clientid={clientid} />
        </Section>
        <Section xs={6}>
          <Reports clientid={client.id} />
        </Section>
        <Section xs={6}>
          <AdminNotes clientid={client.id} />
        </Section>
        <Section xs={12}>
          <Penalties clientid={client.id} />
        </Section>
        <Section xs={12}>
          <Chatlog singleClient clientid={clientid} />
        </Section> */}
      </Row>
    </div>
  );
}

ServerDetails.propTypes = {};

export default ServerDetails;
/*
RCONTROL
*/
