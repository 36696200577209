import React from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import axios from "modules/axios";
import socketio from "modules/sockets";

export default function Ban({ clientid, guid, ip, name, slot }) {
  let fields = [
    {
      name: "name",
      label: "Player name",
      type: "text",
      required: true,
    },
    {
      name: "reason",
      label: "Reason",
      type: "text",
      required: true,
    },
  ];
  if (ip)
    fields.unshift({
      name: "ip",
      label: "IP Address",
      type: "text",
      required: true,
      validation: /^([0-9]{1,3}\.?){3}[0-9]{1,3}$/,
    });

  const issueBan = (values) => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {
      socketio.nsp.emit("clientCmd", `.ban ${slot} ${values.reason}`);
    } else {
      axios
        .post(`/api/v2/clients/${clientid}/penalties`, {
          penalty: {
            client_id: clientid,
            banned_name: values.name,
            banned_ip: values.ip,
            type: "Ban",
            server_id: "njh",
            reason: values.reason,
          },
        })
        .then((res) => {
          console.log("Success!", res);
        })
        .catch((res) => {
          console.log("Error:", res);
        });
    }
    console.log("Issue Ban:", { clientid, guid, ...values });
  };

  return (
    <CommandButton
      label="Ban"
      description="Issue a ban on this client. This will also issue an IP ban at the same time."
      fields={ip ? [...fields] : fields}
      defaultValues={{
        guid,
        name,
        ip,
      }}
      confirm
      permission="cmd_ban"
      onConfirm={issueBan}
      button={
        <Button variant="danger" size="sm">
          Ban <i className="fa fa-ban" />
        </Button>
      }
    />
  );
}

Ban.propTypes = {
  clientid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guid: PropTypes.string,
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number,
};
