import axios from "axios";
import React from "react";

const instance = axios.create({
  baseURL: "/",
  withCredentials: true,
});

export const useAxios = (
  fetchUrl,
  { onFetched = (res) => {}, onError = (err) => {}, onCancel = (err) => {} }
) => {
  const [url, setUrl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  if (fetchUrl !== url) {
    console.log('Updating fetchUrl');
    setUrl(fetchUrl);
  }
  React.useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();
    let isMounted = true;
    if (!url) return;
    instance
      .get(url, { cancelToken: source.token })
      .then((res) => {
        if (isMounted) onFetched(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false)
        if (axios.isCancel(err)) {
          onCancel(err);
        } else if (!isMounted) return;
        else onError(err);
      });
    return () => {
      isMounted = false;
      source.cancel();
    };
    
  }, [url]); // @ts-ignore
  return loading;
};

export default instance;
