// Reducer for state.admin.

import { ACTIONS } from '../actions';
// import { TEAMS } from '../const';

import mentionSoundFile from '../assets/mention.mp3'
import alertSoundFile from '../assets/Mil Notification.wav'
import chatSoundFile from '../assets/message.mp3'

const mentionSound = new Audio(mentionSoundFile);
const alertSound = new Audio(alertSoundFile);
const chatSound = new Audio(chatSoundFile)

// state.admins is an array of admins.
const admin = (state = {alerts: [], chat: [], info: {}}, action) => {
  var admin = {...state};

  switch (action.type) {

    case ACTIONS.ADMIN.ALERTS: {
        let alerts = [];
        for (let i in action.json) {
            if (action.json[i].alert_level !== 'disabled') {
                alerts.push({
                    rxp: new RegExp(action.json[i].regex, 'i'),
                    level: action.json[i].alert_level,
                })
            }
        }
        admin.alerts = alerts;
        return admin;
    }

    case ACTIONS.ADMIN.CHAT: {
        console.log('Test', action.json);
        action.json.message = action.json.message.replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi, '<a href="$1" target="new">$1</a>');
        admin.chat.push(action.json);
        chatSound.play();
        return admin;
    }

    case ACTIONS.ADMIN.INIT_CHAT: {
        // console.log('ADMIN_INIT_CHAT', action.json);
        if (action.json) {
            admin.chat = action.json;
        }
        for (let i in admin.chat) {
            admin.chat[i].message = admin.chat[i].message.replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/gi, '<a href="$1" target="new">$1</a>');
        }
        return admin;
    }

    case ACTIONS.WENISS.SCOREBOARDHEIGHT: {
        // console.log('action:scoreboardheight', action.json);
        if (action.json) {
            admin.sbHeight = action.json;
            return admin;
        } else {
            return state;
        }
    }

    case ACTIONS.ADMIN.LOGIN: {
        admin.info = action.user;
        return admin;
    }

    case ACTIONS.SERVER.LOG: {
        for (let i in state.alerts) {
            if (state.alerts[i].rxp.test(action.json.message)) {
                switch(state.alerts[i].level) {
                    case 'alert':
                        alertSound.play();
                        break;
                    case 'notice':
                    default:
                        mentionSound.play();
                        break;
                }
            }
        }
        return state;
    }

    default:
      return state;
  }
}

export default admin;
