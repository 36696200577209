import CommandButton from "components/commands/CommandButton";
import { APIData, defaultAPIData } from "components/echelon/APIDataTable";
import DataTable from "components/echelon/DataTable";
import DatePagination from "components/echelon/DatePagination";
import { headers } from "components/echelon/Penalties";
import { useAxios } from "modules/axios";
import useDataControls, {
  DataControls,
  FilterKeyValue,
  Sort
} from "modules/useDataControls";
import useDataFilter from "modules/useDataFilter";
import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import "scss/Penalties.scss";
// import SortableHeadings from "SortableHeadings";

interface PenaltyProps {
  id: number;
  type: string;
  inactive: boolean;
  time_add: number;
  time_expire: number;
  banned_name: string;
  client_id: number;
  data: string;
  banned_ip: string;
  reason: string;
  admin_id: number;
  admin_nickname: string;
  server_id: string;
}

const Penalty: FC<PenaltyProps> = ({
  server_id,
  id,
  type,
  inactive,
  time_add,
  time_expire,
  banned_name,
  client_id,
  data,
  banned_ip,
  reason,
  admin_id,
  admin_nickname,
}) => {
  let penaltyStatus =
    time_expire > 0 && time_expire < Date.now() / 1000 ? "Expired" : "Active";

  if (type !== "Ban" && type !== "IP Ban" && type !== "Temp Ban")
    penaltyStatus = "Expired";
  if (inactive) {
    penaltyStatus = "Disabled";
  }

  return (
    <tr className="Penalty">
      <td>{server_id}</td>
      <td>{id}</td>
      <td>{type}</td>
      <td>
      <InputGroup
          size="sm"
          className={
            "flex-nowrap " +
            (penaltyStatus === "Active" ? "active" : "inactive")
          }
        >
          <InputGroup.Text>{penaltyStatus}</InputGroup.Text>
          <CommandButton
            button={
              <Button
                size="sm"
                variant={penaltyStatus === "Active" ? "danger" : "secondary"}
                disabled={penaltyStatus !== "Active"}
              >
                <i
                  className={`fa fa-${inactive ? "window-close" : "window-close-o"}`}
                />
              </Button>
            }
            permission={"cmd_ban"}
            confirm={`Are you sure you want to deactivate this ban?`}
            onConfirm={() => {}}
          />
        </InputGroup>
      </td>
      <td>
        <Moment unix format="MM/DD/YYYY">
          {time_add}
        </Moment>
      </td>
      <td>
        {time_expire <= 0 ? (
          "Never"
        ) : (
          <Moment unix format="MM/DD/YYYY">
            {time_expire}
          </Moment>
        )}
      </td>
      <td>
        <Link to={`/echelon/clientDetails/${client_id}`}>{banned_name}</Link>
      </td>
      <td>{banned_ip}</td>
      <td>{data}</td>
      <td>{reason}</td>
      <td>
        {admin_nickname === "B3" ? (
          admin_nickname
        ) : (
          <Link to={`/echelon/clientDetails/${admin_id}`}>
            {admin_nickname}
          </Link>
        )}
      </td>
    </tr>
  );
};

interface PenaltiesProps {
  defaultNumPerPage?: number;
  defaultSortKey?: string;
  defaultSortOrder?: Sort;
}

const Penalties: FC<PenaltiesProps> = ({
  defaultNumPerPage = 30,
  defaultSortKey = "time_add",
  defaultSortOrder = "DESC",
}) => {
  const getDatesFromControl = (_control: DataControls) => {
    let control = { ..._control };
    let dateFilters: {
      controls: DataControls;
      startDate?: FilterKeyValue<string>;
      endDate?: FilterKeyValue<string>;
    } = { controls: control };
    if (control.filters && control.filters.length > 0) {
      for (let f = 0; f < control.filters.length; f++) {
        if (control.filters[f].key === "startDate") {
          dateFilters.startDate = control.filters[f];
        }
        if (control.filters[f].key === "endDate") {
          dateFilters.endDate = control.filters[f];
        }
      }
    }
    return dateFilters;
  };

  const endpointBase = `/api/v2/servers/all/penalties`;
  const [data, setData] = useState<APIData>(defaultAPIData);
  const [loading, setLoading] = useState<boolean>(true);
  const { controls, onControl } = useDataControls({
    dataKey: "chatlog",
    defaults: { numPerPage: defaultNumPerPage },
  });
  const { setFullData, filteredData, paginatedData } = useDataFilter({
    controls,
    headers,
  });
  const [endpoint, setEndpoint] = useState<string>(() => {
    let splitCtrl = getDatesFromControl(controls);
    if (splitCtrl.startDate?.value && splitCtrl.endDate?.value) {
      return `${endpointBase}?sort=time_add:desc&limit=none&filter=time_add<${
        // @ts-ignore
        splitCtrl.startDate.value
        // @ts-ignore
      }&filter=time_add>${splitCtrl.endDate.value}}`;
    }
    return `${endpointBase}?sort=time_add:desc&limit=none&filter=time_add>${
      Math.floor(new Date().getTime() / 1000) - 60 * 60 * 24 * 7
    }`;
  });

  useEffect(() => {
    setFullData(data.data);

    return () => {
      setFullData([]);
    };
  }, [data, setFullData]);

  useAxios(endpoint, {
    onFetched: (res) => {
      console.log(res, endpoint);
      setLoading(false);
      let data = res.data.data;
      if (!data) setData(defaultAPIData);
      else if (Array.isArray(data.data)) {
        for (let i = 0; i < data.data.length; i++) {
          if (
            data.data[i].admin_nickname === null ||
            data.data[i].admin_nickname === "" ||
            data.data[i].admin_nickname === undefined
          ) {
            data.data[i].admin_nickname = "B3";
          }
        }
        setData(data);
      } else setData(defaultAPIData);
    },
    onError: (err) => {
      setData(defaultAPIData);
    },
    onCancel: () => {
      setData(defaultAPIData);
    },
  });

  const addDatesToControl = (
    _control: DataControls,
    startDate: Date,
    endDate: Date
  ) => {
    let control = { ..._control }; // Create a copy of the controls so we don't change them directly.
    control.page = 0;
    // If the array exists and it has no elements, simply add the start and end dates.
    if (control.filters && control.filters.length === 0) {
      control.filters.push({
        key: "startDate",
        value: `${Math.floor(startDate.getTime() / 1000)}`,
        comp: "<",
      });
      control.filters.push({
        key: "endDate",
        value: `${Math.floor(endDate.getTime() / 1000)}`,
        comp: ">",
      });
      // If the filters array has at least one element, we must check if it's start or end date.
    } else if (control.filters && control.filters.length > 0) {
      let startDateExists = false,
        endDateExists = false;
      // Loop through existing filters to see if the STart or End date exist, and update the value accordingly
      for (let f in control.filters) {
        // If this filter is for start date, change the value
        if (control.filters[f].key === "startDate") {
          control.filters[f].value = `${Math.floor(
            startDate.getTime() / 1000
          )}`;
          startDateExists = true;
        }
        // If this filter is for end date, change the value.
        if (control.filters[f].key === "endDate") {
          control.filters[f].value = `${Math.floor(endDate.getTime() / 1000)}`;
          endDateExists = true;
        }
      }
      // If start date didn't already exist in the filters
      if (!startDateExists) {
        control.filters.push({
          key: "startDate",
          value: `${Math.floor(startDate.getTime() / 1000)}`,
          comp: "<",
        });
      }
      // IF end date didn't already exist in the filters
      if (!endDateExists) {
        control.filters.push({
          key: "endDate",
          value: `${Math.floor(endDate.getTime() / 1000)}`,
          comp: ">",
        });
      }
      // OTHERWISE create the array and add the start and end dates.
    } else {
      control.filters = [];
      control.filters.push({
        key: "startDate",
        value: `${Math.floor(startDate.getTime() / 1000)}`,
        comp: "<",
      });
      control.filters.push({
        key: "endDate",
        value: `${Math.floor(endDate.getTime() / 1000)}`,
        comp: ">",
      });
    }
    return control;
  };

  const onDatePaginationChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    setLoading(true);
    setEndpoint(
      `${endpointBase}?sort=time_add:desc&limit=none&filter=time_add<${Math.floor(
        startDate.getTime() / 1000
      )}&filter=time_add>${Math.floor(endDate.getTime() / 1000)}`
    );
    onControl(addDatesToControl(controls, startDate, endDate));
  };

  const splitControl = getDatesFromControl(controls);
  let defStartDate, defEndDate;
  defStartDate = splitControl.startDate?.value
    ? new Date(parseInt(splitControl.startDate.value) * 1000) // The start date value
    : new Date(); // The default value (today)
  defEndDate = splitControl.endDate?.value
    ? new Date(parseInt(splitControl.endDate.value) * 1000) // The end date value
    : new Date(defStartDate.getTime() - 86400000 * 7); // The default value (1 day ago)
  return (
    <div className="Penalties">
      <Row className="section">
        <Col xs={12}>
            <DatePagination
              defaultStartDate={defStartDate}
              defaultEndDate={defEndDate}
              onControl={(e: { startDate: Date; endDate: Date }) => {
                onDatePaginationChange(e);
              }}
            />
            <DataTable
              headers={headers}
              row={Penalty}
              data={paginatedData}
              controls={controls}
              onControl={onControl}
              loading={loading}
              numPages={Math.ceil(filteredData.length / defaultNumPerPage)}
              numPageButtons={3}
            />
        </Col>
      </Row>
    </div>
  );
};

export default Penalties;
