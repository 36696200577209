import { connect } from 'react-redux'
import ServerLog from 'components/weniss/ServerLog'

const displayMessages = [
    'chat',
    'webcmd',
    'webchat',
    'join',
    'quit',
    'rename',
]

const mapStateToProps = state => ({
    messages: state.serverlog.filter((v) => (displayMessages.indexOf(v.type) >= 0)),
    players: state.players.reduce((a, b) => (a = [...a, {id: b.id, slot: b.slot, name: b.name, guid: b.guid}]), []),
	weviewVisible: state.weniss.weviewVisible,
})

export default connect(
    mapStateToProps,
    null,
)(ServerLog)
