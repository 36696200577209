import axios from '../modules/axios';
import socketio from '../modules/sockets'
// types of action
export const ACTIONS = {
    SERVER: {
        CHANGE_MAP:     "SERVER_CHANGE_MAP",
        MATCH_END:      "SERVER_MATCH_END",
        LOG:            "SERVER_LOG",
        ALERT:          "SERVER_LOG_ALERT",
        TEAMSCORES:     "SERVER_TEAMSCORES",
    },
    PLAYER: {
        CONNECT:        "PLAYER_CONNECT",
        DISCONNECT:     "PLAYER_DISCONNECT",
        JOIN_TEAM:      "PLAYER_JOIN_TEAM",
        KILL:           "PLAYER_KILL",
        SAY:            "PLAYER_SAY",
        SAYTEAM:        "PLAYER_SAYTEAM",
        SAYMUTED:       "PLAYER_SAYMUTED",
        RENAME:         "PLAYER_RENAME",
        IPINFO:         "PLAYER_IPINFO",
        LIST:           "PLAYER_LIST",
        ALERTS:         "PLAYER_ALERTS",
        MUTE:           "PLAYER_MUTE",
    },
    ADMIN: {
        LIST:           "ADMIN_LIST",
        CHAT:           "ADMIN_CHAT",
        INIT_CHAT:      "ADMIN_INIT_CHAT",
        CONNECT:        "ADMIN_CONNECT",
        DISCONNECT:     "ADMIN_DISCONNECT",
        ALERTS:         "ADMIN_ALERTS",
        ERROR:          "ADMIN_ERROR",
        AUTHERR:        "ADMIN_AUTHERR",
        LOGIN:          "ADMIN_LOGIN",
    },
    WENISS: {
        SELECTPLAYER:       "WENISS_SELECTPLAYER",
        HOVERPLAYER:        "WENISS_HOVERPLAYER",
        SCOREBOARDHEIGHT:   "WENISS_SCOREBOARDHEIGHT",
        INIT_SERVER:        "WENISS_INIT_SERVER",
        INIT_PLAYERS:       "WENISS_INIT_PLAYERS",
        INIT_SERVERLOG:     "WENISS_INIT_SERVERLOG",
        INIT_SERVERINFO:    "WENISS_INIT_SERVERINFO",
        CLOSE_WEVIEW:       "WENISS_CLOSE_WEVIEW",
        OPEN_WEVIEW:        "WENISS_OPEN_WEVIEW",
        TOGGLE_CHATCOLORS:  "WENISS_TOGGLE_CHATCOLORS",
        FETCH_B3_REQUEST:   "WENISS_FETCH_B3_REQUEST",
        FETCH_B3_SUCCESS:   "WENISS_FETCH_B3_SUCCESS",
        FETCH_B3_ERROR:     "WENISS_FETCH_B3_ERROR",
        REQUEST_B3INFO:     "WENISS_REQUEST_B3INFO",
        RECEIVE_B3INFO:     "WENISS_RECEIVE_B3INFO",
        REFRESH_B3INFO:     "WENISS_REFRESH_B3INFO",
        DISCONNECT:         "WENISS_DISCONNECT",
    },

};

// actions
// Whenver the CoD4 Match ends
export const serverMatchEnd = task => ({
    type: ACTIONS.SERVER.MATCH_END,
    payload: task
});
// Whenever a player connects to the server
export const playerConnect = json => {
    return {
        type: ACTIONS.PLAYER.CONNECT,
        json: json
    }
};
// Whenever a player leaves the server
export const playerDisconnect = json => ({
    type: ACTIONS.PLAYER.DISCONNECT,
    json: json
});
// Whenever a player gets a kill
export const playerKill = json => ({
  type: ACTIONS.PLAYER.KILL,
  json: json
});
// Whenever a player says something in all chat
export const playerSay = json => ({
  type: ACTIONS.PLAYER.SAY,
  json: json
});
// Whenever a player says something in team chat
export const playerSayTeam = json => ({
  type: ACTIONS.PLAYER.SAYTEAM,
  json: json
});
// Whenever a muted player talks (either all or team)
export const playerSayMuted = json => ({
  type: ACTIONS.PLAYER.SAY_MUTED,
  json: json
});

export const playerDamage = json => ({
    type: ACTIONS.PLAYER.DAMAGE,
    json: json
});

export const playerDamageReceive = json => ({
    type: ACTIONS.PLAYER.DAMAGE_RECEIVE,
    json: json
});

export const playerJoinTeam = json => ({
    type: ACTIONS.PLAYER.JOIN_TEAM,
    json: json
});

export const playerRename = json => ({
    type: ACTIONS.PLAYER.RENAME,
    json: json
});

export const playerAlerts = json => ({
    type: ACTIONS.PLAYER.ALERTS,
    json: json
});

export const playerMute = json => ({
    type: ACTIONS.PLAYER.MUTE,
    json: json
});

export const adminConnect = json => ({
    type: ACTIONS.ADMIN.CONNECT,
    json: json,
});

export const adminDisconnect = json => ({
    type: ACTIONS.ADMIN.DISCONNECT,
    json: json,
});

export const adminList = json => ({
    type: ACTIONS.ADMIN.LIST,
    json: json,
});

export const adminChat = json => ({
    type: ACTIONS.ADMIN.CHAT,
    json: json,
});

export const adminInitChat = json => ({
    type: ACTIONS.ADMIN.INIT_CHAT,
    json: json,
});

export const adminAlerts = json => ({
    type: ACTIONS.ADMIN.ALERTS,
    json: json,
});

export const adminError = json => ({
    type: ACTIONS.ADMIN.ERROR,
    json: json,
});

export const adminLogin = (user) => ({
    type: ACTIONS.ADMIN.LOGIN,
    user,
});

export const playerIpInfo = json => ({
    type: ACTIONS.PLAYER.IPINFO,
    json: json,
});

export const playerList = json => ({
    type: ACTIONS.PLAYER.LIST,
    json: json,
});

export const teamScores = json => ({
    type: ACTIONS.SERVER.TEAMSCORES,
    json: json,
});

export const adminAuthErr = json => ({
    type: ACTIONS.ADMIN.AUTHERR,
    json: json,
});

export const wenissSelectPlayer = (json) => {
    return({
    type: ACTIONS.WENISS.SELECTPLAYER,
    json: json,
})};

export const wenissHoverPlayer = (json) => ({
    type: ACTIONS.WENISS.HOVERPLAYER,
    json: json,
});

export const wenissScoreboardHeight = (json) => ({
    type: ACTIONS.WENISS.SCOREBOARDHEIGHT,
    json: json,
});

export const wenissCloseWeview = () => ({
    type: ACTIONS.WENISS.CLOSE_WEVIEW,
});

export const wenissOpenWeview = () => ({
    type: ACTIONS.WENISS.OPEN_WEVIEW,
});

export const toggleChatColors = (enabled) => ({
    type: ACTIONS.WENISS.TOGGLE_CHATCOLORS,
    json: {enableChatColors: enabled},
});

export const serverLogItem = json => ({
    type: ACTIONS.SERVER.LOG,
    json: json
});

export const serverLogAlert = json => ({
    type: ACTIONS.SERVER.ALERT,
    json: json
});

export const serverChangeMap = json => ({
    type: ACTIONS.SERVER.CHANGE_MAP,
    json: json
});

export const wenissInitServer = json => ({
    type: ACTIONS.WENISS.INIT_SERVER,
    json: json
});

export const wenissInitServerlog = json => ({
    type: ACTIONS.WENISS.INIT_SERVERLOG,
    json: json
});

export const wenissInitServerinfo = json => ({
    type: ACTIONS.WENISS.INIT_SERVERINFO,
    json: json
});

export const wenissInitPlayers = json => ({
    type: ACTIONS.WENISS.INIT_PLAYERS,
    json: json
});

export const wenissRequestB3info = (guid) => ({
    type: ACTIONS.WENISS.REQUEST_B3INFO,
    guid
});

export const wenissReceiveB3info = (guid, info) => ({
    type: ACTIONS.WENISS.RECEIVE_B3INFO,
    guid,
    info
});

export const wenissRefreshB3info = (guid) => ({
    type: ACTIONS.WENISS.REFRESH_B3INFO,
    guid
});

export const wenissDisconnect = () => ({
    type: ACTIONS.WENISS.DISCONNECT,
});


export function playerUpdate(player) {
    return (/* dispatch */) => {
        socketio.nsp.emit('player:update', player);
    }
}

export function adminCommand(cmdString) {
    return (/* dispatch */) => {
        socketio.nsp.emit('clientCmd', cmdString);
    }
}

export function wenissFetchB3info(guid) {
    return (dispatch) => {
        // Set to loading...
        dispatch(wenissRequestB3info(guid));
        // return {...b3info, aliases, reports} after fetching.
        return axios.get(`/api/clients/${guid}/weview`) // fetch b3info
            .then(b3infoRes => {
                let b3info = b3infoRes.data
                if (b3info)
                axios.get(`/api/clients/${b3info.id}/aliases`) // fetch aliases
                .then(aliasesRes => {
                    let aliases = aliasesRes.data
                    axios.get(`/api/clients/${b3info.id}/reports`) // fetch reports
                    .then(reportsRes => {
                        let reports = reportsRes.data
                        axios.get(`/api/clients/${b3info.id}/notes`)
                        .then(notesRes => {
                            let notes = notesRes.data
                            console.log('PlayerB3: ', b3info, aliases, reports, notes)
                            dispatch(wenissReceiveB3info(guid, {
                                b3info,
                                aliases,
                                reports,
                                notes}
                            ));
                        })
                    });
                });
            });
    }
}

export function wenissFetchB3infoIfNeeded (guid) {
    return (dispatch, getState) => {
        var state = getState();
        if (!state.weniss.b3infoByGUID || !state.weniss.b3infoByGUID[guid] || (state.weniss.b3infoByGUID[guid].lastUpdated - Date.now() / 1000) > 5)
            return dispatch(wenissFetchB3info(guid));
    }
}
