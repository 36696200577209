import CommandButton from "components/commands/CommandButton";
import APIDataTable from "components/echelon/APIDataTable";
import { DataRow } from "components/echelon/DataController";
import axios from "modules/axios";
import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import "scss/Penalties.scss";
// import SortableHeadings from "SortableHeadings";

interface PenaltyProps {
  id: number;
  type: string;
  inactive: boolean;
  time_add: number;
  time_expire: number;
  banned_name: string;
  client_id: number;
  data: string;
  banned_ip: string;
  reason: string;
  admin_id: number;
  admin_nickname: string;
  server_id: string;
}

const Penalty: FC<PenaltyProps> = ({
  id,
  type,
  inactive,
  time_add,
  time_expire,
  banned_name,
  client_id,
  data,
  banned_ip,
  reason,
  admin_id,
  admin_nickname,
  server_id,
}) => {
  let penaltyStatus =
    time_expire > 0 && time_expire < Date.now() / 1000 ? "Expired" : "Active";

  if (type !== "Ban" && type !== "IP Ban" && type !== "Temp Ban")
    penaltyStatus = "Expired";
  if (inactive) {
    penaltyStatus = "Disabled";
  }

  const disablePenalty = (client_id: number, id: number) => {
    axios.put(`/api/v2/clients/${client_id}/penalties`, {penalty: {
      id
    }})
    .then(res => {
      window.location.reload();
    })
    .catch(err => {
      alert("Error");
    })
  }

  return (
    <tr className="Penalty">
      <td>{server_id}</td>
      <td>{id}</td>
      <td>{type}</td>
      <td>
        <InputGroup
          size="sm"
          className={
            "flex-nowrap " +
            (penaltyStatus === "Active" ? "active" : "inactive")
          }
        >
          <InputGroup.Text>{penaltyStatus}</InputGroup.Text>
          <CommandButton
            button={
              <Button
                size="sm"
                variant={penaltyStatus === "Active" ? "danger" : "secondary"}
                disabled={penaltyStatus !== "Active"}
              >
                <i
                  className={`fa fa-${inactive ? "window-close" : "window-close-o"}`}
                />
              </Button>
            }
            permission={"cmd_ban"}
            confirm={`Are you sure you want to deactivate this ban?`}
            onConfirm={() => disablePenalty(client_id, id)}
          />
        </InputGroup>
      </td>
      <td>
        <Moment unix format="MM/DD/YYYY hh:mma">
          {time_add}
        </Moment>
      </td>
      <td>
        {time_expire <= 0 ? (
          "Never"
        ) : (
          <Moment unix format="MM/DD/YYYY hh:mma">
            {time_expire}
          </Moment>
        )}
      </td>
      <td>{banned_name}</td>
      <td>{banned_ip}</td>
      <td>{data}</td>
      <td>{reason}</td>
      <td>
        {admin_nickname === null || admin_nickname === undefined ? (
          "B3"
        ) : (
          <Link to={`/echelon/clientDetails/${admin_id}`}>
            {admin_nickname}
          </Link>
        )}
      </td>
    </tr>
  );
};

export const headers = [
  {
    key: "server_id",
    label: "Server",
    searchable: true,
  },
  {
    key: "id",
    label: "Penalty ID",
    sortable: true,
    filterable: false,
    searchable: true,
    searchOptions: {
      exact: true,
    },
  },
  {
    key: "type",
    label: "Type",
    sortable: false,
    filterable: true,
    searchable: true,
    searchOptions: {
      exact: true,
    },
    filters: [
      {
        value: "Ban",
        label: "Ban",
      },
      {
        value: "IP Ban",
        label: "IP Ban",
      },
      {
        value: "Temp Ban",
        label: "Temp Ban",
      },
      {
        value: "Kick",
        label: "Kick",
      },
      {
        value: "Warning",
        label: "Warning",
      },
      {
        value: "Notice",
        label: "Notice",
      },
    ],
  },
  {
    key: "status",
    label: "Status",
    sortable: true,
    filterable: true,
    filters: [
      { label: "Active", value: "Active" },
      { label: "Disabled", value: "Disabled" },
      { label: "Expired", value: "Expired" },
    ],
    filterFunc: (row: DataRow, val: string | string[]) => {
      let values = Array.isArray(val) ? val : [val]; // Force values to be an array.
      if (values.length === 0 || (values.length === 1 && values[0] === ""))
        return true;
      if (values.indexOf("Disabled") >= 0) {
        if (row.inactive === 1 || row.inactive === true) return true;
      }
      if (values.indexOf("Active") >= 0) {
        if (
          row.inactive === 0 &&
          // Has an expiration that hasn't passed
          ((row.time_expire > 0 && row.time_expire > Date.now() / 1000) ||
            // Never expires
            row.time_expire <= 0)
        ) {
          if (
            row.type === "Kick" ||
            row.type === "Warning" ||
            row.type === "Notice"
          ) {
            return false;
          }
          return true;
        }
      }
      if (values.indexOf("Expired") >= 0) {
        if (row.time_expire > 0 && row.time_expire < Date.now() / 1000)
          return true;
      }
      return false;
    },
  },
  {
    key: "time_add",
    label: "Added",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: "time_expire",
    label: "Expires",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: "banned_name",
    label: "Alias",
    sortable: true,
    filterable: false,
    searchable: true,
  },
  {
    key: "banned_ip",
    label: "IP Address",
    sortable: false,
    filterable: false,
    searchable: true,
    searchOptions: {
      noWildcardBefore: true,
    },
  },
  {
    key: "data",
    label: "Data",
    sortable: false,
    filterable: false,
    searchable: true,
  },
  {
    key: "reason",
    label: "Reason",
    sortable: false,
    filterable: false,
    searchable: true,
  },
  {
    key: "admin_nickname",
    label: "Admin",
    sortable: true,
    filterable: false,
    searchable: true,
  },
];

interface PenaltiesProps {
  clientid: number;
}

const Penalties: FC<PenaltiesProps> = ({ clientid }) => {
  return (
    <div className="Penalties content">
      <h1>Penalties</h1>
      {clientid && (
        <APIDataTable
          cached
          endpoint={`/api/v2/clients/${clientid}/penalties`}
          defaultNumPerPage={10}
          defaultSortKey="time_edit"
          defaultSortOrder="DESC"
          row={Penalty}
          headers={headers}
          datakey="penalties"
        />
      )}
    </div>
  );
};

export default Penalties;
