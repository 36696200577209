import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import axios from "modules/axios";
import socketio from "modules/sockets";
class ServerCmdInput extends React.Component {
  constructor(props) {
    super(props);

    socketio.init();
    this.socket = socketio.io;
    this.nsp = socketio.nsp;
    this.state = { commands: [], index: -1, value: "", name: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    var _this = this;
    axios.get("/api/users/me").then((res) => {
      _this.setState({
        name: res.data.name,
      });
    });
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  // Allow user to cycle through commands with up and down arrow.
  onKeyDown(e) {
    var keycode = e.keyCode ? e.keyCode : e.which;
    var i = this.state.index;
    // Handle up arrow.
    if (keycode === 38) {
      e.preventDefault();
      i++;
      // Keep our index between -1 and commands.length
      if (i > this.state.commands.length) {
        this.setState({
          index: this.state.commands.length - 1,
          value: this.state.commands[this.state.commands.length - 1],
        });
      } else {
        this.setState({
          index: i,
          value: this.state.commands[i],
        });
      }
      // Handle down arrow
    } else if (keycode === 40) {
      e.preventDefault();
      i--;
      // Keep our index betwee -1 and commands.length
      if (i < 0) {
        this.setState({
          index: -1,
          value: "",
        });
      } else {
        this.setState({
          index: i,
          value: this.state.commands[i],
        });
      }
    }
  }

  // Update our state every time the value of the input changes.
  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  // Send the command on submission, as well as update our state.
  handleSubmit(e) {
    e.preventDefault();
    if (this.state.value.length < 1) {
      return;
    }
    if (this.state.value[0] === ".") {
      socketio.nsp.emit("clientCmd", this.state.value);
    } else {
      this.socket.emit("admin:chat", this.state.value);
    }
    this.setState({
      commands: [this.state.value, ...this.state.commands],
      value: "",
      index: -1,
    });
  }

  // Render
  render() {
    return (
          <Form
            onSubmit={this.handleSubmit}
            variant="dark"
            className="ServerCmdInput w-spacing-vert w-spacing-right"
          >
            <InputGroup className="mb-3">
              <Form.Label style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                {this.state.name}:
              </Form.Label>
              <Form.Control
               id="cmdInput"
                ref={this.inputRef}
                type="text"
                className="pulse"
                value={this.state.value}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
              />
            </InputGroup>
          </Form>
    );
  }
}

export default ServerCmdInput;
