import { connect } from 'react-redux'
import PlayerList from 'components/weniss/PlayerList'
import { playerConnect, wenissSelectPlayer } from 'actions'

const mapStateToProps = state => ({
    players: state.players,
    selectedPlayer: state.weniss.selectedPlayer,
    serverinfo: state.serverinfo,
    teamScores: state.serverinfo.teamScores,
})


const mapDispatchToProps = dispatch => ({
    playerConnect: name => dispatch(playerConnect(name)),
    wenissSelectPlayer: id => dispatch(wenissSelectPlayer(id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerList)
