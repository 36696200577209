import React, { useEffect } from "react";
import { useAxios } from "modules/useAxios";

export type ChatlogProviderProps = {
  clientid?: number;
  allClients?: boolean;
  children: React.ReactNode;
};

export type ChatlogItem = {
  id: number;
  msg_time: number;
  msg_type: string;
  client_id: number;
  client_name: string;
  client_team: number;
  msg: string;
  server_id: string;
  target_id?: number;
  target_name?: string;
  target_team?: number;
};

export type ChatlogArgs = {
  sortBy?: string;
  sortOrder?: "asc" | "desc";
  limit?: number;
  before?: boolean;
  lastid?: number;
  afterDate?: Date;
  searchMsg?: string;
};

interface ChatlogContextType {
  log: ChatlogItem[];
  loading: boolean;
  count: number;
  loadChatlog: (args: {
    sortBy?: string;
    sortOrder?: "asc" | "desc";
    limit?: number;
    before?: boolean;
    lastid?: number;
    afterDate?: Date;
    searchMsg?: string;
  }) => Promise<void>;
  isItemLoaded: (index: number) => boolean;
}

const ChatlogContext = React.createContext<ChatlogContextType>(null!);

const empty: ChatlogItem = {
  id: 0,
  msg_time: 0,
  msg_type: "",
  client_id: 0,
  client_name: "",
  client_team: 0,
  msg: "",
  server_id: "",
};

const ChatlogProvider = (props: ChatlogProviderProps) => {
  const [log, setLog] = React.useState<ChatlogItem[]>([]);
  const [count, setCount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [afterDate, setAfterDate] = React.useState<number>(0);
  const [client, setClient] = React.useState<number>(props.clientid!);
  const [args, setArgs] = React.useState<ChatlogArgs>({});
  const url = useAxios();

  const countChatlog = async () => {
    let ep = `/api/v2`;
    if (props.allClients) ep += `/servers/all/chatlog?limit=1`;
    else ep += `/clients/${props.clientid}/chatlog?limit=1`;

    const res = await url
      .get(ep)
      .then((res) => res?.data)
      .catch((err) => console.log("error: ", err.config));

    if (res) {
      console.log("Retrieved chatlog count: ", res);
      setCount(res.data.count);
    }
  };

  useEffect(() => {
    if (props.clientid) {
      console.log("clientid changed", props.clientid);
      countChatlog();
      loadChatlog({}); 
      setClient(props.clientid);

    }
    return () => {
      setCount(0);
      setLog([]);
    }
  }, [props.clientid]);

  useEffect(() => {
    if (props.allClients) {
      countChatlog();
      loadChatlog({});
    }
  }, []);

  

  const loadChatlog = async (args: ChatlogArgs) => {
    console.log("Loading...");
    // Do not load if we already are.
    if (!props.clientid && !props.allClients) return;
    setLoading(true);

    let ep = `/api/v2`;
    if (props.allClients)
      ep += `/servers/all/chatlog?sort=id:${
        args.before ? "asc" : "desc"
      }&limit=${args.limit || 1000}&nocount=true`;
    else
      ep += `/clients/${props.clientid}/chatlog?sort=id:${
        args.before ? "asc" : "desc"
      }&limit=${args.limit || 1000}&nocount=true`;

    let newLog = [...log];

    if (props.clientid !== client) newLog = [];

    if (args.lastid) {
      ep += `&filter=id${args.before ? ">" : "<"}${args.lastid}`;
    } else if (args.afterDate) {
      let tm = args.afterDate.getTime() + args.afterDate.getTimezoneOffset();
      tm = tm / 1000;
      tm += 86400;
      if (!isNaN(tm)) ep += `&filter=msg_time${args.before ? ">" : "<"}${tm}`;
      if (tm !== afterDate) newLog = [];
      setAfterDate(tm);
    }

    if (args.searchMsg && args.searchMsg !== "") {
      ep += `&search=msg:${args.searchMsg}`;
    }
    if (args.searchMsg !== search) newLog = [];
    setSearch(args.searchMsg || "");

    console.log("loading endpoint: ", ep);
    const axiosData = await url
      .get(ep)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        console.log("error: ", err.config);
      })
      .finally(() => {});

    console.log("Returned data: ", axiosData);
    if (!axiosData) {
      setLoading(false);
      console.log("No items loaded.");
      return;
    }

    if (args.before) {
      setLog([
        ...axiosData.data.data.sort((a: any, b: any) => a.id < b.id),
        ...newLog,
      ]);
    } else {
      // setCount(axiosData.data.count);
      setLog([...newLog, ...axiosData.data.data]);
    }

    setLoading(false);
  };

  const isItemLoaded = (index: number) => {
    return log.length >= index;
  };

  const value = {
    log,
    count,
    loading,
    loadChatlog,
    isItemLoaded,
  };

  return (
    <ChatlogContext.Provider value={value}>
      {props.children}
    </ChatlogContext.Provider>
  );
};

export default ChatlogProvider;

export const useChatlog = () => {
  return React.useContext(ChatlogContext);
};
