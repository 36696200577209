import UserAlerts from "components/setting/UserAlerts";
import UserRoles from "components/setting/UserRoles";
import GlobalAlerts from "components/setting/GlobalAlerts";
import Roles from "components/setting/Roles";
import Permissions from "components/setting/Permissions";
import RolePermissions from "components/setting/RolePermissions";
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { User, havePerm } from "services/Authentication";
import { connect } from "react-redux";

type Props = {
  admin: User;
};

const Settings = ({admin}: Props) => {
  const [page, setPage] = useState("alerts");

  const selectPage = (thePage: string | null) => {
    if (thePage === null) setPage("alerts");
    else setPage(thePage);
  };

  return (
    <div className="Settings">
      <Nav
        variant="tabs"
        defaultActiveKey="alerts"
        onSelect={selectPage}
        className="settings-nav"
      >
        <Nav.Item>
          <Nav.Link eventKey="alerts">My Alerts</Nav.Link>
        </Nav.Item>
        {havePerm("edit_global_alerts") && (
          <Nav.Item>
            <Nav.Link eventKey="global-alerts">Global Alerts</Nav.Link>
          </Nav.Item>
        )}
        {havePerm("edit_weniss_users") && (
          <Nav.Item>
            <Nav.Link eventKey="user-roles">User Roles</Nav.Link>
          </Nav.Item>
        )}
        {havePerm("edit_weniss_roles") && (
          <Nav.Item>
            <Nav.Link eventKey="roles">Roles</Nav.Link>
          </Nav.Item>
        )}
        {havePerm("edit_weniss_permissions") && (
          <Nav.Item>
            <Nav.Link eventKey="perms">Permissions</Nav.Link>
          </Nav.Item>
        )}
        {havePerm("edit_weniss_role_permissions") && (
          <Nav.Item>
            <Nav.Link eventKey="role-perms">Role Permissions</Nav.Link>
          </Nav.Item>
        )}
 
      </Nav>
      <div className="page">
        {page === "alerts" && <UserAlerts admin={admin} />}
        {page === "global-alerts" && <GlobalAlerts />}
        {page === "user-roles" && <UserRoles />}
        {page === "roles" && <Roles />}
        {page === "perms" && <Permissions />}
        {page === "role-perms" && <RolePermissions />}
      </div>
    </div>
  );
};

export default 
connect(
  (state: any) => ({
    admin: state.admin.info,
  })
  , null
)(Settings);
