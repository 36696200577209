import { connect } from 'react-redux'
import ServerInfo from 'components/weniss/ServerInfo'

const mapStateToProps = state => ({
    players: state.players,
    admins: state.admins,
    serverinfo: state.serverinfo
});

export default connect(
    mapStateToProps,
    null,
)(ServerInfo)
