import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import 'scss/SortableHeadings.scss';
import FontAwesome from 'components/util/FontAwesome';
const SORT = {
    ASC: <FontAwesome icon="sort-amount-asc" />,
    DESC: <FontAwesome icon="sort-amount-desc" />,
    NONE: <FontAwesome icon="sort" />,
}

function SortableHeadings(props) {
    let headings = [];

    for (let i in props.headings) {
        let h = props.headings[i];
        if (h.nosort) {
            headings[headings.length] = (
                <Col
                    key={h.key}
                    xs={h.size || ""}
                >
                {h.label}
                </Col>
            )
        } else {
            headings[headings.length] = (
                <Col
                key={h.key}
                onClick={() => props.sortFunc(h.key)}
                xs={h.size || ""}
                >
                {h.label}
                {props.sortKey === h.key ? (
                    SORT[props.sortOrder]
                ) : SORT['NONE']}
                </Col>
            )
        }
    }
    return (
            <Row className={props.className + " SortableHeadings"} ref={props.sortRef}>
                {headings}
            </Row>
    )
}

SortableHeadings.propTypes = {
    headings: PropTypes.array,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.string,
    className: PropTypes.string,
    sortRef: PropTypes.object,
    sortFunc: PropTypes.func
}
export default SortableHeadings;
