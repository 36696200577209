import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Outlet } from "react-router-dom";
import Navigation from "components/echelon/Navigation";
import "scss/Echelon.scss";

// function Test(props)
// {
//     return (<span>
//         { props.data.map( (e, i) => (
//             <React.Fragment>
//             <span> ID: {e.id} </span>
//             <span> Name: {e.name} </span>
//             <br />
//             </React.Fragment>
//         ))}
//     </span>);
// }

class Echelon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: null,
      logged_in: false,
    };
    this.adminLogin = this.adminLogin.bind(this);
  }

  adminLogin(data) {
    this.setState({
      admin: data,
      logged_in: data.id > 0,
    });
  }
  render() {
    return (
      <div className="Echelon">
        <Container fluid className="nopadding">
          <Row className="FullHeight nogutters">
            <Col xs={12} md={2} lg={1} className="FlexFullHeight">
              <Navigation />
            </Col>
            <Col xs={12} md={10} lg={11} className="FlexFullHeight MainContent">
              <Outlet />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Echelon;
