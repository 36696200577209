import React, { useEffect, useState } from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PropTypes from "prop-types";
import axios from "axios";
import socketio from "modules/sockets";

const TimeSelector = ({ name, value, error, onChange }) => {
  const [duration, setDuration] = useState({ days: 0, hours: 0, minutes: 0 });

  useEffect(() => {
    if (value) {
      let t = value;
      let d = Math.floor(t / (24 * 60));
      t %= (24 * 60);

      let h = Math.floor((t) / (60));
      t %= 60
      let m = t;
      setDuration({
        days: d,
        hours: h,
        minutes: m,
      });
    } else {
      onChange({
        target: {
          name,
          value: duration.days * 24 * 60 + duration.hours * 60 + duration.minutes
        }
      })
    }
  }, []);

  const handleChange = (e) => {
    let d = { ...duration };

    d[e.target.name] = e.target.value < 0 ? 0 : e.target.value;
    setDuration(d);
    onChange({target: {
      name,
      value: d.days * 24 * 60 + d.hours * 60  + d.minutes}});
  };

  return (
    <Form.Group>
      <Form.Label>Duration</Form.Label>
      <InputGroup isInvalid={error}>
        <Form.Control
          name="days"
          type="number"
          value={duration.days}
          onChange={handleChange}
        />
        <InputGroup.Text>Days</InputGroup.Text>
        <Form.Control
          name="hours"
          type="number"
          value={duration.hours}
          onChange={handleChange}
        />
        <InputGroup.Text>Hours</InputGroup.Text>
        <Form.Control
          name="minutes"
          type="number"
          value={duration.minutes}
          onChange={handleChange}
        />
        <InputGroup.Text>Minutes</InputGroup.Text>
      </InputGroup>
      <Form.Control.Feedback>{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

TimeSelector.propTypes = {
  value: PropTypes.number,
  error: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default function TempBan({ clientid, guid, ip, name, slot }) {
  let fields = [
    {
      name: "name",
      label: "Player name",
      type: "text",
      required: true,
    },
    {
      name: "reason",
      label: "Reason",
      type: "text",
      required: true,
    },
    {
      name: "duration",
      label: "Duration",
      type: <TimeSelector name="duration" />,
      required: true,
    },
  ];
  if (ip)
    fields.unshift({
      name: "ip",
      label: "IP Address",
      type: "text",
      required: true,
      validation: /^([0-9]{1,3}\.?){3}[0-9]{1,3}$/,
    });

  const issueBan = (values) => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {
      socketio.nsp.emit("clientCmd", `.kick ${slot} ^1Banned for ^7${values.reason}.\n ^3Appeal at ^2Net-Jam.com`);
    } else {
      axios.post(`/api/v2/clients/${clientid}/penalties`, {penalty: {
        type: 'TempBan',
        reason: values.reason,
        banned_name: values.name,
        duration: values.duration*60,
        time_expire: (Math.floor(new Date().getTime() / 1000) + values.duration*60),
        banned_ip: values.ip,
        server_id: 'njh',
      }})
    }
    console.log("Issue Temp Ban:", { clientid, guid, ...values });
  };

  return (
    <CommandButton
      label="Temp Ban"
      description="Issue a temp ban on this client."
      fields={ip ? [...fields] : fields}
      defaultValues={{
        guid,
        name,
        ip,
        duration: 4320,
      }}
      confirm
      permission="cmd_tempban"
      onConfirm={issueBan}
      button={
        <Button variant="danger" size="sm">
          Temp Ban <i className="fa fa-clock-o" />
        </Button>
      }
    />
  );
}

TempBan.propTypes = {
  clientid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guid: PropTypes.string,
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number
};
