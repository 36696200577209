import { combineReducers } from 'redux'
import serverinfo from './serverinfo'
import players from './players'
import admins from './admins'
import admin from './admin'
import serverlog from './serverlog'
import weniss from './weniss'

export default combineReducers({
  serverinfo,
  players,
  serverlog,
  admins,
  weniss,
  admin,
})
