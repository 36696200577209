import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Moment from "react-moment";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import ChatlogMessage from "../ChatlogMessage";
import ChatlogProvider, { useChatlog } from "./ChatlogProvider";
import DateSelector from "./DateSelector";
import Search from "./Search";
import "scss/Chatlog.scss";
type Props = {
  clientid?: number;
};

type ChatlogItem = {
  id: number;
  msg_time: number;
  msg_type: string;
  client_id: number;
  client_name: string;
  client_team: number;
  msg: string;
  server_id: string;
  target_id?: number;
  target_name?: string;
  target_team?: number;
};

const formatNumber = (n : number) : string => {
  let s = n.toString();
  let r = "";
  for (let i = 1; i <= s.length;  i++) {
    if (i>1 && (i-1)%3 === 0)
      r = "," + r;
    r = s[s.length - i] + r;

  }
  return r;
}

const ChatlogInfinite = (props: Props) => {
  const cl = useChatlog();
  const [highlightMsg, setHighlightMsg] = useState<string>("");
  const [highlights, setHighlights] = useState<(string | number)[]>([]);
  const [searchMsg, setSearchMsg] = useState<string>("");
  const [originalDate, setOriginalDate] = useState<Date>(new Date());
  const [afterDate, setAfterDate] = useState<Date>(originalDate);
  const [height, setHeight] = useState<number>(400);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // if (searchMsg !== "" || afterDate !== originalDate)

      cl.loadChatlog({ searchMsg, afterDate });
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchMsg, afterDate]);

  const toggleHighlight = (id: number | string) => {
    let hl = [...highlights];
    if (hl.includes(id)) {
      hl = hl.filter((v) => v !== id);
    } else {
      hl.push(id);
    }
    setHighlights(hl);
  };

  const Item = ({ index, style }: { index: number; style: any }) => {
    let content = <></>;
    if (index === 0) {
      return (
        <tr
          className="ChatMessage"
          style={{ ...style, background: "#33333399" }}
        >
          {cl.loading ? (
            <p style={{ textAlign: "center" }}>
              <i className="fa fa-spinner fa-spin" /> Loading...
            </p>
          ) : (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                cl.loadChatlog({
                  lastid: cl.log[0].id,
                  before: true,
                  searchMsg,
                  afterDate,
                });
              }}
              style={{ display: "block", width: "100%", textAlign: "center" }}
            >
              Load newer messages
            </a>
          )}
        </tr>
      );
    } else if (index === cl.log.length + 1) {
      return (
        <tr
          className="ChatMessage"
          style={{ ...style, background: "#33333399" }}
        >
          {cl.loading ? (
            <p style={{ textAlign: "center" }}>
              <i className="fa fa-spinner fa-spin" /> Loading...
            </p>
          ) : (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                cl.loadChatlog({
                  lastid: cl.log[cl.log.length - 1].id,
                  searchMsg,
                  afterDate,
                });
              }}
              style={{ display: "block", width: "100%", textAlign: "center" }}
            >
              Load more messages
            </a>
          )}
        </tr>
      );
    } else if (!cl.isItemLoaded(index - 1) && cl.loading) {
      content = <>Loading...</>;
    } else if (!cl.isItemLoaded(index - 1)) {
      content = <>Not found... {index}</>;
    } else {
      const item = cl.log[index - 1];
      const highlighted: boolean =
        highlights.indexOf(item.id) >= 0 ||
        highlights.indexOf(item.client_name.toLowerCase()) >= 0 ||
        (highlightMsg !== "" &&
          item.msg.toLowerCase().indexOf(highlightMsg.toLowerCase()) >= 0);
      content = (
        <ChatlogMessage
          {...item}
          style={style}
          key={"msg-" + item.id}
          onClick={(value: string | number) => {
            toggleHighlight(
              typeof value === "string" ? value.toLowerCase() : value
            );
          }}
          highlighted={highlighted}
          contextLink
        />
      );
    }
    return content;
  };

  return (
    <Row className="Chatlog content">
        
        <Col xs={2}>
        <h1>Chatlog {cl.loading && <i className="fa fa-spin fa-spinner" />}</h1>
            <DateSelector
              onSubmit={(dt) => {
                if (dt) setAfterDate(dt);
                else setAfterDate(originalDate);
              }}
              disabled={cl.loading}
            />
            <Form.Label>Highlight Containing</Form.Label>
            <Form.Control
              type="text"
              value={highlightMsg}
              onChange={(e) => setHighlightMsg(e.target.value)}
            />
            <Search
              onSubmit={(msg) => {
                setSearchMsg(msg);
              }}
              disabled={cl.loading}
            />
            <br />
            <Button onClick={() => setHighlights([])} variant="primary">
              Clear Highlights
            </Button>
            <span style={{ float: "right" }}>
          Showing {formatNumber(cl.log.length)} of {formatNumber(cl.count)} total messages.
        </span>
          </Col>
          <Col xs={10} className="flex-grow-1" style={{minHeight: 600}}>
            {/* <div
                style={{
                  // height: height,
                  borderLeft: "2px solid #999",
                  overflow: "hidden",
                }}
              > */}
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  itemCount={cl.log.length + 2}
                  itemSize={23}
                  height={height}
                  width={width}
                >
                  {Item}
                </FixedSizeList>
              )}
            </AutoSizer>
            {/* </div> */}
        
      </Col>
    </Row>
  );
};

const Chatlog = ({
  clientid,
  allClients,
}: {
  clientid?: number;
  allClients?: boolean;
}) => (
  <ChatlogProvider clientid={clientid} allClients={allClients}>
    <ChatlogInfinite />
  </ChatlogProvider>
);

export default Chatlog;
