import React from 'react'
import PropTypes from 'prop-types'

function pad(val, char, numDigits) {
	var valStr = "" + val;
	while (valStr.length < numDigits) {
		valStr = "" + char + valStr;
	}
	return valStr;
}

const CharPad = ({str, digits, char='0'}) => (
	<span>
		{"" + pad(str, char, digits)}
	</span>
)

CharPad.propTypes = {
	str: PropTypes.string,
	digits: PropTypes.number,
	char: PropTypes.string
}

export default CharPad;