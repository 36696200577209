// Reducer for state.admins.

import { ACTIONS } from '../actions';
// import { TEAMS } from '../const';

// state.admins is an array of admins.
const admins = (state = [], action) => {
  var admins = [...state];

  switch (action.type) {

    case ACTIONS.ADMIN.LIST: {
        // console.log(ACTIONS.ADMIN.LIST, action.json);
      return action.json;
    }

    case ACTIONS.ADMIN.CONNECT: {
        // console.log(ACTIONS.ADMIN.CONNECT, action.json);
        for (let i=0; i<admins.length; i++) {
            if (admins[i].login === action.json.login) {
                admins[i].connected = true;
                return admins;
            }
        }
        action.json.connected = true;
        admins.push(action.json);
        return admins;
    }

    case ACTIONS.ADMIN.DISCONNECT: {
        // console.log(ACTIONS.ADMIN.DISCONNECT, action.json);
      for(let i in admins) {
        if (admins[i].id === action.json.id) {
          admins.splice(i, 1);
          return admins;
        }
      }
      return admins;
    }

    // Add a new player to the list.
    case ACTIONS.ADMIN.AUTHERR: {
        // console.log('Auth error test');
        this.context.router.push('/');
        return;
    }

    default:
      return state;
  }
}

export default admins;
