import APIDataTable from "components/echelon/APIDataTable";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import "scss/Reports.scss";

const headers = [
  {
    key: "server_id",
    label: "Server",
    sortable: false,
    filterable: true,
    searchable: false,
    filters: [
      {
        label: "NJH",
        value: "njh",
      },
      {
        label: "NJG",
        value: "njg",
      },
      {
        label: "NJB",
        value: "njb",
      },
    ],
  },
  {
    key: "client",
    label: "Alias",
    sortable: true,
    filterable: false,
    searchable: true,
  },
  {
    key: "reporting_client_id",
    label: "Reported By",
    sortable: true,
    filterable: false,
    searchable: true,
  },
  {
    key: "reason",
    label: "Reason",
    sortable: false,
    filterable: false,
    searchable: true,
  },
  {
    key: "time_add",
    label: "When",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: "file_name",
    label: "Demo",
    sortable: false,
    filterable: false,
    searchable: false,
  },
];

interface ReportProps {
  server_id: string;
  client: number;
  reporting_client: string;
  reporting_client_id: number;
  reason: string;
  time_add: number;
  file_name: string;
}

const Report: FC<ReportProps> = ({
  server_id,
  client,
  reporting_client,
  reporting_client_id,
  reason,
  time_add,
  file_name,
  ...props
}) => {
  return (
    <tr className="Report" {...props}>
      <td>{server_id}</td>
      <td>{client}</td>
      <td>
        <Link
          to={`/echelon/clientDetails/${reporting_client_id}`}
          style={{ textDecoration: "underline" }}
        >
          {reporting_client}
        </Link>
      </td>
      <td>{reason}</td>
      <td>
        <Moment unix format="MM/DD/YYYY hh:mma">
          {time_add}
        </Moment>
      </td>
      <td>
        {/* <Button size="sm" onClick={() => console.log(`Clicked ${file_name}`)}>
          <i className="fa fa-link" />
        </Button> */}
      </td>
    </tr>
  );
};

interface ReportsProps {
  clientid: number;
}

const Reports: FC<ReportsProps> = ({ clientid }) => {
  return (
    <div className="Reports content">
      <h1>Reports</h1>
      {clientid && (
        <APIDataTable
          cached
          endpoint={`/api/v2/clients/${clientid}/reports`}
          defaultNumPerPage={10}
          defaultSortKey="time_add"
          defaultSortOrder="DESC"
          row={Report}
          headers={headers}
          datakey="reports"
        />
      )}
    </div>
  );
};

export default Reports;
