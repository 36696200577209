import axios, { CancelTokenSource } from "axios";
import React, { useEffect, useState } from "react";

const instance = axios.create({
  baseURL: "/",
  withCredentials: true,
});

interface GetOptions {
  cancel?: boolean;
  onSuccess?: Function;
  onCancel?: Function;
  onError?: Function;
}

const defaultOnSuccess = (err: any) => {};
const defaultOnCancel = (err: any) => {};
const defaultOnError = (err: any) => {};

export const useAxios = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState<CancelTokenSource>( axios.CancelToken.source());

  const get = async (url : string) => {
    if (loading) {
      console.log('Cancelling');
      source.cancel();
    }
    setLoading(true);
    setResponse(null);
    setSource(axios.CancelToken.source());
    let res;
    try {
      res = await axios.get(url, {cancelToken: source.token});
      setResponse(res.data);
      setError(null);
    } catch (err : any) {
      setError(err);
    } finally {
      setLoading(false);
      return res;
    }
  }

  return { get, response, error, loading };

};
