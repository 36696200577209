import PropTypes from "prop-types";
import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import ColoredText from "components/util/ColoredText";
import Section from "components/weniss/Section";
import SelectedPlayer from "components/weniss/weview/SelectedPlayer";
import ServerCmdInput from "components/weniss/ServerCmdInput";
import AdminChatContainer from "containers/AdminChatContainer";
import AdminListContainer from "containers/AdminListContainer";
import PlayerListContainer from "containers/PlayerListContainer";
import ScoreboardContainer from "containers/ScoreboardContainer";
import ServerInfoContainer from "containers/ServerInfoContainer";
import ServerLogContainer from "containers/ServerLogContainer";
// import WeviewContainer from "containers/WeviewContainer";
import "scss/ServerCmdInput.scss";
import "scss/Weniss.scss";

function Weniss({serverinfo}) {
  const weviewContainerRef = useRef(null);

  return (
    <div className="Weniss">
      <Container fluid>
        <Row>
          {/*<!-- Left Column -->*/}
          <Col xs={12} sm={4} md={4} lg={2} className="nopadding g-0">
            <Section>
              <ServerInfoContainer />
            </Section>
            <Section>
              <PlayerListContainer />
            </Section>
            <Section
              title="Admins Online"
              altTitle="Currently touching the [W].E.N.I.S.S."
            >
              <AdminListContainer />
            </Section>
          </Col>
          {/*<!-- End Left Column -->*/}
          <Col xs={12} sm={8} lg={10} className="midColumn">
            {/*<!-- Right Column -->*/}
            <Row>
              <Col xs={12} lg={7} xl={8} className="g-0">
                <Col xs={12} className="nopadding g-0">
                  {/* <WeviewContainer /> */}
                  <div className="clearfix"></div>
                </Col>
                {/*<!-- Chatlog -->*/}
                <Col
                  xs={12}
                  className="nopadding g-0 section-wrap w-section weview-container"
                  
                >
                  <div className="w-section-head w-spacing-vert w-header">
                    <ColoredText className="">
                      {serverinfo.sv_hostname}
                    </ColoredText>{" "}
                    Server Log:
                  </div>
                  <SelectedPlayer wrapper={weviewContainerRef} />
                  <ServerLogContainer weviewContainerRef={weviewContainerRef} />
                </Col>
              </Col>
              <Col xs={12} lg={5} xl={4} className="g-0">
                <div className="w-section">
                  <label
                    id="playerActionLogLabel"
                    className="w-header w-spacing-all text-center w-section-head"
                  >
                    Net-Jam.com(Hardcore) Scoreboard
                  </label>
                  <div className="nopadding g-0 clearfix w-section-body w-spacing-horiz">
                    <ScoreboardContainer />
                  </div>
                </div>
                <div className="w-section">
                  <div className="w-section-head w-spacing-all w-header text-center">
                    [W].E.N.I.S.S. Chat
                  </div>
                  <div className="w-section-body w-spacing-horiz">
                    <AdminChatContainer />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="g-0">
                <ServerCmdInput />
              </Col>
            </Row>

            {/*<!-- Chatlog End -->*/}
            {/*<!-- Right Column END -->*/}
            {/*<!-- Rightest Column -->*/}

            {/*<!-- Rightest Column END -->*/}
            <div className="clearfix"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Weniss.propTypes = {
  admin: PropTypes.object,
  serverLogItem: PropTypes.func,
  serverinfo: PropTypes.object,
  serverChangeMap: PropTypes.func,
  playerKill: PropTypes.func,
  playerConnect: PropTypes.func,
  playerDisconnect: PropTypes.func,
  playerList: PropTypes.func,
  playerJoinTeam: PropTypes.func,
  playerRename: PropTypes.func,
  playerIpInfo: PropTypes.func,
  playerAlerts: PropTypes.func,
  playerMute: PropTypes.func,
  teamScores: PropTypes.func,
  wenissInitPlayers: PropTypes.func,
  wenissInitServerlog: PropTypes.func,
  wenissInitServerinfo: PropTypes.func,
  // Socket Proptypes (no namespace)
  adminList: PropTypes.func,
  adminDisconnect: PropTypes.func,
  adminConnect: PropTypes.func,
  adminAlerts: PropTypes.func,
  adminChat: PropTypes.func,
  adminInitChat: PropTypes.func,
  adminAuthErr: PropTypes.func,
};

export default connect(
  (state) => ({
    admin: state.admin.info,
    serverinfo: state.serverinfo,
  }),
  null
)(Weniss);
