import Loading from "components/echelon/Loading";
import PlayerSearch from "components/PlayerSearch";
import axios from "modules/axios";
import React, { useState } from "react";
import { Button, Col, Form, FormGroup, InputGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";

type UserRegisterProps = {
  admin: any;
};

interface IUser {
  id: string;
  guid: string;
  login: string;
  password?: string;
  nickname: string;
}

function UserRegister({}: UserRegisterProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState<IUser | null>(null);

  const onSelectPlayer = (id: number) => {
    console.log("SELECTED!");
    setLoading(true);
    axios.get(`/api/v2/clients/${id}`).then((clients) => {
      console.log("Clients", clients);
      if (!clients.data.data.data || clients.data.data.data.length === 0) {
        setLoading(false);
        setMessage("Unable to load client...");
        return;
      }
      let client = clients.data.data.data[0];
      axios.get(`/api/v2/users/${client.login}`).then((users) => {
        console.log("users", users);
        if (!users.data.data || users.data.data.length === 0) {
          setLoading(false);
          setMessage("No user account found...");
          return;
        }
        let user = users.data.data[0];
        setUser({
          id: client.id,
          login: client.login,
          guid: client.guid,
          nickname: user.nickname,
        });
        setLoading(false);
      });
    });
  };

  const saveUser = () => {
    if (!user) {
      setMessage("Cannot save. No user found.");
      return;
    }
    console.log('Posting user', user);
    axios.post(`/api/users/`, {
      client_id: user.id,
      username: user.login,
      guid: user.guid,
      nickname: user.nickname,
      password: user.password
    }).then(res => {
      setMessage(res.data.data);
      setUser(null);
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <div className="WenissUserRegister">
      {message.length > 0 && <span>{message}</span>}
      <Form>
        <Row>
          <Col>
            <PlayerSearch onSelect={onSelectPlayer} />
          </Col>
        </Row>

        {loading && <Loading />}
        {user && (
          <>
            <span>Enter user information:</span>
            <FormGroup>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={user.login}
                placeholder="Username"
                onChange={(e) => setUser({ ...user, login: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>Nickname</Form.Label>
              <Form.Control
                type="text"
                value={user.nickname}
                placeholder="Nickname"
                onChange={(e) => setUser({ ...user, nickname: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>Password<br /><span className="text-small">LEAVE BLANK to not change current password.</span></Form.Label>
              <Form.Control
                type="password"
                value={user.password}
                placeholder="Password... Leave blank to not change"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
            </FormGroup>
            <Button variant="success" onClick={saveUser}>Save</Button>
          </>
        )}
      </Form>
    </div>
  );
}

export default connect(
  (state: any) => ({
    admin: state.admin.info,
  }),
  null
)(UserRegister);
