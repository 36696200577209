import Activity from "components/echelon/Activity";
import Loading from "components/echelon/Loading";
import axios from "modules/axios";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";

interface Server {
  game: string;
  id: string;
}

function Servers() {
  const [servers, setServers] = useState<Server[]>([]);

  useEffect(() => {
    axios.get("/api/servers/").then((res) => {
      let _servers = [];
      for (let i in res.data.servers) {
        let s = res.data.servers[i];
        _servers.push({ game: s.game, id: s.server_id });
      }
      setServers(_servers);
    });
  }, []);

  return (
    <div className="ClientDetails">
      <Row>
        {servers.length === 0 ? (
          <Loading />
        ) : (
          servers.map((s: Server) => <Activity serverid={s.id} />)
        )}
      </Row>
    </div>
  );
}

export default Servers;
