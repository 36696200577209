import React from "react";
import { CSSTransition } from "react-transition-group";
import "scss/Loading.scss";
import FontAwesome from "components/util/FontAwesome";

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inProp: false,
    };
  }

  componentDidMount() {
    this.setState({ inProp: true });
  }

  componentWillUnmount() {
    this.setState({ inProp: false });
  }
  render() {
    if (this.props.wrapped) {
      return (
        <div className="loadWrap">
          <CSSTransition in={this.state.inProp} timeout={100} classNames="fade">
            <div key="loading" className="Loading">
              <div className="spinWrap">
                <div className="spinWrapInner">
                  <FontAwesome icon="spinner" spin />
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      );
    } else
      return (
        <CSSTransition in={this.state.inProp} timeout={100} classNames="fade">
          <div key="loading" className="Loading">
            <div className="spinWrap">
              <div className="spinWrapInner">
                <FontAwesome icon="spinner" spin />
              </div>
            </div>
          </div>
        </CSSTransition>
      );
  }
}

export default Loading;
