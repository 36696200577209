import React from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import axios from 'modules/axios';
import socketio from "modules/sockets";

export default function Kick({ clientid, guid, ip, name, slot }) {
  let fields = [
    {
      name: "name",
      label: "Player name",
      type: "text",
      required: true,
    },
    {
      name: "reason",
      label: "Reason",
      type: "text",
      required: true,
    },
  ];

  const issueKick = (values) => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {
      socketio.nsp.emit("clientCmd", `.kick ${slot} ${values.reason}`);
    }
  };

  return (
    <CommandButton
      label="Kick"
      description="Kick this client."
      fields={fields}
      defaultValues={{
        guid,
        name,
        ip,
      }}
      confirm
      permission="cmd_kick"
      onConfirm={issueKick}
      button={
        <Button variant="danger" size="sm">
          Kick <i className="fa fa-times" />
        </Button>
      }
    />
  );
}

Kick.propTypes = {
  clientid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guid: PropTypes.string,
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number
};
