import React from 'react'
import PropTypes from 'prop-types'
//import 'scss/FontAwesome.scss'

// Font Awesome Icons
const FontAwesome = ({icon, title, spin}) => (
    <i className={"fa fa-" + icon + (spin ? ' fa-spin' : '')}
       aria-hidden="true"
       title={title}
        />
)

FontAwesome.propTypes = {
    icon: PropTypes.string.isRequired,
    spin: PropTypes.bool,
    title: PropTypes.string,
}

export default FontAwesome
