import React, { useEffect, useState } from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PropTypes from "prop-types";
import axios from "axios";
import FontAwesome from "components/util/FontAwesome";
import socketio from "modules/sockets";

interface MuteProps {
  clientid: number;
  name?: string;
  muted?: boolean;
  slot?: number;
}

export default function Mute({
  clientid,
  name,
  muted = false,
  slot,
}: MuteProps) {
  const issueMute = () => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {

      socketio?.nsp?.emit("clientCmd", muted ? `.unmute ${slot}` : `.mute ${slot}`);
    } else {
      axios
        .post(`/api/v2/clients/${clientid}/options`, {
          client_options: {
            client_id: clientid,
            muted: !muted,
          },
        })
        .then((res) => {
          window.location.reload();
        });
    }
  };

  return (
    <CommandButton
      label={muted ? "Unmute" : "Mute"}
      description={`${muted ? "Unmute" : "Mute"} this client.`}
      confirm={`Are you sure you want to ${muted ? "unmute" : "mute"} ${
        name ?? "this client?"
      }`}
      permission="cmd_mute"
      onConfirm={issueMute}
      button={
        <Button variant="warning" size="sm">
          {muted ? "Unmute" : "Mute"}{" "}
          <FontAwesome icon={muted ? "microphone" : "microphone-slash"} />
        </Button>
      }
    />
  );
}
