import React, { useState, useEffect } from "react";
import { DataControls } from "./useDataControls";

export interface DataRow {
  [index: string]: any;
}

interface DataFilterProps {
  controls: DataControls;
  headers: any;
  cached?: boolean;
}
const useDataFilter = ({
  controls,
  headers,
  cached = true,
}: DataFilterProps) => {
  const [fullData, setFullData] = useState<DataRow[]>([]);
  const [filteredData, setFilteredData] = useState<DataRow[]>([]);
  const [paginatedData, setPaginatedData] = useState<DataRow[]>([]);

  useEffect(() => {
    /**
     * Apply in this order:
     * Filters
     * Searches
     * Sort
     * Paging
     */
    let newData = [...fullData]; // Duplicate full data.

    console.log("Filters:", controls.filters);

    // Filters
    if (controls.filters && controls.filters.length > 0) {
      let filters = controls.filters;
      // Loop through all headers.
      for (let h in headers) {
        // If this header has a custom filter function...
        newData = newData.filter((row: DataRow) => {
          let inc = false;
          let matched = false;

          // Loop through all filters
          for (let f in filters) {
            // Skip headers that don't match this filter key.
            if (headers[h].key !== filters[f].key) continue;
            matched = true;

            if (headers[h].filterFunc !== undefined) {
              if (headers[h].filterFunc(row, filters[f].value) === true) {
                inc = true;
              }
            } else {
              if (
                filters[f].value !== "" &&
                filters[f].value === row[filters[f].key]
              ) {
                inc = true;
              }
            }
          }
          if (matched) return inc;
          return true;
          // // Filter with a custom header filter function
          // if (headers[h].filterFunc !== undefined) {
          //   return headers[h].filterFunc(row, filters[f].value);
          // } else {
          //   // If the value is NOT blank, return if the filter value matches the row value.
          //   if (filters[f].value !== "")
          //     return filters[f].value === row[filters[f].key];
          // }
        });
      }
    }

    // Searches
    if (controls.searches && controls.searches.length > 0) {
      let searches = controls.searches;
      // Loop through all searches.
      for (let h in headers) {
        // Loop through all headers.
        for (let s in searches) {
          // Skip headers that don't match this filter key.
          if (headers[h].key !== searches[s].key) continue;

          newData = newData.filter((row: DataRow) => {
            if (headers[h].searchFunc !== undefined)
              return headers[h].searchFunc(row, searches[s].value);
            
            if (
              row[searches[s].key] === undefined ||
              row[searches[s].key] === null ||
              searches[s].value === undefined
            )
              return false;

            // If the first character is not a -, then find all matches
            // @ts-ignore
            if (searches[s].value[0] !== "-") {
              return (
                row[searches[s].key]
                  .toString()
                  .toLowerCase()
                  // @ts-ignore
                  .includes(searches[s].value.toString().toLowerCase())
              );
            } else {
              // Otherwise exclude all matches.
              return row[searches[s].key].toString().toLowerCase().includes(
                // @ts-ignore
                searches[s].value.toString().substring(1).toLowerCase()
              );
            }
          });
        }
      }
    }

    // Sort
    if (
      controls.sort &&
      controls.sort.value &&
      controls.sort.value !== "NONE"
    ) {
      let sortKey = controls.sort.key;
      let sortOrder = controls.sort.value;
      newData.sort((a, b) => {
        return sortOrder === "ASC"
          ? a[sortKey] > b[sortKey]
            ? 1
            : a[sortKey] < b[sortKey]
            ? -1
            : 0
          : a[sortKey] < b[sortKey]
          ? 1
          : a[sortKey] > b[sortKey]
          ? -1
          : 0;
      });
    }

    setFilteredData(newData);
    // Apply Page
    if (controls.numPerPage && cached) {
      newData = newData.slice(
        (controls.page ?? 0) * controls.numPerPage,
        ((controls.page ?? 0) + 1) * controls.numPerPage
      );
    }

    setPaginatedData(newData);

    return () => {
      // Clear the filtered data before updating.
      setFilteredData([]);
    };
  }, [fullData, controls]);

  return {
    setFullData,
    fullData,
    filteredData,
    paginatedData,
  };
};

export default useDataFilter;
