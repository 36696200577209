import React, { useState } from "react";
import { connect } from "react-redux";
import CharPad from "components/util/CharPad";
import FontAwesome from "components/util/FontAwesome";
import PropTypes from "prop-types";
import CopyOnly from "components/util/CopyOnly";
import { useNavigate } from "react-router-dom";
import { wenissSelectPlayer, wenissHoverPlayer } from "actions";
import "scss/Player.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Player(props) {
  const plId = props.id;
  const plGUID = props.guid;

  const [clickTimer, setClickTimer] = useState(0);
  const navigate = useNavigate();

  const onClick = () => {
    if (clickTimer > 0) {
      if (
        plGUID !== null &&
        plGUID !== undefined &&
        plGUID !== "" &&
        plGUID !== "SERVER"
      ) {
        navigate(`/echelon/clientDetails/${plGUID}`);
      }
      clearTimeout(clickTimer);
      setClickTimer(0);
    } else {
      setClickTimer(
        setTimeout(() => {
          props.wenissSelectPlayer({ id: plId, guid: plGUID });
          setClickTimer(0);
        }, 200)
      );
    }
  };
  return (
    <OverlayTrigger
      delay={350}
      overlay={<Tooltip>Double-Click for Echelon</Tooltip>}>
      <span
        className="Player"
        // onClick={ () => navigate(`/echelon/clientDetails/${plGUID}`)}
        onClick={onClick}
        onMouseEnter={() => props.wenissHoverPlayer(plId)}
        onMouseLeave={() => props.wenissHoverPlayer("")}
      >
        {props.ipFlag && (
          <span
            className="playerFlag"
            data-toggle="tooltip"
            data-placement="right"
            title={props.ipCountry}
          >
            <img
              src={props.ipFlag.replace("http://", "https://")}
              alt={props.ipCountry}
              width={18}
              height={12}
            />
          </span>
        )}
        {props.showKey === true && (
          <span className="playerKey">
            <FontAwesome
              icon={/0000000/.test(props.guid) ? "unlock-alt" : "lock"}
              title={/0000000/.test(props.guid) ? "Cracked Key" : "Legit Key"}
            />
          </span>
        )}
        {props.showSlot === true && <CopyOnly text="[" />}
        {props.showSlot === true && (
          <span className="playerSlotNum">
            <CharPad str={props.slot} digits={2} />
          </span>
        )}
        {props.showSlot === true && <CopyOnly text="]" />}
        <span className={"playerNameLink " + props.team}>{props.name}</span>
        {props.alert && (
          <span className="playerAlert">
            <FontAwesome icon="eye" title="Check Admin Notes" />
          </span>
        )}
        {props.muted && (
          <span className="playerAlert">
            <FontAwesome icon="microphone-slash" title="Muted" />
          </span>
        )}
      </span>
    </OverlayTrigger>
  );
}

Player.propTypes = {
  static: PropTypes.bool,
  showTitle: PropTypes.bool,
  showFlag: PropTypes.bool,
  showSlot: PropTypes.bool,
  showKey: PropTypes.bool,
  showAlert: PropTypes.bool,
  id: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  guid: PropTypes.string,
  name: PropTypes.string.isRequired,
  connected: PropTypes.bool,
  selected: PropTypes.bool,
  team: PropTypes.string,
  alert: PropTypes.bool,
  muted: PropTypes.bool,
  ipFlag: PropTypes.string,
  ipAddress: PropTypes.string,
  ipCountry: PropTypes.string,
  //
  // player: PropTypes.shape({
  //     id: PropTypes.string.isRequired,
  //     slot: PropTypes.number.isRequired,
  //     guid: PropTypes.string,
  //     name: PropTypes.string.isRequired,
  //     connected: PropTypes.bool,
  //     selected: PropTypes.bool,
  //     score: PropTypes.number,
  //     kills: PropTypes.number,
  //     killsData: PropTypes.array,
  //     deaths: PropTypes.number,
  //     deathsData: PropTypes.array,
  //     ping: PropTypes.number,
  //     team: PropTypes.string,
  //     alert: PropTypes.bool,
  //     ipinfo: PropTypes.shape({
  //         city: PropTypes.string,
  //         country_name: PropTypes.string,
  //         flag: PropTypes.string,
  //         ip_address: PropTypes.string,
  //         ip_id: PropTypes.number,
  //         ip_type: PropTypes.string,
  //         lat: PropTypes.number,
  //         lon: PropTypes.number,
  //         time_edit: PropTypes.number,
  //         zip: PropTypes.number,
  //     }),
  // }),
  wenissSelectPlayer: PropTypes.func.isRequired,
  wenissHoverPlayer: PropTypes.func.isRequired,
};

Player.defaultProps = {
  showTitle: false,
  showAlert: false,
  showKey: false,
  showSlot: true,
  static: false,
  showFlag: false,
  guid: "",
  connected: false,
  selected: false,
  team: "spectator",
  alert: false,
  muted: false,
  ipFlag: "",
  ipAddress: "",
  ipCountry: "",
  id: "",
};

// const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
  wenissSelectPlayer: (id) => dispatch(wenissSelectPlayer(id)),
  wenissHoverPlayer: (id) => dispatch(wenissHoverPlayer(id)),
});

export default connect(null, mapDispatchToProps)(Player);
