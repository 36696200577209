import DataTable from "components/echelon/DataTable";
import Loading from "components/echelon/Loading";
import useDataControls from "modules/useDataControls";
import useDataFilter from "modules/useDataFilter";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import UserAlert from "./UserAlerts.UserAlert";
import axios from "modules/axios";
import { User } from "services/Authentication";

const headers = [
  {
    key: "regex",
    label: "Alert",
    searchable: true,
  },
  {
    key: "alert_level",
    label: "Level",
    sortable: true,
    searchable: true,
  },
  {
    key: "actions",
    label: "Actions",
  },
];

export interface Alert {
  alert_id: number;
  regex: string;
  alert_level: string;
}

type Props = {
  admin: User;
};
const UserAlerts = ({ admin }: Props) => {
  const [loading, setLoading] = useState(true);

  const { controls, onControl } = useDataControls({
    dataKey: "userAlerts",
    defaults: { numPerPage: 10000 },
  });
  const { setFullData, filteredData } = useDataFilter({
    controls,
    headers,
  });

  const loadAlerts = useCallback(() => {
    setLoading(true);
    axios.get(`/api/users/alerts/${admin.guid}`).then((res) => {
      setFullData(res.data.data);
      setLoading(false);
    });
  }, [admin]);

  useEffect(() => {
    loadAlerts();
  }, [loadAlerts]);

  /**
   * Called when the user clicks the Save button on an alert.
   */
  const onSaveAlert = (alert : Alert) => {
    if (alert.alert_id !== undefined) {
      axios
        .put(`/api/users/alerts/${admin.guid}`, {
          alert_id: alert.alert_id,
          regex: alert.regex,
          alert_level: alert.alert_level,
        })
        .then((res) => {
          loadAlerts();
        });
    } else {
      axios
        .post(`/api/users/alerts/${admin.guid}`, {
          regex: alert.regex,
          level: alert.alert_level,
        })
        .then((res) => {
          if (res.data.data === "success") {
            loadAlerts();
          } else {
            window.alert("Failed to save.");
          }
        })
    }
    loadAlerts();
  };

  /**
   * Called when the user clicks the Delete button on an alert.
   */
  const onDeleteAlert = (alert : Alert) => {
    axios
      .delete(`/api/users/alerts/${admin.guid}`, {
        data: {
          alert_id: alert.alert_id,
        },
      })
      .then(() => loadAlerts());
  };

  return (
    <div className="WenissSettingPage UserAlerts">
      {loading && <Loading />}
      <DataTable
        headers={headers}
        row={UserAlert}
        data={[...filteredData, {}]}
        controls={controls}
        onControl={onControl}
        nopagination
        rowProps={{
          onSave: onSaveAlert,
          onDelete: onDeleteAlert,
        }}
      />
    </div>
  );
};

export default UserAlerts;
