import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import logo from "logo.svg";
import "scss/EchelonNavigation.scss";

function Navigation() {
    // const location = useLocation();
    
  return (
    <div fluid className="EchelonNavigation">
      <img src={logo} alt="logo" />
      <Nav defaultActiveKey="/echelon/clients" className="flex-column">
        <NavLink to="/echelon/servers">
          <Nav.Item>Activity</Nav.Item>
        </NavLink>
        <NavLink to="/echelon/">
          <Nav.Item>Clients</Nav.Item>
        </NavLink>
        <NavLink to="/echelon/penalties">
          <Nav.Item>Penalties</Nav.Item>
        </NavLink>
        <NavLink to="/echelon/chatlog">
          <Nav.Item>Chatlog</Nav.Item>
        </NavLink>
      </Nav>
    </div>
  );
}

export default Navigation;
