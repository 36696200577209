// Reducer for state.players.

import { ACTIONS } from '../actions';
import { TEAMS } from '../const';

// state.players is an array of players.
const playerState = {
    id: 'null',
    slot: '',
    guid: '',
    name: '',
    connected: false,
    selected: false,
    muted: false,
    score: 0,
    kills: 0,
    killsData: [],
    deathsData: [],
    ping: 0,
    team: '',
    alert: false,
    ipinfo: {
        city: '',
        country_name: '',
        flag: '',
        ip_address: '',
        ip_id: 0,
        ip_type: '',
        lat: 0,
        lon: 0,
        time_edit: 0,
        zip: 0,
    }
};

const players = (state = [], action) => {
  var plArray = [...state];
  switch (action.type) {

    // Add a new player to the list.
    case ACTIONS.PLAYER.CONNECT: {
      // Check if this player has connected previously.
      for (let i=0; i<plArray.length; i++) {
        if (plArray[i].id === action.json.id) {
          plArray[i].connected = true;
          return plArray;
        }
      }
      let newPlayer = {...playerState, ...action.json}
      plArray.push(newPlayer);
      return plArray;
    }

    // Disconnect a player.
    case ACTIONS.PLAYER.DISCONNECT: {
      // Loop through all players.
      // Mark all players with this ID as disconnected.
      for(let i=0; i<plArray.length; i++) {
        let obj = plArray[i];
        if (obj.id === action.json.id) {
          obj.connected = false;
        }
      }
      return plArray;
    }

    // Triggered whenever a player gets killed.
    // Multiple kills can be sent in a single packet
    case ACTIONS.PLAYER.KILL: {
      // Loop through each kill in this packet.
      for(let  j=0; j<action.json.length; j++) {
        let kill = action.json[j];
        // Loop through players and update the attacker and victim.
        for(let i=0; i<plArray.length; i++) {
          let p = plArray[i];
          if (p.id === kill.attackerid) {
            p.kills++;
            if (!p.killsData) p.killsData = [];
            p.killsData.push(kill);
            p.damageDone += +kill.damage;
            p.score += 10;
          }
          if (p.id === kill.victimid) {
            p.deaths++;
            if (!p.deathsData) p.deathsData = [];
            p.deathsData.push(kill);
            p.damageReceived += kill.damage;
          }
        }
      }
      return plArray;
    }

    // Triggered whenever a player dies...
    // 2/10/20 LuNaR: I don't think this needs to be called, since
    //   ACTIONS.PLAYER.KILL handles deaths.
    case ACTIONS.PLAYER.DEATH: {
        // TODO
      return plArray;
    }

    // Triggered whenever a player deals damage.
    // 2/10/20 LuNaR: This may never be implemented on the client
    //   as I'm not sure the need for it.
    case ACTIONS.PLAYER.DAMAGE: {
      // TODO
      return plArray;
    }

    // Triggered whenever a player receives damage.
    // 2/10/20 LuNaR: This may never be implemented on the client
    //   as I'm not sure the need for it.
    case ACTIONS.PLAYER.DAMAGE_RECEIVE: {
      // TODO
      return plArray;
    }

    // Triggered whenever a player joins a team.
    // This is detected by a map change, a kill, or a death.
    // Received from socket server.
    case ACTIONS.PLAYER.JOIN_TEAM: {
      for (let i=0; i<plArray.length; i++) {
        if (plArray[i].id === action.json.id) {
          plArray[i].team = action.json.team;
          break;
        }
      }
      return plArray;
    }

    // Triggered whenever a player changes their name.
    // Received from the socket server.
    case ACTIONS.PLAYER.RENAME: {
      for (let i=0; i<plArray.length; i++) {
        if (plArray[i].id === action.json.id) {
          let pl = Object.assign({}, plArray[i]);
          plArray[i] = pl;
          plArray[i].name = action.json.name;
          break;
        }
      }
      return plArray.slice();
    }

    // Triggered whenever a player's IP info is updated.
    // Received from the socket server.
    case ACTIONS.PLAYER.IPINFO: {
      for (let i=0; i<plArray.length; i++) {
        if (plArray[i].id === action.json.id) {
          let pl = Object.assign({}, plArray[i]);
          plArray[i] = pl;
          plArray[i].ipinfo = action.json.ipinfo;
          break;
        }
      }
      return plArray;
    }

    case ACTIONS.PLAYER.LIST: {
        return plArray;
    }

    case ACTIONS.PLAYER.ALERTS: {
        for(let i=0; i<plArray.length; i++) {
            if (plArray[i].id === action.json.id) {
                plArray[i] = {...plArray[i], ...action.json};
            }
        }
        return plArray;
    }

    case ACTIONS.PLAYER.MUTE: {
        for(let i=0; i<plArray.length; i++) {
            if (plArray[i].id === action.json.id) {
                plArray[i].mute = action.json;
            }
        }
        return plArray;
    }

    // Triggered whenever the server changes map.
    // Reset all players' stats to 0, and their team to spectator.
    case ACTIONS.SERVER.CHANGE_MAP: {
      for (let i=0; i<plArray.length; i++) {
        let p = plArray[i];
        p.score = 0;
        p.kills = 0;
        p.deaths = 0;
        p.damageReceived = 0;
        p.damageDone = 0;
        p.killsData = [];
        p.deathsData = [];
        p.team = TEAMS.SPECTATOR;
      }
      return plArray;
    }

    // Triggered whenever a player name is clicked.
    // 2/10/2020 LuNaR: Still not sure where this should go.
    //   This may need to go somewhere else.
    case ACTIONS.WENISS.SELECTPLAYER: {

      for(let i=0; i<plArray.length; i++) {
        let obj = Object.assign({}, plArray[i]);
        if (obj.selected) {
          obj.selected = false
      } else if (obj.id === action.id) {
          obj.selected = true
        } else {
          continue;
        }
        plArray[i] = obj;
      }
      return plArray
    }

    // Triggered on initial connect to the socket server.
    // Set the players array to be exactly what is received from the server.
    // This may trigger after an extended disconnect.
    case ACTIONS.WENISS.INIT_PLAYERS: {
        for (let j in action.json) {
            let update = true;
            for (let i in plArray) {
                if (plArray[i].id === action.json[j].id) {
                    plArray[i] = action.json[j];
                    update = false;
                    break;
                }
            }
            if (update) {
                plArray.push(action.json[j]);
            }
        }
      return action.json;
    }

    case ACTIONS.WENISS.DISCONNECT: {
        return [];
    }
    default:
      return state;
  }
}

export default players;
