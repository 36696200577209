import IPBan from "components/commands/IPBan";
import TempBan from "components/commands/TempBan";
import APIDataTable from "components/echelon/APIDataTable";
import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import "scss/IPLogs.scss";
import FontAwesome from "./FontAwesome";

const headers = [
  {
    key: "server_id",
    label: "Server",
    sortable: true,
    filterable: false,
    searchable: true,
  },
  {
    key: "ip",
    label: "IP Address",
    sortable: false,
    filterable: false,
    searchable: true,
  },
  {
    key: "time_edit",
    label: "Last Used",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: 'action',
    label: 'IP Ban',
    sortable: false,
    filterable: false,
    searchable: false,
  }
];

interface IPLogProps {
  id: number;
  server_id: string;
  client_id: number;
  ip: string;
  time_add: number;
  time_edit: number;
}

const IPLog : FC<IPLogProps> = ({id, server_id, ip,client_id,  time_add, time_edit, ...props}) => {
  return (
    <tr className="IPLog" {...props}>
      <td>{server_id}</td>
      <td>{ip}&nbsp;<OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Geo Location</Tooltip>}
                  >
                  <a
                    href={`https://www.geolocation.com/en_us?ip=${ip}#ipresult`}
                    target="_new"
                  >
                    <FontAwesome icon="globe" />
                  </a>
                </OverlayTrigger></td>
      <td>
        <Moment unix format="MM/DD/YYYY hh:mma">
          {time_edit}
        </Moment>
      </td>
      <td>
        <IPBan
          clientid={client_id}
          name=""
          ip={ip}
          />
        &nbsp;
        <TempBan
          clientid={client_id}
          name=""
          ip={ip}
          />
      </td>
    </tr>
  );
}

interface IPLogsProps {
  clientid: number;
}

const IPLogs : FC<IPLogsProps> = ({clientid}) => {
  return (
    <div className="IPLogs content">
      <h1>Recent IPs</h1>
      {clientid && (
        <APIDataTable
          cached
          endpoint={`/api/v2/clients/${clientid}/ipaliases`}
          defaultNumPerPage={10}
          defaultSortKey="time_edit"
          defaultSortOrder="DESC"
          row={IPLog}
          headers={headers}
          datakey="iplogs"
          />
      )}
    </div>
  );
}

export default IPLogs;
