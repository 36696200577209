import React from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import axios from 'modules/axios';
import socketio from "modules/sockets";

export default function Burn({ clientid, guid, ip, name, slot }) {


  const issueBurn = (values) => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {
      socketio.nsp.emit("clientCmd", `.burn ${slot}`);
    }
  };

  return (
    <CommandButton
      label="Burn"
      description="Burn this client."
      confirm
      permission="cmd_burn"
      onConfirm={issueBurn}
      button={
        <Button variant="warning" size="sm">
          Burn <i className="fa fa-fire" />
        </Button>
      }
    />
  );
}

Burn.propTypes = {
  clientid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guid: PropTypes.string,
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number
};
