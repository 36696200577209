import { connect } from 'react-redux'
import AdminChat from 'components/weniss/AdminChat'

const mapStateToProps = state => ({
    messages: state.admin.chat.filter((a) => (a.message !== undefined)),
    admins: state.admins.reduce((a, b) => (a = [...a, {id: b.id, slot: b.slot, name: b.name, guid: b.guid, connected: b.connected}]), []),
})


export default connect(
    mapStateToProps,
    null
)(AdminChat)
