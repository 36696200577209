import axios from "modules/axios";
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from "react";

const minInDay = 60 * 24;
const sMult = 1000;
const mMult = sMult * 60;
const hMult = mMult * 60;

export default function Activity({ clientid, serverid }) {
  const [history, setHistory] = useState([]);
  const [width, setWidth] = useState(0);
  const [/* error */, setError] = useState(undefined);
  const [labels, setLabels] = useState([]);

  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const leftOffset = 30;
  const bottomOffset = 30;
  const rightOffset = 20;
  const topOffset = 20;

  const horizOffset = leftOffset + rightOffset;
  const vertOffset = topOffset + bottomOffset;

  const DrawGrid = (context) => {
    let w = context.canvas.width - horizOffset;
    let h = context.canvas.height - vertOffset;

    context.fillStyle = "#ffffff55";
    for (let i = 0; i <= 24; i++) {
      context.fillRect(
        leftOffset,
        (i * h) / 24 + topOffset,
        w,
        i === 12 ? 2 : 1
      );
    }
    for (let i = 0; i <= 30; i++) {
      context.fillRect((i * w) / 30 + leftOffset, topOffset, 1, h);
    }
    for (let i = 1; i <= 24; i++) {
      context.fillText(
        i,
        leftOffset / 2,
        ((24 - i) * h) / 24 + topOffset + topOffset / 4,
        leftOffset / 2
      );
    }

    let dt = new Date();
    let msInDay = 1000 * 60 * 60 * 24;

    let l = {};
    for (let i = 0; i < 30; i++) {
      let label = `${dt.getMonth() + 1}/${dt.getDate()}`;
      let textWidth = context.measureText(label).width;
      context.fillText(
        label,
        w - (i * w) / 30 + leftOffset - w / 60 - textWidth / 2,
        topOffset * 1.5 + h
      );
      l[label] = leftOffset + w - ((i + 1) * w) / 30;
      dt = new Date(dt.valueOf() - msInDay);
    }
    setLabels(l);
  };

  const DrawTime = (context, dataPoint) => {
    context.fillStyle = "#00ff0033";
    if (serverid)
      context.fillStyle = "#00ff0005";
    // context.fillStyle="#00ff000d"; // Low opacity. good for seeing everyone's activity.
    let w = context.canvas.width - horizOffset;
    let h = context.canvas.height - vertOffset;
    // Draw at the start x for date, y + bottomOffset for time.
    // y / minInDay * h;
    context.fillRect(
      labels[dataPoint.x],
      topOffset + h - h * ((dataPoint.y0 + dataPoint.y) / minInDay),
      w / 30,
      (h * dataPoint.y) / minInDay
    );
  };

  useEffect(() => {
    canvasRef.current.width = containerRef.current.clientWidth;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    // console.log("rendering!", history);

    DrawGrid(context);
    for (let i = 0; i < history.length; i++) {
      if (Object.keys(labels).includes(history[i].x))
        DrawTime(context, history[i]);
    }

    return () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      // console.log("Cleared!");
    };
  }, [history, width]);

  const windowResize = (() => {
    let timeout;
    return () => {
      if (timeout) clearTimeout(timeout);
      setTimeout(() => {
        setWidth(containerRef.current.clientWidth);
      }, 100);
    };
  })();

  useEffect(() => {
    window.addEventListener("resize", windowResize);
  });

  useEffect(() => {
    if (!clientid && !serverid) return;
    let api;

    if (serverid)
      api = `/api/v2/servers/${serverid}/activity?limit=-1`;
    if (clientid)
      api = `/api/v2/clients/${clientid}/activity?limit=-1`;


    axios
      .get(api)
      .then((res) => {
        let dateDiff = new Date().getTimezoneOffset() * 60;
        setHistory(res);
        let data = [];
        for (let i in res.data.data) {
          let came = new Date(parseInt(res.data.data[i].came) * 1000 + dateDiff);
          // let gone = new Date(parseInt(res.data.data[i].gone) * 1000 + dateDiff);

          let day = new Date(
            came.valueOf() -
              came.getHours() * hMult -
              came.getMinutes() * mMult -
              came.getSeconds() * sMult -
              came.getMilliseconds()
          );

          let duration =
            Math.floor(parseInt(res.data.data[i].gone) / 60) -
            Math.floor(came.valueOf() / (60 * 1000));
          // If we left more than minInDay later
          // let a = res.data.data[i].gone;
          // let b = Math.floor(day.valueOf() / 1000);
          if (
            parseInt(res.data.data[i].gone) - Math.floor(day.valueOf() / 1000) >
            minInDay * 60
          ) {
            // tomorrow = start of next day
            let tomorrow = new Date(day.valueOf() + minInDay * 60 * 1000); // Tomorrow!
            // Duration of tomorrow is gone - tomorrow
            let dur =
              Math.floor(parseInt(res.data.data[i].gone) / 60) -
              Math.floor(tomorrow.valueOf() / (1000 * 60));
            duration -= dur;
            data.push({
              x: `${tomorrow.getMonth() + 1}/${tomorrow.getDate()}`,
              y: dur,
              y0: 0,
              name: "tomorrow",
            });
          }

          data.push({
            x: `${day.getMonth() + 1}/${day.getDate()}`,
            y: duration,
            y0: came.getHours() * 60 + came.getMinutes(), // Minutes into the day.
          });
        }
        setHistory(data.sort((a, b) => a.x > b.x));
      })
      .catch((err) => {
        setError(err);
        console.log("Activity error: ", err);
      });
    return () => {
      setHistory([]); // Clear history when clientid changes.
      setError(undefined); // Clear error when clientid changes.
    };
  }, [clientid, serverid]);

  return (
    <div className="content">
      <h1>Activity last 30 days{serverid ? ` on ${serverid}` : ""}.</h1>
      <div ref={containerRef} style={{ height: "400px" }}>
        <canvas
          ref={canvasRef}
          width={1200}
          height={400}
          style={{ display: "block" }}
        />
        <div style={{ clear: "both" }} />
      </div>
    </div>
  );
}
Activity.propTypes = {
  clientid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  serverid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}