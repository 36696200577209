import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useDeepLinking = (key, replace = true) => {
  const location = useLocation();
  const [sParams, setSparams] = useState(location.search);
  const navigate = useNavigate();

  const nav = (params) => {
    navigate(location.pathname + "?" + params.toString().replaceAll("%3A", ":").replaceAll("%2C", ","), { replace: replace });
  };

  const setSearchParam = (paramKey, paramValue) => {
    let params = new URLSearchParams(sParams);
    params.set(key + paramKey, paramValue);
    setSparams(params.toString());
    nav(params);
  };

  const setSearchParams = (objValues) => {
    let params = new URLSearchParams(sParams);
    let keys = Object.keys(objValues);
    for (let k in keys) {
      if (objValues[keys[k]])
        params.set(key + keys[k], objValues[keys[k]]);
      else
        params.delete(key + keys[k]);
    }
    setSparams(params.toString());
    nav(params);
  };

  const removeSearchParamValue = (paramKey, paramValue) => {
    let params = new URLSearchParams(sParams);
    if (params.has(key + paramKey)) {
      let values = params.getAll(key + paramKey);
      if (values.includes(paramValue)) {
        values.splice(values.indexOf(paramValue), 1);
        params.set(key+paramKey, values[0]);
        for (let i=1; i<values.length; i++) {
          params.append(key+paramKey, values[i]);
        }
        setSparams(params.toString());
        nav(params);
      }
    }
  };

  const addSearchParamValue = (paramKey, paramValue) => {
    let params = new URLSearchParams(sParams);
    if (params.has(key + paramKey)) {
      let values = params.getAll(key + paramKey);
      if (values.includes(paramValue)) return;
      else {
        params.append(key + paramKey, paramValue);
      }
    } else {
      params.set(key + paramKey, paramValue);
    }
    setSparams(paramKey, params.toString());
    nav(params);
  };

  const getSearchParam = (paramKey, getAll = false) => {
    let params = new URLSearchParams(sParams);
    return getAll ? params.getAll(key + paramKey) : params.get(key + paramKey);
  };

  // On load, update sParams to fit the current sParams.
  useEffect(() => {
    setSparams(location.search);
  }, [location.search]);

  return [
    getSearchParam,
    setSearchParam,
    addSearchParamValue,
    removeSearchParamValue,
    setSearchParams,
  ];
};

export default useDeepLinking;
