import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import axios from "modules/axios";
import 'scss/ChatlogContext.scss';
import Message from 'components/echelon/ChatlogMessage';
import { headers } from 'components/echelon/ClientChatlog';
import DataTable from "./DataTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


type Props = {
  serverid : string,
  chatid: number
}

const ChatlogContext = ({serverid, chatid}: Props) => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const LoadContext = (e : any) => {
    e.preventDefault();
    e.stopPropagation();
    axios
      .get(`/api/servers/${serverid}/chatlog/context/${chatid}`)
      .then((res) => {
        // console.log(res);
        for (let i=0; i < res.data.length; i++) {
          if (res.data[i].id === chatid)
            res.data[i].highlighted = true;
        }
        setData(res.data);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {show && (
        <Modal show={show} onHide={() => setShow(false)} size="xl" className="ChatlogContext">
          <Modal.Header closeButton>
            <Modal.Title>Chat Context</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataTable
              nopagination
              headers={headers}
              row={Message}
              data={data}
              loading={false}
              numPages={1}
              numPageButtons={0}
              
              />
              
          </Modal.Body>
        </Modal>
      )}
      <OverlayTrigger
        placement="top"
        delay={{show: 100, hide: 400}}
        overlay={() => (<Tooltip id={`chat-context-${chatid}`}>Chat Context...</Tooltip>)}>
      <i className="fa fa-comments-o open-context" onClick={LoadContext} />
      </OverlayTrigger>
      
    </>
  );
}

export default ChatlogContext;