import DataTable, { Header } from "components/echelon/DataTable";
import { useAxios } from "modules/axios";
import useDataControls, { DataControls, KeyValuePair, Sort } from "modules/useDataControls";
import useDataFilter from "modules/useDataFilter";
import { FC, useEffect, useState } from "react";

export interface APIData {
  data: Array<any>;
  count: number;
  countBefore: number;
}

export const defaultAPIData: APIData = {
  data: [],
  count: 0,
  countBefore: 0,
};

interface APIDataTableProps {
  defaultNumPerPage: number;
  defaultSortKey: string;
  defaultSortOrder: Sort;
  row: Function;
  headers: Array<Header>;
  datakey: string;
  endpoint: string;
  numPageButtons?: number;
  refresh?: number;
  cached?: boolean;
}
const APIDataTable: FC<APIDataTableProps> = ({
  endpoint,
  defaultNumPerPage,
  defaultSortKey,
  defaultSortOrder,
  datakey,
  row,
  headers,
  refresh = 0,
  cached = false,
  numPageButtons = 5,
}) => {
  // Full API endpoint.
  const [fullEndpoint, setFullEndpoint] = useState<string>(() => {
    if (cached) return `${endpoint}?limit=none&nocount`;
    else return "";
  });
  let defaultSort: KeyValuePair<Sort> | undefined = undefined;
  if (defaultSortKey && defaultSortOrder)
    defaultSort = {
      key: defaultSortKey,
      value: defaultSortOrder,
    };
  const [apiTimeout, setApiTimeout] = useState<NodeJS.Timeout>();
  const [page, setPage] = useState<number>(1);
  const { controls, onControl } = useDataControls({
    dataKey: datakey,
    defaults: { numPerPage: defaultNumPerPage, sort: defaultSort },
  });
  const { setFullData, fullData, filteredData, paginatedData } = useDataFilter({
    controls,
    headers,
    cached,
  });
  const [data, setData] = useState<APIData>(defaultAPIData);

  const loading = useAxios(fullEndpoint, {
    onFetched: (res) => {
      const data = res.data.data;
      if (!data) setData(defaultAPIData);
      else if (Array.isArray(data.data)) setData(data);
      else setData(defaultAPIData);
    },
    onError: (err) => {
      setData(defaultAPIData);
    },
    onCancel: () => {
      setData(defaultAPIData);
    },
  });

  useEffect(() => {
    setFullData(data.data);
    return () => {
      setFullData([]);
    };
  });

  useEffect(() => {
    if (cached) {
      console.log("Endpoint changed?");
      setFullEndpoint(`${endpoint}?limit=none&nocount&refresh=${refresh}`);
    }
  }, [endpoint, refresh]);


  const localOnControl = (controls: DataControls) => {
    if (cached) {
      setFullEndpoint(`${endpoint}?limit=none&nocount`);
      onControl(controls);
      return;
    }
  };

  return (
    <DataTable
      headers={headers}
      row={row}
      data={paginatedData}
      controls={controls}
      onControl={localOnControl}
      loading={loading}
      numPages={
        cached ? Math.ceil(filteredData.length / defaultNumPerPage) : undefined
      }
      numPageButtons={numPageButtons}
    />
  );
};

export default APIDataTable;
