import React from 'react'
import FontAwesome from './FontAwesome'
import PropTypes from 'prop-types';

const MessageIcon = ({type}) => {
    var theIcon = ""
    switch (type) {
        case 'say':
        case 'sayteam':
        case 'saymuted':
        case 'chat':
            theIcon = "comments-o";
            break;

        case 'join':
            theIcon = "user-plus";
            break;

        case 'quit':
            theIcon = "user-times";
            break;

        case 'cmd':
            theIcon = "magic";
            break;

        case 'rename':
            theIcon = "address-card";
            break;

        case 'webcmd':
            theIcon = "server";
            break;

        default:
            theIcon = "none";
            break;
    }
    return (
        <FontAwesome icon={theIcon} />
    );
};

MessageIcon.propTypes = {
    type: PropTypes.string,
};

export default MessageIcon;
