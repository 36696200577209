import React from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import axios from "modules/axios";
import socketio from "modules/sockets";
import FontAwesome from "components/util/FontAwesome";

export default function EditGreeting({ clientid, guid, ip, name, slot, greeting }) {
  let fields = [
    {
      name: "greeting",
      label: "Greeting",
      type: "textarea",
      required: false,
    },
  ];

  const editGreeting = (values) => {
    axios
      .put(`/api/v2/clients/${clientid}/greeting`, {
        greeting: values.greeting
        }
      )
      .then((res) => {
        console.log("Success!", res);
        window.location.reload();
      })
      .catch((res) => {
        console.log("Error:", res);
      });
  };

  return (
    <CommandButton
      label="New Greeting"
      description="Change this client's greeting"
      fields={ip ? [...fields] : fields}
      defaultValues={{
        greeting
      }}
      confirm
      permission="edit_greeting"
      onConfirm={editGreeting}
      button={
        <a href="#">
          <FontAwesome icon="pencil" />
        </a>
      }
    />
  );
}

EditGreeting.propTypes = {
  clientid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guid: PropTypes.string,
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number,
  greeting: PropTypes.string
};
