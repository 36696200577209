// Reducer for state.serverlog

import { ACTIONS } from '../actions'

const serverlog = (state = [], action) => {
  switch (action.type) {
    // Triggered whenever a message appears in the serverlog.
    // Received from the socket server.
    case ACTIONS.SERVER.LOG: {
        if (state.length < 500) {
            return [...state, action.json]
        }
        let slog = [...state, action.json].slice(2);

        return slog;
    }

    case ACTIONS.SERVER.ALERT: {
        let msgs = state.slice();
        for(let i in msgs) {
            if (msgs[i].id === action.json) {
                msgs[i].alertPlayed = true;
                return msgs;
            }
        }
      return [...state, action.json]
    }
    // Triggered whenever the socket server initially connects.
    // Forces the current serverlog to match the WENISS server.
    case ACTIONS.WENISS.INIT_SERVERLOG: {
        var serverlog = [];
        for(let i in action.json) {
            // let update = true;
            // for(let j in serverlog) {
            //     if (action.json[i].__id === serverlog[j].__id) {
            //         update = false;
            //         break;
            //     }
            // }
            // if (update) {
            //     serverlog.push(action.json[i]);
            // }
            serverlog.push(action.json[i]);
        }
      return serverlog;
    }


    default:
      return state;
  }
}

export default serverlog;
