import { APIData, defaultAPIData } from "components/echelon/APIDataTable";
import { ChatlogMessageWithContext } from 'components/echelon/ChatlogMessage';
import { Sort } from "components/echelon/DataController";
import DataTable from "components/echelon/DataTable";
import DatePagination from "components/echelon/DatePagination";
import { useAxios } from "modules/axios";
import useDataControls, {
  DataControls,
  FilterKeyValue
} from "modules/useDataControls";
import useDataFilter from "modules/useDataFilter";
import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "scss/Chatlog.scss";
import Loading from "./Loading";

/** THIS FILE IS STILL USED FOR THE HEADERS EXPORT AS OF 5/22/2023 */

/** New  idea for chatlog: Date-Based
 * Filterable by client ID??
 * Pagination is ENTIRELY date-based. Maximum timespan on a single page is 1 month (31 days)
 * Defaults to last week of chatlog
 * Searching the message column only searches the currently displayed data.
 * Separate chatlog SEARCH controller. This searches ALL time, and is paginated.
 *
 */

export const headers = [
  {
    key: "msg_time",
    label: "Timestamp",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: "client_name",
    label: "Name",
    sortable: false,
    filterable: false,
    searchable: true,
  },
  {
    key: "msg",
    label: "Message",
    sortable: false,
    filterable: false,
    searchable: true,
  },
];

interface MessageProps {
  msg: string;
  msg_type: string;
  msg_time: number;
  client_name: string;
  client_id: number;
  onClick: Function;
  highlighted: boolean;
  id: number;
  server_id: string;
  key: string;
}

interface ClientChatlogProps {
  defaultNumPerPage?: number;
  defaultSortKey?: string;
  defaultSortOrder?: Sort;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  clientid: number | string;
}

const ClientChatlog: FC<ClientChatlogProps> = ({
  defaultNumPerPage = 100,
  defaultSortKey = "msg_time",
  defaultSortOrder = "DESC",
  defaultStartDate = new Date(),
  defaultEndDate = new Date(new Date().getTime() - 604800000), // 7 days
  clientid,
  ...props
}) => {

  const getDatesFromControl = (_control: DataControls) => {
    let control = { ..._control };
    let dateFilters: {
      controls: DataControls;
      startDate?: FilterKeyValue<string>;
      endDate?: FilterKeyValue<string>;
    } = { controls: control };
    if (control.filters && control.filters.length > 0) {
      for (let f = 0; f < control.filters.length; f++) {
        if (control.filters[f].key === "startDate") {
          dateFilters.startDate = control.filters[f];
        }
        if (control.filters[f].key === "endDate") {
          dateFilters.endDate = control.filters[f];
        }
      }
    }
    return dateFilters;
  };

  const [endpointBase, setEndpointBase] = useState<string>(
    `/api/v2/clients/${clientid}/chatlog`
  );
  const [data, setData] = useState<APIData>(defaultAPIData);
  const [loading, setLoading] = useState<boolean>(true);
  const { controls, onControl, getFiltersByKey, getSearchesByKey } =
    useDataControls({
      dataKey: "chatlog",
      defaults: { numPerPage: defaultNumPerPage },
    });
  const { setFullData, filteredData, paginatedData } = useDataFilter({
    controls,
    headers,
  });

  const [endpoint, setEndpoint] = useState<string>(() => {
    if (!clientid) return ''
    let splitCtrl = getDatesFromControl(controls);
    if (splitCtrl.startDate?.value && splitCtrl.endDate?.value) {
      return `${endpointBase}?sort=msg_time:desc&limit=none&filter=msg_time<${
        // @ts-ignore
        splitCtrl.startDate.value
        // @ts-ignore
      }&filter=msg_time>${splitCtrl.endDate.value}}`;
    }
    return `${endpointBase}?sort=msg_time:desc&limit=none&filter=msg_time>${
      Math.floor(new Date().getTime() / 1000) - 60 * 60 * 24
    }`;
  });
  console.log("endpoint", endpoint, clientid);
  useEffect(() => {
    setFullData(data.data);

    return () => {
      setFullData([]);
    };
  }, [data]);

  useEffect(() => {
    if (clientid === undefined) {
      setFullData([]);
      return;
    }

    let base = `/api/v2/clients/${clientid}/chatlog`;
    if (base !== endpointBase) {
      setFullData([]);

      setEndpointBase(base);
      let startDate = Math.floor(defaultStartDate.getTime() / 1000),
        endDate = Math.floor(defaultEndDate.getTime() / 1000);
      
      // Set the start date from the filters..
      let filters = getFiltersByKey("startDate");
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].comp === "<" &&
          filters[i].value &&
          // @ts-ignore The filters[i].value error is handled by the filters[i].value check just before.
          !isNaN(parseInt(filters[i].value))
        )
          // @ts-ignore The filters[i].value error is handled by the filters[i].value check in the preceding if
          startDate = parseInt(filters[i].value);
      }
      // Set the end date from the filters..
      filters = getFiltersByKey("endDate");
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].comp === ">" &&
          filters[i].value &&
          // @ts-ignore The filters[i].value error is handled by the filters[i].value check just before.
          !isNaN(parseInt(filters[i].value))
        )
          // @ts-ignore The filters[i].value error is handled by the filters[i].value check in the preceding if
          endDate = parseInt(filters[i].value);
      }

      setEndpoint(
        `${base}?sort=msg_time:desc&limit=none&filter=msg_time<${startDate}&filter=msg_time>${endDate}`
      );
    }
  }, [clientid]);

  const axios = useAxios(endpoint, {
    onFetched: (res) => {
      console.log(res, endpoint);
      setLoading(false);
      const data = res.data.data;
      if (!data) setData(defaultAPIData);
      else if (Array.isArray(data.data)) setData(data);
      else setData(defaultAPIData);
    },
    onError: (err) => {
      setData(defaultAPIData);
    },
    onCancel: () => {
      setData(defaultAPIData);
    },
  });

  const addDatesToControl = (
    _control: DataControls,
    startDate: Date,
    endDate: Date
  ) => {
    let control = { ..._control }; // Create a copy of the controls so we don't change them directly.
    control.page = 0;
    // If the array exists and it has no elements, simply add the start and end dates.
    if (control.filters && control.filters.length === 0) {
      control.filters.push({
        key: "startDate",
        value: `${Math.floor(startDate.getTime() / 1000)}`,
        comp: "<",
      });
      control.filters.push({
        key: "endDate",
        value: `${Math.floor(endDate.getTime() / 1000)}`,
        comp: ">",
      });
      // If the filters array has at least one element, we must check if it's start or end date.
    } else if (control.filters && control.filters.length > 0) {
      let startDateExists = false,
        endDateExists = false;
      console.log(control.filters);
      // Loop through existing filters to see if the STart or End date exist, and update the value accordingly
      for (let f in control.filters) {
        // If this filter is for start date, change the value
        if (control.filters[f].key === "startDate") {
          control.filters[f].value = `${Math.floor(
            startDate.getTime() / 1000
          )}`;
          startDateExists = true;
        }
        // If this filter is for end date, change the value.
        if (control.filters[f].key === "endDate") {
          control.filters[f].value = `${Math.floor(endDate.getTime() / 1000)}`;
          endDateExists = true;
        }
      }
      // If start date didn't already exist in the filters
      if (!startDateExists) {
        control.filters.push({
          key: "startDate",
          value: `${Math.floor(startDate.getTime() / 1000)}`,
          comp: "<",
        });
      }
      // IF end date didn't already exist in the filters
      if (!endDateExists) {
        control.filters.push({
          key: "endDate",
          value: `${Math.floor(endDate.getTime() / 1000)}`,
          comp: ">",
        });
      }
      // OTHERWISE create the array and add the start and end dates.
    } else {
      control.filters = [];
      control.filters.push({
        key: "startDate",
        value: `${Math.floor(startDate.getTime() / 1000)}`,
        comp: "<",
      });
      control.filters.push({
        key: "endDate",
        value: `${Math.floor(endDate.getTime() / 1000)}`,
        comp: ">",
      });
    }
    return control;
  };

  const onLocalControl = (control: DataControls) => {};

  const onDatePaginationChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    setLoading(true);
    setEndpoint(
      `${endpointBase}?sort=msg_time:desc&limit=none&filter=msg_time<${Math.floor(
        startDate.getTime() / 1000
      )}&filter=msg_time>${Math.floor(endDate.getTime() / 1000)}}`
    );
    onControl(addDatesToControl(controls, startDate, endDate));
  };

  if (clientid === undefined || clientid === "") {
    return <Loading />;
  }

  const splitControl = getDatesFromControl(controls);
  let defStartDate, defEndDate;
  defStartDate = splitControl.startDate?.value
    ? new Date(parseInt(splitControl.startDate.value) * 1000) // The start date value
    : defaultStartDate // The default value (today)
  defEndDate = splitControl.endDate?.value
    ? new Date(parseInt(splitControl.endDate.value) * 1000) // The end date value
    : defaultEndDate; // The default value (1 day ago)

  return (
    <div className="Chatlog">
      <Row className="section">
        <Col xs={12}>
          <div className="content">
            <DatePagination
              defaultStartDate={defStartDate}
              defaultEndDate={defEndDate}
              onControl={(e: { startDate: Date; endDate: Date }) => {
                onDatePaginationChange(e);
              }}
            />
            <DataTable
              headers={headers}
              row={ChatlogMessageWithContext}
              data={paginatedData}
              controls={controls}
              onControl={onControl}
              loading={loading}
              numPages={Math.ceil(filteredData.length / defaultNumPerPage)}
              numPageButtons={3}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ClientChatlog;
