import React from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import axios from 'modules/axios';
import socketio from "modules/sockets";

export default function Explode({ clientid, guid, ip, name, slot }) {


  const issueExplode = (values) => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {
      socketio.nsp.emit("clientCmd", `.explode ${slot}`);
    }
  };

  return (
    <CommandButton
      label="Explode"
      description="Explode this client."
      confirm
      permission="cmd_explode"
      onConfirm={issueExplode}
      button={
        <Button variant="warning" size="sm">
          Explode <i className="fa fa-bomb" />
        </Button>
      }
    />
  );
}

Explode.propTypes = {
  clientid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guid: PropTypes.string,
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number
};
