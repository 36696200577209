import React from "react";
import CommandButton from "components/commands/CommandButton";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import axios from 'modules/axios';
import socketio from "modules/sockets";

export default function Rename({ clientid, guid, ip, name, slot }) {


  const issueRename = (values) => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {
      socketio.nsp.emit("clientCmd", `.rename ${slot} ${values.newName}`);
    }
  };

  let fields = [
    {
      name: "newName",
      label: "New Name",
      type: "text",
      required: true,
    }
  ]

  return (
    <CommandButton
      label="Rename"
      description="Rename this client."
      fields={fields}
      defaultValues={{newName: ""}}
      confirm
      permission="cmd_rename"
      onConfirm={issueRename}
      button={
        <Button variant="warning" size="sm">
          Rename <i className="fa fa-pencil" />
        </Button>
      }
    />
  );
}

Rename.propTypes = {
  clientid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guid: PropTypes.string,
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number
};
