import React from "react";
import ChatlogComponent from "components/echelon/Chatlog/Chatlog";
import { Sort } from "components/echelon/DataController";
import { FC } from "react";
import "scss/Chatlog.scss";
// import SortableHeadings from "SortableHeadings";
/** New  idea for chatlog: Date-Based
 * Filterable by client ID??
 * Pagination is ENTIRELY date-based. Maximum timespan on a single page is 1 month (31 days)
 * Defaults to last week of chatlog
 * Searching the message column only searches the currently displayed data.
 * Separate chatlog SEARCH controller. This searches ALL time, and is paginated.
 *
 */

interface ChatlogProps {
  defaultNumPerPage?: number;
  defaultSortKey?: string;
  defaultSortOrder?: Sort;
}

const Chatlog: FC<ChatlogProps> = ({
  defaultNumPerPage = 100,
  defaultSortKey = "msg_time",
  defaultSortOrder = "DESC",
  ...props
}) => {
  return <ChatlogComponent allClients />;
};

export default Chatlog;
