import { useNavigate } from "react-router-dom";
// import "react-virtualized/styles.css";
import {
  APIData,
  defaultAPIData
} from "components/echelon/APIDataTable";
import DataTable, { Header } from "components/echelon/DataTable";
import { useAxios } from "modules/axios";
import useDataControls, {
  DataControls,
  KeyValuePair,
  Sort
} from "modules/useDataControls";
import useDataFilter from "modules/useDataFilter";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Moment from "react-moment";
import "scss/Clients.scss";
// import AutoSizer from 'react-virtualized-auto-sizer';

const headers: Header[] = [
  {
    key: "name",
    label: "Name",
    sortable: true,
  },
  {
    key: "id",
    label: "ID",
    sortable: true,
    filterable: false,
  },
  {
    key: "connections",
    label: "Connections",
    sortable: true,
  },
  {
    key: "guid",
    label: "GUID",
    sortable: false,
  },
  {
    key: "time_edit",
    label: "Last Seen",
    sortable: true,
  },
  {
    key: "time_add",
    label: "First Seen",
    sortable: true,
  },
];
// interface ClientsListProps {
//   data: DataRow[];
// }
// function ClientsList({ data }: ClientsListProps) {
//   return (
//     <Container fluid>
//       {data.map((c) => (
//         <Link key={c.id} to={"/echelon/clientDetails/" + c.id}>
//           <Client
//             name={c.name}
//             connections={c.connections}
//             guid={c.guid}
//             id={c.id}
//             lastseen={c.time_edit}
//           />
//         </Link>
//       ))}
//     </Container>
//   );
// }

interface GameClientProps {
  id: string;
  connections: number;
  guid: string;
  name: string;
  time_add: number;
  time_edit: number;
}
function GameClient({
  id,
  connections,
  guid,
  name,
  time_add,
  time_edit,
}: GameClientProps) {
  const navigate = useNavigate();
  return (
    <tr onClick={() => navigate(`/echelon/clientDetails/${id}`)}>
      <td>
        <h3>{name}</h3>
      </td>
      <td>{id}</td>
      <td>{connections}</td>
      <td>{guid}</td>
      <td>
        <Moment unix format="MM/DD/YYYY hh:mm:ssa">
          {time_edit}
        </Moment>
      </td>
      <td>
        <Moment unix format="MM/DD/YYYY hh:mm:ssa">
          {time_add}
        </Moment>
      </td>
    </tr>
  );
}

interface ClientsProps {
  defaultNumPerPage?: number;
  defaultSort?: KeyValuePair<Sort>;
}

function Clients({
  defaultNumPerPage = 100,
  defaultSort = { key: "time_edit", value: "DESC" },
}: ClientsProps) {
  const endpointBase = `/api/v2/clients`;
  const [data, setData] = useState<APIData>(defaultAPIData);
  const { controls, onControl } = useDataControls({
    dataKey: "clients",
    defaults: { numPerPage: defaultNumPerPage, sort: defaultSort },
  });
  const [endpoint, setEndpoint] = useState<string>(() => {
    return `${endpointBase}?sort=${controls.sort?.key ?? defaultSort.key}:${
      controls.sort?.value ?? defaultSort.value
    }&limit=${defaultNumPerPage}${
      controls.after
        ? "&after=" + controls.after.key + ":" + controls.after.value
        : ""
    }`;
  });
  const { setFullData, fullData, filteredData } = useDataFilter({
    controls,
    headers,
  });
  const [page,] = useState<number>(controls.page ?? 0);

  const buildEndpoint = (control: DataControls): string => {
    return `${endpointBase}?limit=${defaultNumPerPage}&sort=${
      control.sort?.key ?? defaultSort.key
    }:${control.sort?.value ?? defaultSort.value}${
      control.after
        ? "&after=" + control.after.key + ":" + control.after.value
        : ""
    }${
      control.before
        ? "&before=" + control.before.key + ":" + control.before.value
        : ""
    }`;
  };

  const localOnControl = (control: DataControls) => {
    if (control.page && control.page > page) {
      control.after = {
        key: control.sort?.key ?? defaultSort.key,
        value:
          fullData[fullData.length - 1][control.sort?.key ?? defaultSort.key],
      };
    }

    if (control.page && control.page < page) {
      control.before = {
        key: control.sort?.key ?? defaultSort.key,
        value: fullData[0][control.sort?.key ?? defaultSort.key],
      };
    }

    if (!control.page || control.page === 0) {
      control.after = undefined;
    }

    setEndpoint(buildEndpoint(control));
    onControl(control);
  };

  useEffect(() => {
    setFullData(data.data);
  }, [data, setFullData]);

  const loading = useAxios(endpoint, {
    onFetched: (res: any) => {
      const data = res.data.data;
      if (!data) setData(defaultAPIData);
      else if (Array.isArray(data.data)) setData(data);
      else setData(defaultAPIData);
    },
    onError: (err) => {
      setData(defaultAPIData);
    },
    onCancel: () => {
      setData(defaultAPIData);
    },
  });

  return (
    <>
      <div className="Clients">
        <Row className="section">
          <Col xs={12}>
            <div className="Clients content">
              <DataTable
                // defaultNumPerPage={20}
                // defaultSortKey="id"
                // defaultSortOrder="DESC"
                row={GameClient}
                headers={headers}
                controls={controls}
                data={filteredData}
                onControl={localOnControl}
                loading={loading}
                disableControlsOnLoading
                numPages={Math.ceil(data.count / defaultNumPerPage)}
                numPageButtons={1}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

Clients.propTypes = {
  data: PropTypes.array,
};

export default Clients;
