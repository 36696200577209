import React from "react";
import "scss/CopyOnly.scss";
import PropTypes from "prop-types";

function CopyOnly(props) {
  return <span className="CopyOnly">{props.text}</span>;
}

CopyOnly.propTypes = {
  text: PropTypes.string,
};

export default CopyOnly;
