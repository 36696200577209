import { FC } from "react";
import Moment from "react-moment";
// import Paginate from "components/Paginate";
// import Loading from "components/Loading";
import APIDataTable from "components/echelon/APIDataTable";
import "scss/Aliases.scss";
// import SortableHeadings from "SortableHeadings";

interface AliasProps {
  num_used: number;
  alias: string;
  time_edit: number;
  time_add: number;
}

const Alias : FC<AliasProps> = ({num_used, alias, time_edit, time_add}) => {
  return (
    <tr className="Alias">
      <td>{num_used}</td>
      <td>{alias}</td>
      <td>
        <Moment unix format="YYYY/MM/DD hh:mma">
          {time_add}
        </Moment>
      </td>
      <td>
        <Moment unix format="YYYY/MM/DD hh:mma">
          {time_edit}
        </Moment>
      </td>
    </tr>
  );
}

const headers = [
  {
    key: "num_used",
    label: "# Used",
    sortable: true,
    filterable: false,
    searchable: true,
  },
  {
    key: "alias",
    label: "Alias",
    sortable: true,
    filterable: false,
    searchable: true,
  },
  {
    key: "time_add",
    label: "First Used",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: "time_edit",
    label: "Last Used",
    sortable: true,
    filterable: false,
    searchable: false,
  },
];

interface AliasesProps {
  clientid: number;
}

const Aliases: FC<AliasesProps> = ({clientid}) => {
  return (
    <div className="Aliases content">
      <h1>Aliases</h1>
      {clientid && (
        <APIDataTable
          cached
          endpoint={`/api/v2/clients/${clientid}/aliases`}
          defaultNumPerPage={10}
          defaultSortKey="time_edit"
          defaultSortOrder="DESC"
          row={Alias}
          headers={headers}
          datakey="aliases"
          />
      )}
    </div>
  );
}

export default Aliases;
