import FontAwesome from "components/util/FontAwesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ChatlogContext from "./ChatlogContext";

type Props = {
  msg: string;
  msg_type: string;
  msg_time: number;
  client_name: string;
  client_id: number;
  onClick: Function;
  highlighted: boolean;
  id: number;
  server_id: string;
  key: string;
  contextLink?: boolean;
  style?: any;
  msgHighlight?: string;
  index?: number;
};

const ChatlogMessage = ({
  msg,
  msg_type,
  client_name,
  client_id,
  onClick,
  msg_time,
  highlighted,
  id,
  server_id,
  key,
  contextLink = false,
  style,
  index,
}: Props) => {
  return (
    <tr
      className={"ChatMessage" + (highlighted ? " highlighted" : "")}
      
      key={key}
      style={style}
    >
      {index && <td className="col-1">{index}</td>}
      <td className="col-2 timestamp">
        <Moment unix format="MM/DD/YYYY hh:mm:ssa">
          {msg_time}
        </Moment>
      </td>
      <td className="col-2">
        <Link
          to={`/echelon/clientDetails/${client_id}`}
          onClick={(e) => e.stopPropagation()}
        >
          <i className="fa fa-user" />
          &nbsp;
        </Link>
        <OverlayTrigger
          // @ts-ignore
          placement="below"
          overlay={
            <Tooltip id={"tooltip-chatmessage-" + client_id}>
              {client_id}
            </Tooltip>
          }
        >
          <a
            href={`#${client_name}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClick(client_name, true);
            }}
          >
            {client_name}
          </a>
        </OverlayTrigger>
        {contextLink && (
          <span className="right">
            <ChatlogContext serverid={server_id} chatid={id} />
          </span>
        )}
      </td>
      <td className={"col message" + (msg_type === "MUTED" ? " muted" : "")} onClick={() => onClick(id)}>
        {msg_type === "MUTED" && (
          <>
            <FontAwesome icon="microphone" />
            &nbsp;
          </>
        )}
        {msg_type === "TEAM" && <span className="teammsg">(team) </span>}
        {msg}
      </td>
    </tr>
  );
};

export const ChatlogMessageWithContext = (props: Props) => {
  return ChatlogMessage({ ...props, contextLink: true });
};

export default ChatlogMessage;
