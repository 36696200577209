import { connect } from 'react-redux'
import Scoreboard from 'components/weniss/Scoreboard'
import { wenissSelectPlayer } from 'actions'

const mapStateToProps = state => ({
    players: state.players,
    selectedPlayer: state.selectedPlayer,
    scores: state.serverinfo.teamScores || {},
})


const mapDispatchToProps = dispatch => ({
    wenissSelectPlayer: id => dispatch(wenissSelectPlayer(id)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Scoreboard)
