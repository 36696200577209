import React, { EventHandler, FormEvent } from 'react'
import { Button, Form, InputGroup } from "react-bootstrap";
import Loading from '../Loading';

type SearchProps = {
  onSubmit?: (msg : string) => void;
  disabled?: boolean;
}

const Search = (props: SearchProps) => {
  const [value, setValue] = React.useState<string>("");

  const onSubmit = (e : FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit && props.onSubmit(value);
  }
  
  const clear = (e : FormEvent<HTMLButtonElement>) => {
    if (value !== "")
      props.onSubmit && props.onSubmit("");
    setValue("");
  }

  return (
    <>
    <Form onSubmit={onSubmit}>
      
      <Form.Label>Search</Form.Label>
      <InputGroup >
        <Form.Control type="text" onChange={(e) => setValue(e.target.value)} value={value} disabled={props.disabled} />
        <Button disabled={props.disabled || value===""} variant="secondary" onClick={clear}><i className="fa fa-times" /></Button>
        <Button type="submit" disabled={props.disabled}><i className={props.disabled ? "fa fa-spinner fa-spin" : "fa fa-search"} /></Button>
      </InputGroup>
    </Form>
    </>
  )
}

export default Search