import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import Loading from "components/echelon/Loading";
import { useAxios } from "modules/axios";
import { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "scss/PlayerSearch.scss";
import FontAwesome from "./util/FontAwesome";

const R_GUID = /^[A-z0-9]{32}$/;
const R_CLIENTID = /^[0-9]+$/;
const R_IP = /^[12]?[0-9]{1,2}\.([12]?[0-9]{1,2}\.?){2,3}$/;
const R_FULLIP =
  /^[12]?[0-9]{1,2}\.[12]?[0-9]{1,2}\.[12]?[0-9]{1,2}\.[12]?[0-9]{1,2}$/;

interface PlayerSearchProps {
  onSelect?: Function;
}

interface PlayerSearchResult {
  id: string;
  client_id: string;
  name?: string;
  alias?: string;
  connections?: number;
  num_used?: number;
}

enum SearchType {
  ID,
  IP,
  Name,
  GUID
}

const PlayerSearch: FC<PlayerSearchProps> = ({onSelect}) => {
  const endpointBase = "/api/v2/clients";
  const [searchStr, setSearchStr] = useState<string>("");
  const [searchType, setSearchType] = useState<SearchType>();
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();
  const [searchResults, setSearchResults] = useState<Array<PlayerSearchResult>>(
    []
  );
  const [aliasSearch, setAliasSearch] = useState<boolean>(false);
  const [endpoint, setEndpoint] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const isGUID = (str: string): boolean => {
    return R_GUID.test(str);
  };

  const isIP = (str: string): boolean => {
    return R_IP.test(str);
  };

  const isFullIP = (str: string): boolean => {
    return R_FULLIP.test(str);
  };

  const isClientId = (str: string): boolean => {
    return R_CLIENTID.test(str);
  };

  const localOnSelect = (e : any) => {
    console.log('localonselect');
    if (onSelect !== undefined) {
      console.log('Running onselect');
      onSelect(e);
      return;
    }
    if (e === -1 || e === '-1') {
      setAliasSearch(true);
      updateEndpoint(true);
      return;
    }
    console.log('onSelect: ', e);
    navigate(`/echelon/clientDetails/${e}`);
  };

  const onSearchChange = (e: any): void => {
    const str = e.target.value;
    setSearchStr(str);
    setSearchResults([]);
    setAliasSearch(false);
  };

  const updateEndpoint = (alias: boolean = false) => {
    const str = searchStr;
    if (isClientId(str)) {
      setEndpoint(
        `${endpointBase}/?filter=id=${str}&sort=time_edit:DESC&limit=10`
      );
      setSearchType(SearchType.ID);
    } else if (isGUID(str)) {
      setEndpoint(
        `${endpointBase}/?filter=guid=${str}&sort=time_edit:DESC&limit=10`
        );
      setSearchType(SearchType.GUID);
    } else if (isIP(str)) {
      setSearchType(SearchType.IP);
      if (isFullIP(str)) {
        setEndpoint(`${endpointBase}/iplogs/?filter=ip=${str}&sort=time_edit:DESC&limit=10`);
      } else {
        setEndpoint(
          `${endpointBase}/iplogs?filter=ip;${str}@&sort=time_edit:DESC&limit=10`
          );
        }
      } else {
      setSearchType(SearchType.Name);
      // let start = str[0] === '^' ? '' : '@';
      // let end = str[str.length-1] === '$' ? '' : '@';

      // let newStr = str;
      // if (start === '')
      //   newStr = newStr.substring(1);
      // if (end === '')
      //   newStr = newStr.substring(0, newStr.length-1);
      if (alias) {
        setEndpoint(
          `${endpointBase}/aliases?search=alias:${str}&sort=time_edit:DESC&limit=10`
        );
      } else {
        setEndpoint(
          `${endpointBase}?search=name:${str}&sort=time_edit:DESC&limit=10`
        );
      }
    }
    setLoading(true);
  }

  useAxios(endpoint, {
    onFetched: (res) => {
      console.log(res);
      const data = res.data.data.data;
      if (!data)
        setSearchResults([]);
      else if (Array.isArray(data))
        setSearchResults(data);
      else
        setSearchResults([data]);
      setLoading(false);
    },
    onError: (err) => {},
    onCancel: () => {
      console.log('RequestCancelled');
    }
  })

  useEffect( () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    if (searchStr && searchStr.length >= 3) {
      const timeout = setTimeout( () => {
        updateEndpoint();
      }, 500);
      setSearchTimeout(timeout);
    }
  }, [searchStr])

  return (
    <div className="PlayerSearch">
      <Combobox onSelect={localOnSelect} openOnFocus>
        <ComboboxInput
          placeholder="Search for player..."
          aria-labelledby="players"
          onChange={onSearchChange}
          autocomplete={false}
          value={searchStr}
          
        />
        <ComboboxPopover style={{ zIndex: 20000, background: "#333" }}>
          {loading ? (
            <Loading />
          ) : searchResults && searchResults.length > 0 ? (
            <ComboboxList aria-labelledby="players" className="PlayerSearchList" >
              {searchType === SearchType.Name && aliasSearch === true && (
                <span className="searchmessage">Found Aliases:</span>
              )}
              {searchResults.map((res) => (
                <ComboboxOption value={res.client_id || res.id} key={res.client_id || res.id}>
                  <span className="flexitem">
                    <span style={{ width: "30%" }}>[{
                      searchType === SearchType.GUID || searchType === SearchType.ID || (searchType === SearchType.Name && !aliasSearch) || searchType === SearchType.IP ? res.id
                    : res.client_id}]</span>
                    <span style={{ width: "30%" }}>{res.alias ?? ""}{res.name ?? ""}</span>
                    <span style={{ width: "30%", float: "right" }}>
                      {res.connections || res.num_used}
                    </span>
                  </span>
                </ComboboxOption>
              ))}
              {searchType === SearchType.Name && aliasSearch === false && (
                  <Button className="right text-right" variant="search" onClick={() => {setAliasSearch(true); updateEndpoint(true)}}><FontAwesome icon="search" /> Search Aliases</Button>
              )} 
            </ComboboxList>
          ) : (
            <span style={{ display: "block", margin: 8, zIndex: 10001 }}>
              No results found.
              {searchType === SearchType.Name && aliasSearch === false && (
                  <Button className="right text-right" variant="search" onClick={() => {setAliasSearch(true); updateEndpoint(true)}}><FontAwesome icon="search" /> Search Aliases</Button>
              )} 
            </span>

          )}
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

export default PlayerSearch;
