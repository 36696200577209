import React, { EventHandler, FormEvent } from 'react'
import { Button, Form, InputGroup } from "react-bootstrap";

type SearchProps = {
  onSubmit?: (msg ?: Date) => void;
  disabled?: boolean;
}

const DateSelector = (props: SearchProps) => {
  const [value, setValue] = React.useState<string>("");

  const onSubmit = (e : FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit && props.onSubmit(new Date(value));
  }

  const clear = (e : FormEvent<HTMLButtonElement>) => {
    if (value !== "")
      props.onSubmit && props.onSubmit();
    setValue("");
  }

  return (
    <Form onSubmit={onSubmit}>
      
      <Form.Label>Select Date</Form.Label>
      <InputGroup>
        <Form.Control type="date" onChange={(e) => setValue(e.target.value)} value={value} disabled={props.disabled} />
        <Button disabled={props.disabled || value===""} variant="secondary" onClick={clear}><i className="fa fa-times" /></Button>
        <Button type="submit" disabled={props.disabled}><i className={props.disabled ? "fa fa-spinner fa-spin" : "fa fa-check"} /></Button>
      </InputGroup>
    </Form>
  )
}

export default DateSelector