import axios from "../modules/axios";

export interface User {
  cacheExpireTime: number;
  client_id: number;
  group_bits: number;
  guid: string;
  id: number;
  lastonline: number;
  loggedIn: boolean;
  login: string;
  name: string;
  nickname: string;
  perms: string[];
  roles: string[];
  power: number;
  username: string;
}

let userData : User = {
  cacheExpireTime: 0,
  client_id: 0,
  group_bits: 0,
  guid: "",
  id: 0,
  lastonline: 0,
  loggedIn: false,
  login: "",
  name: "",
  nickname: "",
  perms: [],
  roles: [],
  power: 0,
  username: "",
};


function isLoggedIn() {
  return userData.loggedIn || false;
}

function getUserData() {
  return userData;
}

function RefreshLogin() {
  return new Promise((resolve, reject) => {
    axios.get("/api/users/me").then((res) => {
      if (res.data.error === "unauthorized") {
        return reject("unauthorized");
      }
      userData = res.data as User;
      userData.loggedIn = true; 
      return resolve(res.data);
    });
  });
}

function Login(username: string, password: string) {
  return new Promise((resolve, reject) => {
    axios
      .post("/login", {
        username: username,
        password: password,
      })
      .then((res) => {
        let data = res.data;

        if (data.error === "unauthorized") {
          reject("Invalid username or password");
        } else {
          userData = data;
          data.loggedIn = true;
          resolve(data);
        }
      });
  });
}

export function havePerm(perm: string) {
  return userData.roles.includes('Super Admin') || userData.perms.includes(perm);
}

const def = {
  isLoggedIn,
  getUserData,
  Login,
  RefreshLogin,
  havePerm
};

export default def;
