import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const HoveredPlayerHighlight = (props) => {
    const hoverCSS = `{background-color: #3d3d3d;}`;
    return (
        <style type="text/css">
            .{props.playerid || ''} {hoverCSS}
        </style>
    )
}

HoveredPlayerHighlight.propTypes = {
    playerid: PropTypes.string,
}

HoveredPlayerHighlight.defaultProps = {
    playerid: ''
};

const mapStateToProps = state => ({
    playerid: state.weniss.hoveredPlayer,
});


export default connect(
    mapStateToProps,
    null)(HoveredPlayerHighlight)
