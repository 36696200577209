import React from 'react'
import PropTypes from 'prop-types'
import Player from 'components/weniss/Player'
import "scss/AdminList.scss"

class AdminList extends React.Component {
    render() {
        return (
            <div className="AdminList">
                <ul>
                {this.props.admins.map((a) => (
                    <li key={a.login}><Player
                        name={a.name}
                        guid={a.guid}
                        slot="W"
                        showSlot={false} /></li>
                ))}
                </ul>
            </div>
        );
    }
}

AdminList.propTypes = {
    admins: PropTypes.arrayOf(PropTypes.shape(Player.propTypes)).isRequired
}

export default AdminList
