import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "actions";
import Loading from "components/echelon/Loading";
import Authentication from "services/Authentication";
import "./Login.scss";

function Login({ adminLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    if (!username || !password) {
      return setFormMessage("You must enter a username and password.");
    }
    setLoading(true);
    Authentication.Login(username, password)
      .then((res) => {
        adminLogin(res);
        setTimeout(() => {
          navigate("/");
        }, 200);
      })
      .catch((msg) => {
        setFormMessage(msg);
        setLoading(false);
      });
  };

  return (
    <div className="Login">
      <div className="login-form-container">
        {loading && <Loading />}
        <div className="login-form">
          <Form onSubmit={onSubmit}>
            <div className="login-form-title">[W].E.N.I.S.S.</div>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                placeholder="Username..."
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                placeholder="Password..."
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            {formMessage && (
              <div className="login-form-message">{formMessage}</div>
            )}
            <br />
            <Button variant="secondary" type="submit">
              Login
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  adminLogin: PropTypes.func,
};

export default connect(null, (dispatch) => ({
  adminLogin: (userData) => dispatch(adminLogin(userData)),
}))(Login);
