// Reducer for state.serverinfo

import { ACTIONS } from '../actions'

// Default state is serverinfo from the INITGAME log
//   that happens on every map change.

const defaultState = {
  serverinfo: {
    mapname: "mp_shipment",
    sv_hostname: "^3NET-JAM.com ^2(HARDCORE)",
    sv_maxclients: "24",
    sv_privateclients: "",
    g_mapstarttime: "",
    shortversion: "1.7ish",
    sv_floodprotect: "1",
    sv_maxRate: "25000",
    sv_voice: "0",
    sv_maxPing: "400",
    sv_minPing: "0",
    protocol: "6",
    sv_privateClients: "2",
    sv_disableClientConsole: "0",
    g_mapStartTime: "Sat Feb  8 18:34:30 2020",
    uptime: "31 days",
    g_gametype: "war",
    sv_pure: "1",
    _Admin: "Gryphus_1",
    _Clan: "NJA",
    _Website: "http://www.net-jam.com",
    _Location: "USA",
    _Irc: "irc.net-jam.com",
    _Teamspeak: "ts3.net-jam.com",
    g_compassShowEnemies: "0",
    gamename: "Call of Duty 4",
},
teamScores: {},
};

const serverinfo = (state = defaultState, action) => {
  switch (action.type) {

    // Triggered whenever the socket server is initially connected.
    // Forces the serverlog to match the WENISS server.
    case ACTIONS.WENISS.INIT_SERVERINFO: {
      return {...action.json, teamScores: {}};
    }
    // Triggered whenever the server changes map
    case ACTIONS.SERVER.CHANGE_MAP: {
        return {...action.json, teamScores: {}};
    }

    case ACTIONS.SERVER.TEAMSCORES: {
        return {...state, teamScores: action.json};
    }

    default:
      return state;
  }
};

export default serverinfo;
