import ChatlogContext from "components/echelon/ChatlogContext";
import FontAwesome from "components/util/FontAwesome";
import { useAxios } from "modules/axios";
import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";

type Props = {};

const headers = [
  {
    key: "msg_time",
    label: "Timestamp",
    sortable: true,
    filterable: false,
    searchable: false,
  },
  {
    key: "client_name",
    label: "Name",
    sortable: false,
    filterable: false,
    searchable: true,
  },
  {
    key: "msg",
    label: "Message",
    sortable: false,
    filterable: false,
    searchable: true,
  },
];

interface MessageProps {
  msg: string;
  msg_type: string;
  singleClient: boolean;
  client_name: string;
  client_id: number;
  onClick?: Function;
  msg_time: number;
  highlighted: boolean;
  id: number;
  server_id: string;
}

const Message = ({
  msg,
  msg_type,
  singleClient,
  client_name,
  client_id,
  onClick = () => {},
  msg_time,
  highlighted,
  id,
  server_id
} : MessageProps) => (
  <tr className={"ChatMessage" + highlighted ? " highlighted" : ""}>
    <td className="col-2">
      <Moment unix format="MM/DD/YYYY hh:mm:ssa">
        {msg_time}
      </Moment>
    </td>
    <td className="col-2">
        {!singleClient && (
          <Link
            to={`/echelon/clientDetails/${client_id}`}
            onClick={(e) => e.stopPropagation()}
          >
            <Button size="sm"><i className="fa fa-user" /></Button>&nbsp;
          </Link>
        )}
        <OverlayTrigger
          overlay={
            <Tooltip id={"tooltip-chatmessage-" + client_id}>
              {client_id}
            </Tooltip>
          }
        >
          <a
            onClick={(e) => {
              e.stopPropagation();
              onClick(client_name);
            }}
          >
            {client_name}
          </a>
        </OverlayTrigger>
        <div style={{ float: "right", paddingRight: "5px" }}>
          {singleClient && <ChatlogContext serverid={server_id} chatid={id} />}
        </div>
      </td>
      <td className={"col message" + (msg_type === "MUTED" ? " muted" : "")}>
        {msg_type === "MUTED" && (
          <>
            <FontAwesome icon="microphone" />
            &nbsp;
          </>
        )}
        {msg_type === "TEAM" && <span className="teammsg">(team) </span>}
        {msg}
      </td>
  </tr>
)

const Test = (props: Props) => {
  const endpointBase = "/api/v2/servers/all/chatlog";

  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [endpoint, setEndpoint] = useState<string>(
    `${endpointBase}?sort=msg_time:desc&limit=none&filter=msg_time>${
      Math.floor(new Date().getTime() / 1000) - 60 * 60 * 24 * 30
    }`
  );

  const axios = useAxios(endpoint, {
    onFetched: (res) => {
      console.log(res, endpoint);
      setLoading(false);
      const data = res.data.data.data;
      if (!data) setData([]);
      else if (Array.isArray(data)) setData(data);
      else setData([data]);
    },
    onError: (err) => {
      setData([]);
    },
    onCancel: () => {
      setData([]);
    },
  });

  const onDatePaginationChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    console.log('Start', startDate, 'End', endDate);
    setLoading(true);
    setEndpoint(
      `${endpointBase}?sort=msg_time:desc&limit=none&filter=msg_time<${Math.floor(
        startDate.getTime() / 1000
      )}&filter=msg_time>${Math.floor(endDate.getTime() / 1000)}}`
    );
  };
return <></>
  // return (
  //   <div>
  //     <DatePagination onControl={onDatePaginationChange} />
  //     {data.length > 0 && (
  //       <DataController
  //         initial={{
  //           sortKey: 'msg_time',
  //           sortOrder: 'DESC',
  //           page: 0,
  //           numPerPage: 10,
  //           // @ts-ignore
  //           filtered: undefined, 
  //           cached: true,
  //         }}
  //         data={data}
  //       >
  //         {(control: any) => (
  //           <DataTable
  //             headers={headers}
  //             row={Message}
  //             controls={control.controls}
  //             data={control.data}
  //             loading={loading}
  //             numPages={Math.ceil(control.numTotalItems / 10)}
  //           />
  //         )}
  //       </DataController>
  //     )}
  //   </div>
  // );
};

export default Test;
