import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import io from "socket.io-client";
import {
  adminAlerts,
  adminAuthErr,
  adminChat,
  adminConnect,
  adminDisconnect,
  adminInitChat,
  adminList,
  playerAlerts,
  playerConnect,
  playerDisconnect,
  playerIpInfo,
  playerJoinTeam,
  playerKill,
  playerList,
  playerMute,
  playerRename,
  serverChangeMap,
  serverLogItem,
  teamScores,
  wenissInitPlayers,
  wenissInitServerinfo,
  wenissInitServerlog,
} from "../actions";

import { connect } from "react-redux";

class Socket {
  constructor() {
    this.nsp = null;
    this.io = null;
    this.initailized = false;
    this.currentGame = "";
    this.currentServer = "";
    this.subscribed = false;
    this.isConnected = false;
  }

  SetSubscribed(isSubscribed) {
    this.subscribed = isSubscribed;
  }

  IsSubscribed() {
    return this.subscribed;
  }

  IsConnected() {
    return this.isConnected;
  }

  init() {
    if (this.initialized) return false;

    this.nsp = io();
    this.io = io("/");

    this.initialized = true;
    return true;
  }

  setCurrentServer(game, server) {
    if (!this.IsSubscribed()) {
      this.currentGame = game;
      this.currentServer = server;
    } else {
      this.connectToServer(game, server);
    }
  }

  connectToDefault() {
    this.connectToServer(this.currentGame, this.currentServer);
  }

  connectToServer(game, server) {
    if (!this.initialized) this.init();
    const _callbacks = this.nsp._callbacks;
    if (this.currentGame !== game || this.currentServer !== server) {
      // console.log(`connecting to /${game}/${server}`);
      if (this.nsp && this.nsp.disconnect) this.nsp.disconnect();
    } else {
      return false;
    }
    this.nsp = io(`/${game}/${server}`);
    this.nsp.connect();
    this.currentGame = game;
    this.currentServer = server;
    this.nsp._callbacks = _callbacks;
    this.isConnected = true;
    return true;
  }
}

const socketio = new Socket();

export default socketio;

const SocketWrapperComponent = (props) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (mounted) return;
    setMounted(true);
    if (!socketio.IsSubscribed()) {
      if (!socketio.IsConnected()) socketio.connectToDefault();
      // CoD4 Server Namespace
      // const nsp = io('/cod4/lts');
      // socketio.nsp.connect();
      socketio.nsp.on("connect", () => {});
      socketio.nsp.on("serverlog", (data) => {
        props.serverLogItem(data);
      });
      socketio.nsp.on("mapchange", (data) => {
        // console.log("socket:mapchange", data);
        props.serverChangeMap(data);
      });
      socketio.nsp.on("server:killfeed", (data) => {
        props.playerKill(data);
      });
      socketio.nsp.on("player:join", (data) => {
        // console.log("playerJoin: ", data);
        props.playerConnect(data);
      });
      socketio.nsp.on("player:quit", (data) => {
        // console.log("playerQuit: ", data);
        props.playerDisconnect(data);
      });
      socketio.nsp.on("player:list", (data) => {
        props.playerList(data);
      });
      socketio.nsp.on("player:joinTeam", (data) => {
        props.playerJoinTeam(data);
      });
      socketio.nsp.on("player:rename", (data) => {
        props.playerRename(data);
      });
      socketio.nsp.on("player:ipinfo", (data) => {
        props.playerIpInfo(data);
      });
      socketio.nsp.on("player:alerts", (data) => {
        props.playerAlerts(data);
      });
      socketio.nsp.on("player:mute", (data) => {
        props.playerMute(data);
      });
      socketio.nsp.on("rcon:response", (/* data */) => {
        // console.log(data);
      });
      socketio.nsp.on("serverlog:init", (data) => {
        // console.log("Serverlog Init: ", data);
        props.wenissInitPlayers(data.players);
        props.wenissInitServerlog(data.serverlog);
        props.wenissInitServerinfo(data.serverInfo);
      });
      socketio.nsp.on("team:scores", (data) => {
        // console.log(JSON.parse(data));
        props.teamScores(JSON.parse(data));
      });

      socketio.io.on('test', console.log);

      // Admin Information Namespace
      // const socket = io('/');
      // socketio.io.connect();
      socketio.io.on("connect_error", (/* d */) => {
        // console.log(d);
      });
      socketio.io.on("admin:list", (d) => {
        props.adminList(d);
      });
      socketio.io.on("admin:disconnect", (d) => {
        props.adminDisconnect(d);
      });

      window.io = socketio.io;
      window.nsp = socketio.nsp;
      
      socketio.io.on("admin:alerts", (d) => {
        props.adminAlerts(d);
      });
      socketio.io.on("admin:chat", (d) => {
        props.adminChat(d);
      });
      socketio.io.on("admin:init_chat", (d) => {
        props.adminInitChat(d);
      });
      socketio.io.on("error", (d) => {
        console.log(d);
      });
      socketio.io.on("auth:error", (d) => {
        props.adminAuthErr(d);
      });
      socketio.SetSubscribed(true);
    }
  }, []);

  return <></>;
};

SocketWrapperComponent.propTypes = {
  playerConnect: PropTypes.func,
  playerDisconnect: PropTypes.func,
  playerJoinTeam: PropTypes.func,
  playerRename: PropTypes.func,
  playerIpInfo: PropTypes.func,
  playerKill: PropTypes.func,
  playerList: PropTypes.func,
  playerAlerts: PropTypes.func,
  playerMute: PropTypes.func,
  serverLogItem: PropTypes.func,
  serverChangeMap: PropTypes.func,
  wenissInitPlayers: PropTypes.func,
  wenissInitServerlog: PropTypes.func,
  wenissInitServerinfo: PropTypes.func,
  adminAuthErr: PropTypes.func,
  adminConnect: PropTypes.func,
  adminDisconnect: PropTypes.func,
  adminList: PropTypes.func,
  adminAlerts: PropTypes.func,
  adminInitChat: PropTypes.func,
  adminChat: PropTypes.func,
  teamScores: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps =  (dispatch) => ({
  playerConnect: (data) => dispatch(playerConnect(data)),
  playerDisconnect: (data) => dispatch(playerDisconnect(data)),
  playerJoinTeam: (data) => dispatch(playerJoinTeam(data)),
  playerRename: (data) => dispatch(playerRename(data)),
  playerIpInfo: (data) => dispatch(playerIpInfo(data)),
  playerKill: (data) => dispatch(playerKill(data)),
  playerList: (data) => dispatch(playerList(data)),
  playerAlerts: (data) => dispatch(playerAlerts(data)),
  playerMute: (data) => dispatch(playerMute(data)),
  serverLogItem: (data) => dispatch(serverLogItem(data)),
  serverChangeMap: (data) => dispatch(serverChangeMap(data)),
  wenissInitPlayers: (data) => dispatch(wenissInitPlayers(data)),
  wenissInitServerlog: (data) => dispatch(wenissInitServerlog(data)),
  wenissInitServerinfo: (info) => dispatch(wenissInitServerinfo(info)),
  adminAuthErr: (info) => dispatch(adminAuthErr(info)),
  adminConnect: (info) => dispatch(adminConnect(info)),
  adminDisconnect: (info) => dispatch(adminDisconnect(info)),
  adminList: (info) => dispatch(adminList(info)),
  adminAlerts: (info) => dispatch(adminAlerts(info)),
  adminInitChat: (info) => dispatch(adminInitChat(info)),
  adminChat: (info) => dispatch(adminChat(info)),
  teamScores: (scores) => dispatch(teamScores(scores)),
});

export const SocketWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocketWrapperComponent);
