import DataTable, { Header } from "components/echelon/DataTable";
import Loading from "components/echelon/Loading";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "modules/axios";
import useDataControls from "modules/useDataControls";
import useDataFilter, { DataRow } from "modules/useDataFilter";

interface UserRole {
  user_id: number;
  user_name: string;
  user_login: string;
  user_nickname?: string;
  role_id: number;
  role_name: string;
}

interface Role {
  id: number;
  name: string;
}

type UserRoleProps = {
  id: number;
  name: string;
  login: string;
  nickname?: string;
  allRoles: Role[];
  roles: Role[];
  onChange?: Function;
};

const UserRole = ({
  id,
  name,
  login,
  nickname,
  allRoles,
  roles,
  onChange = (user_id: number, role_id: number, has: boolean) => {console.log("Default onChange")},
}: UserRoleProps) => {

  const hasRole = useCallback((id:number) => {
    for (let r in roles) {
      if (roles[r].id === id)
        return true;
    }
    return false;
  }, [roles])

  return (
    <tr>
      <td>
        <span className="strong">{nickname ?? name}</span>
        <br />
        <span className="small">{login}</span>
      </td>
      {allRoles.map((role) => (
        <td>
          <Form.Check
            checked={hasRole(role.id)}
            onChange={() => onChange(
              id,
              role.id,
              !hasRole(role.id)
            )}
          />
        </td>
      ))}
    </tr>
  );
};



interface User {
  id: number;
  name: string;
  login: string;
  nickname?: string;
  roles: Role[];
}

type Props = {};

const UserRoles = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [roles, setRoles] = useState<Role[]>([]);
  const [headers, setHeaders] = useState<Header[]>([]);

  const { controls, onControl } = useDataControls({
    dataKey: "users",
    useDeepLinks: false,
    defaults: { numPerPage: 10000 },
  });

  const { setFullData, filteredData } = useDataFilter({
    controls,
    headers,
  });

  const loadUsers = useCallback(() => {
    setLoading(true);
    axios.get(`/api/users`).then((res) => {
      if (res.data.data) {
        let _users = res.data.data;
        for (let u in _users) {
          let user = _users[u];
          let _roles = [];
          for (let i = 0; i < user.roleids.length; i++) {
            _roles.push({ id: user.roleids[i], name: user.roles[i] });
          }
          user.roles = _roles;
          user.roleids = undefined;
        }
        setFullData(_users);
        setLoading(false);
      }
    });
  }, []);

  const loadAllRoles = useCallback(() => {
    axios.get(`/api/roles`).then((res) => {
      let _roles = res.data.roles;
      let _headers : Header[] = [{key: "login", label: "Admin", searchable: true, searchFunc: (row: DataRow, val: string | string[]) => {
        if (row?.login?.includes(val) || row?.name?.includes(val) || row?.nickname?.includes(val))
          return true;
        return false;
      }}];
      for (let r in _roles) {
        _roles[r].id = _roles[r].role_id;
        _roles[r].name = _roles[r].role_name;
        _roles[r].role_id = undefined;
        _roles[r].role_name = undefined;
        _headers.push( {
          key: _roles[r].name,
          label: _roles[r].name
        })
      }
      setRoles(_roles);
      setHeaders(_headers);

      setLoadingRoles(false);
    });
  }, []);

  useEffect(() => {
    loadAllRoles();
    loadUsers();
  }, [])
  
  const onChangeUserRole = useCallback((user_id: number, role_id: number, has: boolean) => {
    console.log('Change User Role', user_id, role_id, has);
    axios.post(`/api/users/roles`, {
      role: role_id,
      has: has ? "true" : "false", // I'm a complete idiot for writing it as a string on the server....
      userid: user_id
    }).then((res) => {
      console.log('Response from change user role', res)
      loadUsers();
    })
  }, [])

  return (
    <div className="WenissSettingPage UserRoles">
      {(loading || loadingRoles) && <Loading />}
      <DataTable 
        row={UserRole}
        data={filteredData}
        headers={headers}
        onControl={onControl}
        controls={controls}
        nopagination
        rowProps={{
          allRoles: roles,
          onChange: onChangeUserRole,
        }}
        />
    </div>
  );
};

export default UserRoles;
