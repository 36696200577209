import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import Moment from 'react-moment'
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'
import AutoSizer from 'react-virtualized-auto-sizer'
import 'scss/AdminChat.scss'
import ColoredText from 'components/util/ColoredText'
import Player from 'components/weniss/Player'


function formAdminFromId(admins, id) {
    for(let i in admins) {
        if (admins[i].id === id) {
            return {
                name: admins[i].name,
                slot: "W",
                id: admins[i].id,
                guid: admins[i].guid || "NA",
            }
        }
    }
    return {
        name: "NOT_FOUND",
        slot: "NA",
        guid: "NA",
        id: "NOT_FOUND",
    }
}


class ServerLog extends React.PureComponent {
    constructor(props) {
        super(props)
        const cache = new CellMeasurerCache({
            defaultHeight: 21,
            fixedWidth: true,
            // keyMapper: () => 1,
        })
        this.state = {
            visibleStopIndex: 500,
            numberOfMessages: 0,
        };
        this.onRowsRendered = this.onRowsRendered.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.row = this.row.bind(this);
        this.listRef = React.createRef();
        this.cache = cache;
    }

    getSnapshotBeforeUpdate(prevProps, /*prevState*/) {
        // Debuggign...
        // console.log('index:', this.state.visibleStopIndex,
        //     'oldItems: ', prevProps.messages.length,
        //     'newItems: ', this.props.messages.length,
        //     'props: ', this.props);
        if (prevProps.messages.length < this.props.messages.length
            && this.state.visibleStopIndex >= prevProps.messages.length-1) {
            return this.props.messages.length-1;
        }
        return null;
    }

    row({index, parent, key, style}) {
        var message = this.props.messages[index];
        var admins = this.props.admins;
        let admin = formAdminFromId(admins, message.admin);
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}>
                    <div className="msg" style={style}>
                    <Moment
                        date={moment.unix(Math.floor(message.timestamp))}
                        format="HH:mm:ss"
                        withTitle
                        titleFormat="ddd, MMM DD, YYYY HH:mm:ss"
                        tz="America/Los_Angeles"/>&nbsp;
                            <Player
                            showSlot={false}
                            name={admin.name}
                            slot={admin.slot}
                            guid={admin.guid}
                            id={admin.id} />
                            <ColoredText>{message.message}</ColoredText>
                    </div>
            </CellMeasurer>
        )
    }

    scrollToBottom() {
        if (this.listRef && this.listRef.current)
            this.listRef.current.scrollToItem(this.props.messages.length);
    }

    onRowsRendered({stopIndex}) {
        if (stopIndex >= this.props.messages.length-2) {
            this.setState({
                visibleStopIndex: this.props.messages.length,
            })
        } else if(this.state.visibleStopIndex !== undefined) {
            this.setState({
                visibleStopIndex: undefined,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('Update!', snapshot);
        if (snapshot !== null) {
            // console.log('Snapshot: ', snapshot, 'Items: ', this.props.messages.length);
            if (this.listRef && this.listRef.current)
                this.listRef.current.scrollToItem(snapshot);
        }
    }

    render() {
        // return (
        //     <div className="adminChatLog">
        //     {this.props.messages.map( (message) => (
        //         <p className="msg" key={message.id}>
        //             <Player
        //                 name={"Test"}
        //                 slot={"W"}
        //                 id={message.admin} />
        //                 {message.message}
        //         </p>
        //     ))}
        //     </div>
        // );
        return (
            <div className="AdminChat">
                <AutoSizer>
                    {({width, height}) => (
                        <List
                        height={height}
                        width={width}
                        deferredMeasurementCache={this.cache}
                        rowHeight={this.cache.rowHeight}
                        rowRenderer={this.row}
                        scrollToIndex={this.state.visibleStopIndex}
                        rowCount={this.props.messages.length}
                        onRowsRendered={this.onRowsRendered}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    }
}

ServerLog.propTypes = {
    messages: PropTypes.array.isRequired,
    admins: PropTypes.array.isRequired,
    sbHeight: PropTypes.number,
}

ServerLog.defaultProps = {
    messages : [],
    admins: [],
};

export default ServerLog
