import useDeepLinking from "modules/deepLinking";
import { useState } from "react";

export interface KeyValuePair<T2, T1 = string> {
  key: T1;
  value?: T2;
}

export interface FilterKeyValue<T2, T1 = string> {
  key: T1;
  value?: T2;
  comp?: Comparator;
}

export type Sort = "ASC" | "DESC" | "NONE";
export type Comparator =
  | ">="
  | "<="
  | ">"
  | "<"
  | "!="
  | "="
  | "!:"
  | ":"
  | ";;"
  | ";";

const DataComparators = [">=", "<=", ">", "<", "!=", "=", "!:", ":", ";;", ";"];

const GetDataComparator = (param: string): Comparator | undefined => {
  for (let i in DataComparators) {
    if (param.indexOf(DataComparators[i]) >= 0)
      return DataComparators[i] as Comparator;
  }
};

export interface DataControls {
  page?: number;
  numPerPage?: number;
  numPages?: number;
  first?: KeyValuePair<string>;
  last?: KeyValuePair<string>;
  after?: KeyValuePair<string>;
  before?: KeyValuePair<string>;
  sort?: KeyValuePair<Sort>;
  limit?: number | "none";
  filters?: FilterKeyValue<string>[];
  searches?: KeyValuePair<string>[];
}

interface DataControlsProps {
  dataKey: string;
  useDeepLinks?: boolean;
  defaults?: DataControls;
}

const useDataControls = ({
  dataKey,
  useDeepLinks = true,
  defaults,
}: DataControlsProps) => {
  const GetAllDeepLinkParams = (): DataControls => {
    let sortParam = getSearchParam("Sort");
    let sort,
      page,
      numPerPage,
      after,
      filters: FilterKeyValue<string>[] = [],
      searches: KeyValuePair<string>[] = [];

    // Parse the Sort part of the URL Params
    if (typeof sortParam === "string") {
      let sortKey = sortParam.split(":")[0];
      let sortOrder = sortParam.split(":")[1];

      sort = { key: sortKey, value: sortOrder as Sort };
    }

    // Page and Num Per Page
    let pageParam = getSearchParam("Page");
    if (typeof pageParam === "string") {
      // Handle if we included the numPerPage or not.
      if (pageParam.indexOf(":") >= 0) {
        page = parseInt(pageParam.split(":")[0]);
        numPerPage = parseInt(pageParam.split(":")[1]);
      } else {
        page = parseInt(pageParam);
      }
    }

    // After
    let afterParam = getSearchParam("After");
    if (typeof afterParam === "string") {
      let [key, value] = afterParam.split(":");
      after = {
        key,
        value,
      };
    }

    // Filters
    // ?keyFilter=one:two,three:a,five:b,six<5
    let filtersParam = getSearchParam("Filter", true);
    if (Array.isArray(filtersParam)) {
      for (let f in filtersParam) {
        let filterArr = filtersParam[f].split(",");

        if (Array.isArray(filterArr)) {
          for (let i in filterArr) {
            let comp = GetDataComparator(filterArr[i]);
            if (comp) {
              let [fKey, fValue] = filterArr[i].split(comp) as [string, string];
              let filter: FilterKeyValue<string> = {
                key: fKey,
                value: fValue,
                comp,
              };
              filters.push(filter);
            }
          }
        } else {
          console.error("Filter Param is not an array.", filtersParam);
        }
      }
    }

    // Searches
    let searchesParam = getSearchParam("Search", true);
    if (Array.isArray(searchesParam)) {
      for (let s in searchesParam) {
        let [key, value] = searchesParam[s].split(":");
        searches.push({ key, value });
      }
    }

    return {
      sort,
      page,
      after,
      numPerPage,
      filters,
      searches,
    };
  };

  // Call Deep Linking Hook
  const [getSearchParam, , , , setSearchParams] = useDeepLinking(dataKey || "");
  /**
   * setControls default is to either GetAllDeepLinks or to set to defaults.
   */
  const [controls, setControls] = useState<DataControls>(() => {
    if (useDeepLinks) {
      if (!defaults)
        return GetAllDeepLinkParams();

      let ctrls = GetAllDeepLinkParams();
      if (defaults.numPerPage && !ctrls.numPerPage)
        ctrls.numPerPage = defaults.numPerPage;
      if (defaults.sort && !ctrls.sort)
        ctrls.sort = defaults.sort;
      return ctrls;
    } else {
      return defaults ?? {};
    }
  });

  const SetDeepLinks = (ctrl: DataControls) => {
    let sort,
      page,
      after,
      numPerPage,
      filters = [],
      searches = [];
    // Sort
    if (ctrl.sort?.key && ctrl.sort?.value) {
      sort = `${ctrl.sort.key}:${ctrl.sort.value}`;
    }

    // Page and NumPerPage
    if (ctrl.page) {
      if (ctrl.numPerPage) {
        page = `${ctrl.page}:${ctrl.numPerPage}`;
      } else {
        page = `${ctrl.page}`;
      }
    }

    // After
    if (ctrl.after?.key && ctrl.after?.value) {
      after = `${ctrl.after.key}:${ctrl.after.value}`;
    }

    // Filters
    if (ctrl.filters && ctrl.filters.length > 0) {
      for (let f in ctrl.filters) {
        filters.push(
          `${ctrl.filters[f].key}${ctrl.filters[f].comp}${ctrl.filters[f].value}`
        );
      }
    }

    // Searches
    if (ctrl.searches && ctrl.searches.length > 0) {
      for (let f in ctrl.searches) {
        searches.push(`${ctrl.searches[f].key}:${ctrl.searches[f].value}`);
      }
    }

    setSearchParams({
      Sort: sort,
      Page: page,
      Filter: filters,
      Search: searches,
      After: after,
    });
  };

  /**
   * onControl - Call whenever you click a control button.
   * @param ctrl : DataControls the Controls changed.
   *
   * This will process the ctrl, updating deep links if used.
   */
  const onControl = (ctrl: DataControls) => {
    if (useDeepLinks) {
      SetDeepLinks(ctrl);
    }
    // TODO: Need a way to persist a control...
    setControls(ctrl);
  };

  /**
   * Get a list of all filters matching this key.
   * @param key The key to match
   * @returns 
   */
  const getFiltersByKey =(key: string) => {
    if (!controls.filters) return [];
    let results = [];
    for (let i=0; i<controls.filters.length; i++) {
      if (controls.filters[i].key === key)
        results.push(controls.filters[i]);
    }
    return results;
  }
  
  /**
   * Get a list of all searches matching this key.
   * @param key The Key to match
   * @returns 
   */
  const getSearchesByKey = (key: string) => {
    if (!controls.searches) return [];
    let results = [];
    for(let i=0; i<controls.searches.length; i++) {
      if (controls.searches[i].key === key)
        results.push(controls.searches[i]);
    }
    return results;
  }

  return {
    onControl,
    controls,
    getFiltersByKey,
    getSearchesByKey,
  };
};

export const toggleFilterForField = (
  field: string,
  filter: string,
  ctrl: DataControls
): DataControls => {
  let controls = { ...ctrl };
  // Add the filters array if it doesn't exist..
  if (!controls.filters) {
    controls.filters = [];
  }

  // Check all of the existing controls and delete it if it exists.
  for (let i = 0; i < controls.filters.length; i++) {
    if (
      controls.filters[i].key === field &&
      controls.filters[i].value === filter
    ) {
      controls.filters.splice(i, 1);
      return controls;
    }
  }

  controls.filters.push({
    key: field,
    value: filter,
  });

  return controls;
};

export const setSearchForField = (
  field: string,
  value: string,
  ctrl: DataControls
) : DataControls => {
  let controls = {...ctrl};
    // Create the controls if they don't exist.
    if (!controls.searches)
      controls.searches = [];

    for (let i=0; i < controls.searches.length; i++) {
      if (controls.searches[i].key === field) {
        if (value === "") {
          controls.searches.splice(i, 1);
        } else {
          controls.searches[i].value = value;
        }
        return controls;
      }
    }
    
    controls.searches.push({
      key: field,
      value
    });

    return controls;
}

export default useDataControls;
