import React from 'react'
import PropTypes from 'prop-types'
import Player from './Player'
import { TEAMS } from 'const'
import "scss/Scoreboard.scss"

const ScoreboardPlayer = (props) => (
    <div className={"player __" + props.player.id}>
        <Player showSlot
            id={props.player.id || ''}
            name={props.player.name || ''}
            slot={props.player.slot || ''}
            guid={props.player.guid || ''}
            />
        <span className="kills">{props.player.kills}</span>
        <span className="deaths">{props.player.deaths}</span>
        <span className="score">{props.player.score}</span>
    </div>
);


ScoreboardPlayer.propTypes = {
    player: PropTypes.shape({
        kills: PropTypes.number.isRequired,
        deaths: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired,
        id: PropTypes.string,
        slot: PropTypes.string,
        name: PropTypes.string,
        guid: PropTypes.string,
    }),
};
ScoreboardPlayer.defaultProps = {
    player: PropTypes.shape({
        name: '',
        slot: '',
        id: '',
        kills: 0,
        deaths: 0,
        score: 0,
    }),
};

const ScoreboardTeam = (props) => (
    <div className={"team " + props.team}>
        <div className="header">
            <span className="playercount">
                {props.players.filter((p) => (p.connected && p.team === props.team)).length}
            </span>
            <span className="teamname">
                {props.team.toLowerCase().charAt(0).toUpperCase() + props.team.toLowerCase().substring(1)}
            </span>
            <span className="kills">
                K
            </span>
            <span className="deaths">
                D
            </span>
            <span className="score">
                {props.totalScore || 0}
            </span>
        </div>
        <div className="data">
            {props.players.filter((p) => (p.connected && p.team === props.team))
                          .sort((a, b) => (b.score > a.score))
                          .map((p) => (
                <ScoreboardPlayer key={p.id} player={p} />
                )
            )}
        </div>
    </div>
);

ScoreboardTeam.propTypes = {
    players: PropTypes.array.isRequired,
    totalScore: PropTypes.number,
    team: PropTypes.string.isRequired,
};

class Scoreboard extends React.PureComponent {
    render() {
        return (
            <div className="Scoreboard">
                <ScoreboardTeam team={TEAMS.ALLIES}
                                players={this.props.players}
                                totalScore={this.props.scores[TEAMS.ALLIES]} />
                <ScoreboardTeam team={TEAMS.AXIS}
                                players={this.props.players}
                                totalScore={this.props.scores[TEAMS.AXIS]} />
            </div>
        )
    }
}

Scoreboard.propTypes = {
    players: PropTypes.arrayOf(PropTypes.shape(Player.propTypes).isRequired).isRequired,
    scores: PropTypes.object,
    scoreboardHeight: PropTypes.func,
}

export default Scoreboard
