// Reducer for state.weniss

import { ACTIONS } from '../actions'


// const emptyState = {
//     selectedPlayer,
//     selectedPlayerGUID,
//     b3infoByPlayerGUID: {
//         playerguid: {
//             isFetching: true,
//             refresh: false,
//             lastUpdated: 0,
//             b3info: {
//                 auto_login,
//                 connections,
//                 greeting,
//                 group_bits,
//                 guid,
//                 id,
//                 ip,
//                 login,
//                 mask_level,
//                 name,
//                 password,
//                 pbid,
//                 time_add,
//                 time_edit,
//             },
//             aliases: [{
//                 id,
//                 alias,
//                 client_id,
//                 num_used,
//                 time_add,
//                 time_edit
//             }],
//             reports: [],
//         }
//     }
// }

const b3info = (state = {
    isFetching: false,
    lastUpdated: 0,
    refresh: false,
    b3info: {},
    aliases: [],
    reports: [],
}, action) => {
    switch(action.type) {
        case ACTIONS.WENISS.REFRESH_B3INFO:
            return {...state,
                refresh: true};
        case ACTIONS.WENISS.REQUEST_B3INFO:
            return {...state,
                isFetching: true};
        case ACTIONS.WENISS.RECEIVE_B3INFO:
            return {...state,
                isFetching: false,
                lastUpdated: Date.now() / 1000,
                b3info: action.info.b3info,
                aliases: action.info.aliases,
                reports: action.info.reports,
                notes: action.info.notes,
            };
        default:
            return state;
    }
}

const b3infoByGUID = (state = {selectedPlayer: "", selectedPlayerGUID: "", hoveredPlayer: ""}, action) => {
    switch(action.type) {
        case ACTIONS.WENISS.REFRESH_B3INFO:
        case ACTIONS.WENISS.RECEIVE_B3INFO:
        case ACTIONS.WENISS.REQUEST_B3INFO:
            return {...state,
            [action.guid]: b3info(state[action.guid], action),
        };
        default:
            return state;

    }
}

const setting = (state = {}, action) => {
    switch(action.type) {
        case ACTIONS.WENISS.REFRESH_SETTINGS:
            return {...state,
            refresh: true
        };

        case ACTIONS.WENISS.REQUEST_SETTINGS:
            return {...state,
            isFetching: true
        };

        case ACTIONS.WENISS.RECEIVE_SETTINGS:
            return {...state,
            isFetching: false,
            lastUpdated: Date.now() / 1000,
            settingsInfo: action.settingsInfo
        };

        default:
            return state;
    }
}

const wenissSettings = (state = {
    pages: {},

}, action) => {
    switch(action.type) {
        case ACTIONS.WENISS.REFRESH_SETTINGS:
        case ACTIONS.WENISS.RECEIVE_SETTINGS:
        case ACTIONS.WENISS.REQUEST_SETTINGS:
            return {...state,
                [action.page]: setting(state[action.page], action),
            };
        default:
            return state;
    }
}

const weniss = (state = {weviewVisible: false, enableChatColors: true}, action) => {
    switch(action.type) {
        case ACTIONS.WENISS.REFRESH_B3INFO:
        case ACTIONS.WENISS.RECEIVE_B3INFO:
        case ACTIONS.WENISS.REQUEST_B3INFO:
            return {...state,
            weviewVisible: true,
            b3infoByGUID: b3infoByGUID(state.b3infoByGUID, action)
        };

        case ACTIONS.WENISS.REFRESH_SETTINGS:
        case ACTIONS.WENISS.RECEIVE_SETTINGS:
        case ACTIONS.WENISS.REQUEST_SETTINGS:
            return {...state,
            settingsVisible: true,
            wenissSettings: wenissSettings(state.wenissSettings, action)
        };

        case ACTIONS.WENISS.TOGGLE_CHATCOLORS:
            return {...state,
                enableChatColors: action.json.enableChatColors
            };

        case ACTIONS.WENISS.SELECTPLAYER:
            return {...state,
                selectedPlayer: action.json.id,
                selectedPlayerGUID: action.json.guid,
                weviewVisible: true,
            };

        case ACTIONS.WENISS.HOVERPLAYER:
            return {...state,
                hoveredPlayer: action.json
            };

        case ACTIONS.WENISS.CLOSE_WEVIEW:
            return {...state,
                weviewVisible: false,
                selectedPlayer: {id: '', guid: 'SERVER'}
            };

        case ACTIONS.WENISS.OPEN_WEVIEW:
            return {...state,
                weviewVisible: true,
            };

        default:
            return state;
    }
}

export default weniss;
