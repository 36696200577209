import { toggleChatColors } from "actions";
import axios from "modules/axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PlayerSearch from "components/PlayerSearch";
import Settings from "components/setting/Settings";
import UserRegister from "components/settings/UserRegister";
import FontAwesome from "components/util/FontAwesome";
import socketio, { SocketWrapper } from "modules/sockets.js";
import "scss/Navigation.scss";
import "scss/SettingsModal.scss";
import { havePerm } from "services/Authentication";

function ServerIcon(props) {
  return (
    <span className="server-icon">
      <img
        src={"/img/" + props.game + ".png"}
        style={{ width: "23px", height: "23px" }}
      />
    </span>
  );
}

ServerIcon.propTypes = {
  game: PropTypes.string,
};

function NavMenu(props) {
  const modalOptions = {
    default: {
      title: "Default Modal",
      body: <React.Fragment />,
    },
    settings: {
      title: "Settings",
      body: <Settings />,
    },
    register: {
      title: "Register User",
      body: <UserRegister />,
    },
  };
  const [modal, setModal] = useState(modalOptions["default"]);
  const [showModal, setShowModal] = useState(false);
  const [game, setGame] = useState("");
  const [server, setServer] = useState("");
  const [servers, setServers] = useState([]);
  const [mounted, setMounted] = useState(false);

  const location = useLocation();
  const [echelonLink, setEchelonLink] = useState(
    location.pathname.includes("echelon")
      ? location
      : {
          pathname: "/echelon",
          search: "",
        }
  );
  const [tab, setTab] = useState(
    location.pathname.includes("echelon") ? "echelon" : "weniss"
  );
  const navigate = useNavigate();

  const selectTab = (tab) => {
    setTab(tab);
    switch (tab) {
      case "weniss":
      default:
        if (location.pathname.includes("echelon")) {
          setEchelonLink(location);
        }
        navigate("/");
        break;
      case "echelon":
        navigate(echelonLink);
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.includes("echelon")) {
      setEchelonLink(location);
      setTab("echelon");
    }
  }, [location]);

  const setCookie = (enabled) => {
    let date = new Date();
    date.setTime(date.getTime() + 99999 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `enableChatColors=${
      enabled ? "true" : "false"
    }; ${expires}; path="/settings"`;
  };

  const getCookie = () => {
    const name = "enableChatColors=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  };

  const toggleChatColors = () => {
    props.toggleChatColors(!props.enableChatColors);
    setCookie(!props.enableChatColors);
  };

  const openModal = (modal) => {
    if (modalOptions[modal] !== undefined) {
      setModal(modalOptions[modal]);
      setShowModal(true);
    }
  };

  const connectToServer = (game, server) => {
    socketio.setCurrentServer(game, server);
    setGame(game);
    setServer(server);
  };

  const settingsMenuSelect = (key) => {
    switch (key) {
      case "settings":
      case "register":
        openModal(key);
        break;
      case "togglechatcolors":
        toggleChatColors();
        break;
      case "logout":
        axios.get("/logout").then((res) => {
          window.location = "/";
        });
        break;
      default:
        return;
    }
  };

  if (!mounted) {
    axios.get("/api/servers/").then((res) => {
      let servers = [];
      for (let i in res.data.servers) {
        let s = res.data.servers[i];
        servers.push({ game: s.game, id: s.server_id });
      }
      setGame(servers[0].game);
      setServer(servers[0].id);
      setServers(servers);
      connectToServer(servers[0].game, servers[0].id);
      if (getCookie() === "false") props.toggleChatColors(false);
    });
  }
  useEffect(() => {
    setMounted(true);
  });

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        dialogClassName="s-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.body}</Modal.Body>
      </Modal>
      <Navbar variant="dark" className="Navigation navbar-inverse" fixed="top">
        <Container fluid className="nopadding">
          <Navbar.Brand>
            <Link className="navbar-brand" id="navName1" tabIndex="-1" to="/">
              The [WizZar] Eradication of Neanderthals Interweb Suieet System
            </Link>
            <Link className="navbar-brand" id="navName2" to="/">
              [W].E.N.I.S.S.
            </Link>
          </Navbar.Brand>
          <Nav
            className="me-auto page-tabs"
            variant="tabs"
            activeKey={tab}
            onSelect={selectTab}
          >
            <Nav.Item>
              <Nav.Link eventKey="weniss">[W].E.N.I.S.S.</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="echelon">Echelon</Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav>
            <Navbar.Collapse>
              <PlayerSearch />
              <NavDropdown
                className="serverTabs"
                title={
                  <React.Fragment>
                    {game.toUpperCase()}: {server.toUpperCase()}
                    <span className="carat" />
                  </React.Fragment>
                }
              >
                {servers.map((e, i) => (
                  <NavDropdown.Item
                    key={`server-${i}`}
                    href="#"
                    onClick={() => connectToServer(e.game, e.id)}
                    className={server === e.id ? "selected" : ""}
                  >
                    <FontAwesome
                      icon={server === e.id ? "link" : "chain-broken"}
                    />{" "}
                    <ServerIcon game={e.game.toLowerCase()} />{" "}
                    {e.id.toUpperCase()}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown
                title={
                  <i
                    style={{
                      background: 'url("/img/gear.png") no-repeat',
                      backgroundSize: "cover",
                      height: "30px",
                      width: "30px",
                      display: "inline-block",
                    }}
                  />
                }
                id="nav-settings-dropdown"
                className="dropdown-menu-right"
                onSelect={settingsMenuSelect}
              >
                <NavDropdown.Item eventKey="settings">
                  <FontAwesome icon="cog" /> Settings
                </NavDropdown.Item>
                {havePerm("register_users") && (
                  <NavDropdown.Item eventKey="register">
                    <FontAwesome icon="id-card-o" /> Register User
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item eventKey="togglechatcolors">
                  <FontAwesome
                    icon={
                      props.enableChatColors ? "check-square-o" : "square-o"
                    }
                  />{" "}
                  Toggle Colors
                </NavDropdown.Item>
                {props.admin.perms.includes("view_map_graph") && (
                  <NavDropdown.Item eventKey="mapgraph">
                    <FontAwesome icon="map-o" /> Map Graph
                  </NavDropdown.Item>
                )}
                {props.admin.perms.includes("view_playrs_map") && (
                  <NavDropdown.Item eventKey="playersgraph">
                    <FontAwesome icon="area-chart" /> Players Graph
                  </NavDropdown.Item>
                )}
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="https://echelon.net-jam.com"
                  target="new"
                >
                  <FontAwesome icon="info-circle" /> Echelon{" "}
                  <FontAwesome icon="external-link" />
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="logout">
                  <FontAwesome icon="sign-out" /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </Nav>
        </Container>
      </Navbar>
      <SocketWrapper />
    </React.Fragment>
  );
}

NavMenu.propTypes = {
  enableChatColors: PropTypes.bool,
  toggleChatColors: PropTypes.func,
  admin: PropTypes.object,
};

export default connect(
  (state) => ({
    enableChatColors: state.weniss.enableChatColors,
    admin: state.admin.info,
  }),
  (dispatch) => ({
    toggleChatColors: (enabled) => dispatch(toggleChatColors(enabled)),
  })
)(NavMenu);
