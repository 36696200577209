import FontAwesome from "components/util/FontAwesome";
import axios from "modules/axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Alert } from "components/setting/UserAlerts";

type UserAlertProps = {
  onSave?: Function;
  onDelete?: Function;
  regex?: string;
  alert_level?: string;
  alert_id?: number;
};

const UserAlert = ({
  regex = "",
  alert_level = "alert",
  alert_id,
  onSave = (alert: Alert) => {},
  onDelete = (alert: Alert) => {},
}: UserAlertProps) => {
  const [regexp, setRegexp] = useState(regex);
  const [level, setLevel] = useState(alert_level);

  useEffect(() => {
    setLevel(alert_level)
    setRegexp(regex);

  }, [alert_id, alert_level, regex])

  return (
    <tr className="UserAlert">
      <td>
        <Form.Control
          type="text"
          onChange={(e) => setRegexp(e.target.value)}
          value={regexp}
          placeholder="Regex"
        />
      </td>
      <td>
        <Form.Select onChange={(e) => setLevel(e.target.value)} value={level}>
          <option value="alert">Alert</option>
          <option value="mention">Mention</option>
          <option value="disabled">Disabled</option>
        </Form.Select>
      </td>
      <td>
        <Button
          variant="success"
          onClick={() =>
            onSave({ alert_id, alert_level: level, regex: regexp })
          }
        >
          <FontAwesome icon="floppy-o" /> Save{alert_id === undefined && " New"}
        </Button>
        {alert_id !== undefined && (
          <Button
            variant="danger"
            onClick={() =>
              onDelete({ alert_id, alert_level: level, regex: regexp })
            }
          >
            <FontAwesome icon="times" /> Delete
          </Button>
        )}
      </td>
    </tr>
  );
};

export default UserAlert;
