import { DataControls, DataRow } from "components/echelon/DataController";
import FilterHeader from "components/echelon/FilterHeader";
import Loading from "components/echelon/Loading";
import Pager from "components/echelon/Pager";
import {
  setSearchForField,
  toggleFilterForField
} from "modules/useDataControls";
import React, { FC } from "react";
import Table from "react-bootstrap/Table";
import "scss/DataTable.scss";

export interface Header {
  key: string;
  label: string;
  sortable?: boolean;
  filterable?: boolean;
  searchable?: boolean;
  searchOptions?: {
    exact?: boolean; // Does searching match exact? Such as for ID fields
    noWildcardBefore?: boolean; // does the search add a wildcard before?
    noWildcardAfter?: boolean; // does the search add a wildcard after?
  };
  filterFunc?: (row: DataRow, val: string | string[]) => boolean;
  filterParamFunc?: (val: string | string[]) => string;
  searchFunc?: (row: DataRow, val: string | string[]) => boolean;
}

interface DataTableProps {
  headers: Array<Header>;
  data: Array<any>;
  row: Function;
  controls?: any;
  onControl?: (ctrl: DataControls) => void;
  loading?: boolean;
  disableControlsOnLoading?: boolean;
  numPages?: number;
  numPageButtons?: number;
  nopagination?: boolean;
  rowProps?: any;
}

const DataTable: FC<DataTableProps> = ({
  headers,
  data,
  row,
  controls = {},
  onControl = (ctrl: DataControls) => {},
  loading = false,
  disableControlsOnLoading = false,
  numPages,
  numPageButtons = 5,
  nopagination = false,
  rowProps,
  ...props
}) => {
  const onSelectPage = (page: number) => {
    let ctrls = { ...controls };
    ctrls.page = page;
    onControl(ctrls);
  };

  const onFilter = (field: string) => (value: string) => {
    onControl(toggleFilterForField(field, value, controls));
  };

  const getFilter = (field: string) => {
    let values: string[] = [];
    if (!controls.filters) return values;
    for (let i = 0; i < controls.filters.length; i++) {
      if (controls.filters[i].key === field)
        values.push(controls.filters[i].value);
    }
    return values;
  };

  const onSort = (field: string) => (value: string) => {
    let ctrls = { ...controls };
    ctrls.sort = {
      key: field,
      value: value,
    };
    onControl(ctrls);
  };
  const getSort = () => {
    return {
      field: controls.sort?.key ?? "",
      order: controls.sort?.order ?? "",
    };
  };

  const onSearch = (field: string) => (value: string) => {
    onControl(setSearchForField(field, value, controls));
  };

  const getSearch = (field: string) => {
    for (let f in controls.searches) {
      if (controls.searches[f].key === field) return controls.searches[f].value;
    }
    return "";
  };

  const Row = row;

  return (
    <React.Fragment>
      {!nopagination &&
      <Pager
      disabled={disableControlsOnLoading && loading}
      page={controls.page ?? 0}
      onSelectPage={onSelectPage}
      pageCount={numPages}
      numPageButtons={numPageButtons}
      />
    }
      <Table size="sm" responsive className="DataTable" {...props}>
        <thead>
          <tr>
            {headers.map((e, i) => (
              <FilterHeader
                key={i}
                field={{
                  field: e.key,
                  ...e,
                }}
                controls={{
                  onSearch,
                  getSearch,
                  onSort,
                  onFilter,
                  getSort,
                  getFilter,
                }}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {loading && <Loading wrapped />}
          {Array.isArray(data) && data.map((e, i) => (<Row {...e} key={i} {...rowProps}/>))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default DataTable;
