import { connect } from 'react-redux'
import AdminList from 'components/weniss/AdminList'

const mapStateToProps = state => ({
    admins: state.admins.filter(a => a.connected),
})


export default connect(
    mapStateToProps,
    null,
)(AdminList)
