import React from 'react';
import { connect } from "react-redux";
import "scss/ColoredText.scss";
import PropTypes from 'prop-types'
function ColoredText(props) {
  let text = "" + props.children;

  while (text.match(/\^[0-9]/g)) {
    text = text.replace(
      /(.*)\^([0-9]{1})(.*)/gi,
      '$1<span class="cod4color$2">$3</span>'
    );
  }
  let msg = text;
  return (
    <span
      className={props.className + (props.enableColors ? " colors" : "")}
      dangerouslySetInnerHTML={{ __html: msg }}
    ></span>
  );
}

ColoredText.defaultProps = {
  className: "msg",
  enableColors: false,
};

ColoredText.propTypes = {
    className: PropTypes.string,
    enableColors: PropTypes.bool,
    children: PropTypes.any,
}

export default connect(
  (state) => ({
    enableColors: state.weniss.enableChatColors,
  }),
  null
)(ColoredText);
