import Pagination from 'components/echelon/Pagination';
import SortableHeadings from 'components/echelon/SortableHeadings';
import FontAwesome from 'components/util/FontAwesome';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Moment from 'react-moment';
import { Link, Navigate } from 'react-router-dom';
import 'react-virtualized/styles.css';
import 'scss/AdminBans.scss';
import penalties from 'template_data/penalties.json';

// import AutoSizer from 'react-virtualized-auto-sizer';


function Loading() {
    return (
        <div className="Loading">
            <FontAwesome icon="spinner" spin />
        </div>
    )
}

const banTypes = ['Ban', 'IP Ban', 'Temp Ban'];
class AdminBans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirect: null,
            bans: penalties.filter(p => (banTypes.includes(p.type) && (p.admin_id > 1))),
            sortKey: 'NONE',
            sortOrder: 'ASC',
        }
        this.handleClick = this.handleClick.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.sortRef = React.createRef();
        this.pagRef = React.createRef();
    }

    handleClick(id) {
        this.setState({
            redirect: `/clientDetails/${id}`,
        })
    }

    changeSort(key) {
        this.setState({
            loading: true,
        });
        var sortKey = "" + this.state.sortKey;
        var sortOrder = "" + this.state.sortOrder;
        if (key === sortKey) {
            switch(sortOrder) {
                case 'ASC':
                    sortOrder = 'DESC';
                    break;
                case 'DESC':
                    sortOrder = 'NONE';
                    break;
                case 'NONE':
                default:
                    sortOrder = 'ASC';
                    break;
            }
        } else {
            sortOrder = 'ASC'
        }
        let sortedAdminBans = this.state.bans.slice();
        if (sortOrder === 'NONE') {
            sortedAdminBans = penalties.filter(p => (banTypes.includes(p.type) && (p.admin_id > 1)));
        } else {
            sortedAdminBans = sortedAdminBans.sort((a, b) => {
                switch(sortOrder) {
                    case 'DESC':
                    return (parseInt(a[key]) ? parseInt(a[key]) < parseInt(b[key]) : a[key] < b[key]);
                    case 'ASC':
                    default:
                    return (parseInt(a[key]) ? parseInt(a[key]) > parseInt(b[key]) : a[key] > b[key]);
                }
            });
        }
        this.setState({
            sortOrder: sortOrder,
            sortKey: key,
            bans: sortedAdminBans,
            loading: false,
        })
    }


    render() {
        if (this.state.redirect) {
            this.setState({redirect: null});
            return <Navigate push to={this.state.redirect} />
        }
        let params = new URLSearchParams(this.props.location.search);
        const queryPage = params.get('adminBansPage');
        let page = parseInt(queryPage) || 0;
        let num = this.props.num || 15;

        return (
            <Container fluid>
                {this.state.loading &&
                    <Loading />
                }
                <Row ref={this.pagRef}>
                    <Pagination
                        page={page}
                        num={num}
                        totalItems={this.state.bans.length}
                        queryParam="adminBansPage"
                    />
                </Row>
                <SortableHeadings className="content"
                    headings={[
                        {key: "banned_name", label: "Name", size: 2},
                        {key: "type", label: "Ban Type", size: 1},
                        {key: "time_add", label: "Time Added", size: 3},
                        {key: "time_expire", label: "Time Expires", size: 3},
                        {key: "reason", label: "Reason", size: 2},
                        {key: "admin_id", label: "Admin ID", size: 1},
                    ]}
                    sortFunc={this.changeSort}
                    sortKey={this.state.sortKey}
                    sortOrder={this.state.sortOrder}
                />
                    {this.state.bans.slice(page*num, num+page*num).map(ban => (
                        <Link key={ban.id} to={"/ClientDetails/" + ban.client_id}>
                            <Ban
                                banned_name={ban.banned_name}
                                type={ban.type}
                                time_add={ban.time_add}
                                time_expire={ban.time_expire}
                                reason={ban.reason}
                                admin_id={ban.admin_id}
                            />
                        </Link>
                    ))}
            </Container>

        )
    }
}

AdminBans.propTypes = {
    num: PropTypes.number,
    location: PropTypes.object,

}

function Ban(props) {
    return (
        <Row className="Ban content">
            <Col xs={2}>
                <h2>{props.banned_name}</h2>
            </Col>
            <Col xs={1}>
                {props.type}
            </Col>
            <Col xs={3}>
                <Moment unix format="ddd MM/DD/YYYY hh:mma">{props.time_add}</Moment>
            </Col>
            <Col xs={3}>
                <Moment unix format="ddd MM/DD/YYYY hh:mma">{props.time_expire}</Moment>
            </Col>
            <Col xs={2}>
                {props.reason}
            </Col>
            <Col xs={1}>
                {props.admin_id}
            </Col>
        </Row>
    )
}

Ban.propTypes = {
    banned_name: PropTypes.string,
    type: PropTypes.string,
    time_add: PropTypes.number,
    time_expire: PropTypes.number,
    reason: PropTypes.string,
    admin_id: PropTypes.string,
}

export default AdminBans;
