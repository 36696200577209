import React from "react";
import CommandButton from "components/commands/CommandButton";
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types'
import axios from "axios";
import socketio from "modules/sockets";

export default function IPBan({ clientid, ip, name, slot }) {
  const fields = [
    {
      name: "ip",
      label: "IP Address",
      type: "text",
      required: true,
      validation: /^([0-9]{1,3}\.?){3}[0-9]{1,3}$/,
    },
    {
      name: "name",
      label: "Player name",
      type: "text",
      required: true,
    },
    {
      name: "reason",
      label: "Reason",
      type: "text",
      required: true,
    },
  ];
  const issueBan = (values) => {
    if (slot !== undefined && slot >= 0 && slot <= 24) {
      socketio.nsp.emit("clientCmd", `.kick ${slot} ^1Banned for ^7${values.reason}.\n ^3Appeal at ^2Net-Jam.com`);
    }

    axios.post(`/api/v2/clients/${clientid}/penalties`, { penalty: {
      banned_ip: values.ip
      , banned_name: values.name
      , reason: values.reason
      , type: "IP Ban"
      , time_expire: (new Date().getTime()/ 1000) + 60*60*24*90
      , server_id: "njh"
    } }).then(res => {
      console.log(res)
    });
  };
  return (
    <CommandButton
      label="IP Ban"
      fields={fields}
      defaultValues={{
        ip,
        name,
      }}
      permission="cmd_ban"
      confirm
      onConfirm={issueBan}
      button={
        <Button variant="danger" size="sm">
          IP Ban <i className="fa fa-ban" />
        </Button>
      }
    />
  );
}

IPBan.propTypes = {
  clientid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  ip: PropTypes.string,
  name: PropTypes.string,
  slot: PropTypes.number
}