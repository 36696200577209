import React, { FC } from "react";
import { Pagination } from "react-bootstrap";

interface PagerProps {
  noPages?: boolean;
  page: number;
  pageCount?: number;
  onSelectPage: Function;
  numPageButtons?: number;
  disabled: boolean;
}

const Pager: FC<PagerProps> = ({
  noPages,
  page,
  pageCount,
  onSelectPage,
  numPageButtons = 5,
  disabled = false,
}) => {

  if (noPages) {
    return (
      <Pagination className="Pager">
        <Pagination.Prev disabled={disabled || (page < 1)} onClick={() => onSelectPage(page-1)} />
        <Pagination.Next disabled={disabled} onClick={() => onSelectPage(page)} />
      </Pagination>
    )
  }

  let numButtons = numPageButtons;
  if (numButtons % 2 === 0) numButtons++;

  let midButton = Math.floor(numButtons / 2) + 1;
  let Buttons = [];
  for (let i = 1; i <= numButtons; i++) {
    let pageNumber = page - midButton + i;
    let adjPageNumber =
      pageNumber < 0
        ? false
        : pageCount && pageNumber > pageCount - 1
        ? false
        : pageNumber;
    let disabledByPage = pageNumber < 0 || (pageCount !== undefined && pageNumber > pageCount-1);

    Buttons.push(
      <Pagination.Item
        active={page === pageNumber}
        onClick={() => onSelectPage(adjPageNumber)}
        disabled={disabled || disabledByPage}
      >
        {adjPageNumber !== false ? adjPageNumber + 1 : <>&nbsp;</>}
      </Pagination.Item>
    );
  }
  return (
    <Pagination className="Pager">
      <Pagination.First
        onClick={() => onSelectPage(0)}
      >
      <i className="fa fa-step-backward" />&nbsp;1
      </Pagination.First>
      <Pagination.Prev
        disabled={disabled || (page < 1)}
        onClick={() => onSelectPage(page - 1)}
      >
        <i className="fa fa-caret-left"/>
        </Pagination.Prev>
      {Buttons}
      <Pagination.Next
        onClick={() => onSelectPage(page + 1)}
        disabled={disabled || (pageCount !== undefined && page >= pageCount - 1)}
      >
        <i className="fa fa-caret-right" />
        </Pagination.Next>
      {pageCount !== undefined && (
        <Pagination.Last
          onClick={() => onSelectPage(pageCount - 1)}
          disabled={disabled}
        >
          {pageCount}&nbsp; <i className="fa fa-step-forward" />
        </Pagination.Last>
      )}
    </Pagination>
  );
};

export default Pager;
