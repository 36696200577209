import React from 'react'
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types'
import 'scss/Section.scss'

class Section extends React.Component {
  render() {
    return (
      <Container xs={12} className="g-0 nopadding w-section w-spacing-top w-spacing-horiz">
        {this.props.title &&
          <div className="w-section-head text-center w-header w-spacing-bottom"
            title={this.props.altTitle}>
            {this.props.title}
          </div>
        }
        <div className="w-section-body">
          {this.props.children}
        </div>

      </Container>
    )
  }
}

Section.propTypes = {
  title: PropTypes.string,
  altTitle: PropTypes.string,
  spacing: PropTypes.arrayOf(PropTypes.oneOf(
    ['left',
      'right',
      'bottom',
      'top',
      'horiz',
      'vert',
      'all'])),
  children: PropTypes.element,
}
export default Section
